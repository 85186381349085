import { DictionaryType } from './'

const dictionary_en: DictionaryType[] = [
  {
    questionId: 10201,
    clues: ["American", "Male", "From group to solo", "Pop singer", "King of Pop"],
    words: [
      'Michael Jackson'
    ]
  },
  {
    questionId: 10202,
    clues: ["Celebrity", "Male", "International", "Singer", "King of Rock and Roll"],
    words: [
      'Elvis Presley'
    ]
  }
]

export default dictionary_en

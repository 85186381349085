import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import InnerPageHeader from "../../Components/InnerPageHeader";
import { routes } from "../../constants";
import useStyles from "./styles";

const Tips = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const props = useLocation().state;

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  return (
    <>
      <div className={classes.container}>
        <InnerPageHeader
          hasBackBtn
          goBackClick={() => {
            navigate(routes.home);
          }}
          title={`${props.title} Channel`}
        />
        {/* <div style={{position: 'fixed', left: isMobile ? '0px' : 'calc(50vw - 240px)', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignSelf: 'center', width: '100%', maxWidth: '480px', zIndex: 3}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-2)} title={`${props.title} Channel`}  />
        </div> */}
        <img src={props.image} className={classes.homeContent} />
      </div>
    </>
  );
};

export default Tips;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../utils/windowDimention';
import Loader from "../../Components/Common/Loader";
import InnerPageHeader from '../../Components/InnerPageHeader';
import Placeholder from '../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';

import {
  getNotifications, getRondleStealDetails
} from "../../services/ApiService";
import { ids, routes } from '../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Notification = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getNotifications();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
    // setData([{
    //   id: 1,
    //   source: {
    //     avatar_small: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1646883153360.jpeg',
    //   },
    //   text_format: "%USER% tries to steal from you",
    //   text_values: `\{
    //     "USER": "gtsxf2wg4"
    //   \}`,
    //   notification_type_id: 41,
    //   create_date: "2022-05-24T05:03:27.000Z"
    // }])
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const getButton = (notificationTypeId, referenceId) => {
    switch (notificationTypeId) {
      case ids.notificationTypeSteal:
        return <button className={classes.actionButton} onClick={() => fetchStealData(referenceId)}>
          FOIL
        </button>
      default:
        return <></>
    }
  }

  const fetchStealData = async (id) => {
    try {
      const response = await getRondleStealDetails({id: id});
      if (response) {
        var newItem = { ...response.data.engagement };
        newItem.joinRequirement = `[{
          "type": 4,
          "prizes": [
            { 
              "winOrder": 1,
              "imageSmall": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653739582660.png" ,
              "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653740546172.png" 
            },
            { 
              "winOrder": 2,
              "imageSmall": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653739582660.png" ,
              "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653740546172.png" 
            },
            { 
              "winOrder": 3,
              "imageSmall": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653739582660.png" ,
              "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653740546172.png" 
            },
            { 
              "winOrder": 4,
              "imageSmall": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653739582660.png" ,
              "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653740546172.png" 
            },
            { 
              "winOrder": 5,
              "imageSmall": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653739582660.png" ,
              "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653740546172.png" 
            },
            { 
              "winOrder": 6,
              "imageSmall": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653739582660.png" ,
              "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653740546172.png" 
            }
          ],
          "background": "${response.data.background}"
        }]`;
        newItem.postloaderImageBig = "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653741630579.png";
        if (!isNaN(newItem.id)) {
          newItem.newId = newItem.id;
        }
        navigate(routes.topic, {
          state: {
            engagementData: newItem,
            userData: {},
            joinSummary: [],
            lessonData: props.lessonData,
            fromNotification: true,
            questionId: response.data.question_id,
            difficultyClassId: response.data.difficulty_class_id,
            betAmount: response.data.bet_amount,
            defenderId: response.data.defender_id,
            challengerId: response.data.challenger_id,
            stealId: response.data.id,
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
          {data.length > 0 && data.map((item,index) => {
            let format = item.text_format
            let values = JSON.parse(JSON.stringify(JSON.parse(item.text_values)));
              for (let key in values) {
                var value = values[key]
                format = format.replace(`%${key}%`,value.trim())
              }
              return (<div className={classes.notifContainer} key={item.id} style={{backgroundColor: index % 2 === 0 ? '#303030' : 'transparent'}}>
                <img src={item.source.avatar_small ? item.source.avatar_small : Placeholder} className={classes.profileImage} style={{
                  width: 48,
                  height: 48,
                  objectFit: "cover",
                  borderRadius: 24,
                }}/>
                <div style={{flexDirection: 'column'}}>
                  <p className={classes.textFormat}>{format}</p>
                  <div className={classes.actionContainer} >
                    <ReactTimeAgo date={item.create_date} locale="en-US" style={{color: '#a0a0a0', fontSize: 10, textAlign: 'left'}}/>
                    {getButton(item.notification_type_id, item.reference_id)}
                  </div>
                </div>
              </div>)}
            )
            }
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="Notification" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default Notification;

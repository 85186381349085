import { useNavigate, useRoutes } from "react-router-dom";
import getRoutes from "./routes";
import "./App.css";
import Api from "./services/Api";
import { io } from "socket.io-client";
import jwtDecode from "jwt-decode";
import { routes } from "./constants";
import { useEffect } from "react";

import { DappCoreUIWrapper, DappUI } from "@elrondnetwork/dapp-core";
import { DappProvider } from "@elrondnetwork/dapp-core";
import {
  network,
  walletConnectBridge,
  walletConnectDeepLink,
} from "./config_elrond";
import "@elrondnetwork/dapp-core/build/index.css";

const {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal,
  DappCorePages: { UnlockPage },
} = DappUI;

function App() {
  const navigate = useNavigate();
  const user =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));

  const walletConnect =
    localStorage.getItem("walletconnect") &&
    JSON.parse(localStorage.getItem("walletconnect"));

  if (user !== null) {
    Api.setAuthHeader(user);
  }

  useEffect(() => {
    if (user !== null) {
      let name = localStorage.getItem("name");
      if (name === "tab1") {
        localStorage.removeItem("token");
        localStorage.removeItem("wefftWalletAddress");
        localStorage.removeItem("winEnggConsolidated");
        localStorage.removeItem("name");
        navigate(routes.login);
      } else {
        localStorage.setItem("name", "tab1");
      }
    }
  }, [user]);

  useEffect(() => {
    if (walletConnect) {
      localStorage.setItem("maiarAddress", walletConnect?.accounts[0]);
      // localStorage.removeItem("walletconnect");
      // localStorage.removeItem("persist:dapp-core-store");
    }
  }, [walletConnect]);

  const socket = io.connect(process.env.REACT_APP_SOCKET_URL);
  let userId;
  socket.on("connect", (data) => {
    if (user !== null) {
      setTimeout(() => {
        userId = jwtDecode(user?.split(" ")[1]);
        socket.emit("online", { userId: userId.id });
      }, 2000);
    }
  });

  socket.on("nl", (data) => {
    let userRecevingId = jwtDecode(data);
    console.log("receving", userRecevingId, window.newObject);
    if (userRecevingId.id === userId.id) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("balance");
      localStorage.removeItem("wefftWalletAddress");
      localStorage.removeItem("winEnggConsolidated");
      localStorage.removeItem("gameSound");
      localStorage.removeItem("footerIndex");
      localStorage.removeItem("itemStorage");
      localStorage.removeItem("isNewUser");
      navigate(routes.login);
    }
  });

  // let userId;

  const routing = useRoutes(getRoutes(user));

  return (
    <>
      <DappProvider
        environment="devnet"
        networkConfig={{ network, walletConnectBridge, walletConnectDeepLink }}
        customNetworkConfig={{ name: "customConfig", apiTimeout: 6000 }}
        completedTransactionsDelay={200}
      >
        <DappCoreUIWrapper>
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals className="custom-class-for-modals" />
          {routing}
        </DappCoreUIWrapper>
      </DappProvider>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import "./styles.css";
import { logout, useGetAccountInfo } from "@elrondnetwork/dapp-core";

import { checkWalletAddress, checkName, register, login } from "../../services/ApiService";
import { routes } from "../../constants";
import { useNavigate } from "react-router-dom";
import NoEffort from '../../assets/images/common-images/no_effort.png';
import { effortHelper } from "../../helpers/effortHelper";

import useStyles from './style';
import { checkNft } from "../../utils/utils";
import { nftHelper } from "../../helpers/nftHelper";

const MaiarPage = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [heHasNft, setHeHasNft] = useState(false);
  const ALLOWED_ADDRESSES = [
    //************** VGG
    "erd12uq7zj6lj6wqp7l9pasv60pad4f9mw73wnuzl6qhmj8szlearpxsfat0qv",
    "erd1zaravy98xy28ssf5cjujha2hsrs5vnwh50yh4vle2dqvxpn8krss38uf6f",
    "erd16u736c9uct8hrxstkc5jakj3t483neuj5pf933qj57u8crume2cq2qk3py",
    "erd1csa58uzfdk6fp20f80jmmhfkqafwfqz4n3ddulh5qfr3jm80zjxszg2lmf",
    "erd1pdxw9f9ah05zw80y9s7433ae6egl3a3z2srwenzpg0vaggzxhdyqy9svzx",
    "erd1kjvngst8ppp56f8094mj5tmvpk4g7dcyuzmxamjw5l8dsl4q3lwsckjkmw",
    "erd1q9lpjpde59qhaqc7npw5u2llqvy079rk2u8uwrxhw6udhm822g9qq6e4kd",
    "erd1kjvngst8ppp56f8094mj5tmvpk4g7dcyuzmxamjw5l8dsl4q3lwsckjkmw",
    "erd1kqep2j5ca3d8ke528ftycc6l9vl5q27ftc9vuhjy2nc2ur5r6crsgkh8dq",
    "erd1kqn45v4kn83dhdvn97u3ejmy78p995hvys6r3vypspxwggxtl3cq6mdhnd",
    "erd1543gyckcy0qh3gsgqdgn5fmezgeyhudpcsr3m7gf0en2m456r4zqdkq0kr",
    "erd1qu25t3jhcfjsnyd38c5cdmegkwdarx5sln0wvnx07rwdtl9s4xks0tcsya",
    "erd1vjmku0n8heks03907a0mgat9dgqrgu86q2mlchcelkzzly4g228sethe0a",
    "erd1qenvrzn03ddgtzq3g0qfq62l53gfjatcrldnn8p990h7x2994vlqrjt9pa",
    "erd1m3vekpwjunu9l3uyq79rztzw9eykqdgapk7sg9g5d65jfkm7s00qd46cpv",
    "erd16s4lhcacl4hwswz6cc0j0u7zm4v4x9rt84880xe8xafetv2ckpksc8605g",
    "erd1ncpqfsuxmcg77l564a0q3lf340u6g78zyv7feqszsc6ts7n4yhtsz4cdac",
    "erd1vc02vaekk5u2js7fd55puezff40qddh2gdquhwvdapa5ryzgvh2skn9kll",
    "erd14dqp4nscpyez869tghgz258qd5q2hy04ah2gt0fnvmy84cfzz4hqu0k7je",
    "erd195lymkylh5clg8ch7hk9sxlurxxvcxrz28443pma0vltua8zcd7qsafz5u",
    "erd1qjs3ggypf3ucp3vcedy30h5v2vrma2sf8ctfg9mh8ekgsthqs5mqy3zux4",
    "erd1n5jn8jvppmtuzwljsauxf9y7mwtdkg8u5jzgy4zksvduve7eyqtsu8lgyl",
    "erd1e5q2qhzpprrrcvn0u27azrcuut3zhh76cv7ejx59wlxudhvpzltscr0f9c",
    "erd1uz3sn65d6pgx36msm0xm9wc5kns2qgyhzknujxeqmakhk3at7aksmggcmp",
    "erd1ju2n7x680fktg0xd4fnqw557j03v9mpt4p6je4m75kc4g9kxta0s3nrzzz",
    "erd1z4huqn4n97nze0lqhfncd3vrqesynskeec6750cjhzmn28m4z7mqcynx0f",
    "erd1m74xssrhew2vfttj7em80lmlctgzwkpw8lfvf6zsus9ferh5x00q83jc4l",
    "erd15gup6d9pk32h98zr7fvrprrywpenprdte77pw6j7sum58xstdnrqy04edj",
    "erd1v3su0mfe7gwf94jgc9ma32y49vtp6fd9yltyx0qjt52r7hhq5e2sve2jpq",
    "erd1jf02yk4e608upm6jcfhpqq9s4u2j4dlv3d4dhkx2qhuxanfx2lyq7jddul",
    "erd1s2jdc232rrpadfem2ny9r9p5rhs5cktwpj76j0rp0ulev7tn044qjqfpve",
    "erd18q60gp9g4l23gpgjmr3egjmmu6hq9nsth5znv23euhycppcj27dqklms83",
    "erd13net7wul34u7rr8lmdz600hcpsg0xhzkqazmg295mdc0ustx45xsz5x7w5",
    "erd1543gyckcy0qh3gsgqdgn5fmezgeyhudpcsr3m7gf0en2m456r4zqdkq0kr",
    "erd1m74xssrhew2vfttj7em80lmlctgzwkpw8lfvf6zsus9ferh5x00q83jc4l",
    "erd1kqn45v4kn83dhdvn97u3ejmy78p995hvys6r3vypspxwggxtl3cq6mdhnd",
    "erd1vc02vaekk5u2js7fd55puezff40qddh2gdquhwvdapa5ryzgvh2skn9kll",
    "erd1qxpvr5vxemq5zzpygw7l54fm9xpefhejdf2lfpqca5f5t9jqa23qj9a6nm",
    "erd1ksstck6hghf7a788sxrpkvg6ffnzyuc2c5at9m64cd49c5y3yzfqr2aprt",
    "erd18mnjpljlruq9nztt566egkk0l3ydgjcr0eu6mgf2kjnqm4tmnz0salw2qw",
    "erd1vjmku0n8heks03907a0mgat9dgqrgu86q2mlchcelkzzly4g228sethe0a",
    "erd1yv3a38lqe8svl3clqcx44zeq6fztwal2lrhhr0gzyj44lxj4yvpquhsj8z",
    "erd172c9n2mry0ws74kldsxv4e9qqn7fdr7rdn0wxzajrtp2d6lxwwvqpdlarg",
    "erd1jnyk8f3dcq9ua6xtvfr6dyc7dz3xnyl6qpsltgr2fez28rlsuqmqx93jsn",
    "erd1qu25t3jhcfjsnyd38c5cdmegkwdarx5sln0wvnx07rwdtl9s4xks0tcsya",
    "erd1sjfhgjfxg068f6pn4un8v0exhhu8sd5takm66hqradvzr3ttz8qqtlzzqw",
    "erd1ncpqfsuxmcg77l564a0q3lf340u6g78zyv7feqszsc6ts7n4yhtsz4cdac",
    "erd14dqp4nscpyez869tghgz258qd5q2hy04ah2gt0fnvmy84cfzz4hqu0k7je",
    "erd1dtg4yywfg0jpy52h5qqua84jg4648kdvgppsf642nt5llgsjvlfs74cqmg",
    "erd1qaaghrat4shnvapedasdzt5740784s5fcgx8r76cgzsfg5j4388qtq7h5g",
    "erd1tgqqzjpnynnjdv3azv8xeyrq06u4hg92h999s9unw8x3ehu882pqq6w8cw",
    "erd160dn6vttje6hxsur4z72asd9fjq5hlpncmv0veunk4j4f3fcwcvqhpeqvn",
    "erd1qjs3ggypf3ucp3vcedy30h5v2vrma2sf8ctfg9mh8ekgsthqs5mqy3zux4",
    "erd1xnwj23gkwfgppkkjmkgl6v972jy2nt9expulu80khqgye03p8zcqc3x4ah",
    "erd195lymkylh5clg8ch7hk9sxlurxxvcxrz28443pma0vltua8zcd7qsafz5u",
    "erd14dqp4nscpyez869tghgz258qd5q2hy04ah2gt0fnvmy84cfzz4hqu0k7je",
    "erd1sp7pa7cy5ent2jcq97mqavkfahnujrqfyhawrge5vzktt3tv4pvq3cjhyu",
    "erd1u97jv389zknkzs7637wtf5uu0qe6tqsp2q98qwtrqwnm3wavz5aqdwgll8",
    "erd1836enz6jyxvjjj37uhqyrveqekfandx54zzp6nh0vrx6rpkeklpsmn903w",
    //********************
    //********* renzo
    "erd1d43frkawmfl2hvhlrw9uklzqaw4u7ekdx0fmr0q6ctteq65tqrtstq6lm2",
    //********* 
    //********* admin
    "erd1fkeq6auzdgcvjmaf6h4446q5n2tkz8seh5xj6s5s8jnrhemwmqzqcvq0ul", // Mabel Canillo
    "erd1q8gv9ydsrwxz8qlnwlhqcv56uqa3nms4rt3q346tmd6afz4lsfqs7wjxur", // Celine
    "erd1sznrumy5vydjnyhgdcw5exn4hjs8fnaxw78gruw8e4avx3zhtgdsv56ztv", // Alennie
    "erd1np3fnus2frdwe3gxjhrfz89qxhfdjvqsalsdge6wuq863cg77yas64lnkn", // jaQe
    "erd1jf02yk4e608upm6jcfhpqq9s4u2j4dlv3d4dhkx2qhuxanfx2lyq7jddul", // Ron
    "erd1kgdcnljqah80zvc3dnzsd8qluu5nvgkmxsp3wxy0hnady585hlkszjn5zf", // Feby
    //*********
  ]

  const loginHandler = async (oldId, apiKey, address, isNewUser) => {
    try {
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        localStorage.setItem("wefftWalletAddress", address);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        localStorage.setItem("isNewUser", isNewUser ? "1" : "0");
        localStorage.setItem("autoJoin", "0");
        if (localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"))) {
          navigate(routes.home);
        }
        else {
          // navigate(routes.login);
        }
      }
    } catch (e) {
      console.log(e);
      // navigate(routes.login);
    }
  };

  useEffect(async () => {
    const address = localStorage.getItem("maiarAddress");
    // console.log("Maiar Address", address);
    const creators = [ //check in homepage
      "erd1qf9et8d4swd09unnv0rdzezpkyxg5f2tk00tlr039nhjyz8xrm5qfj53ww", //elva
      "erd1jpr49da65vae0wjl73e3ghv443swxqaqvacu2f6uelvcnuxxw5jqktqsyh", //elrond lion
      "erd1qqqqqqqqqqqqqpgqeys5lt360l342m4dk2edehykktwn34djys5s46rk5f", //evoluzion
      "erd14edl4fvr8wc2sczrz5af6tfmfucgqzsh4u73hnxw96l07cekdg6schwtsh", //maiarpunks
      "erd1qqqqqqqqqqqqqpgqf6qde2f9dt8u943la9ehgvemzldx7rccxjhs3nqucu", //superrarebears
      "erd18e7t3fquqjm9rs6385tqu0pk543e43e44rlkrhtdzfd64rgu7jrq6jrza9", //whaley
      "erd1qqqqqqqqqqqqqpgqy2elehk25f6kp3zvf4wtd96sax6z78gqejeqq4l6n7", //Elrond Mutant Eheadz 
      "erd1qqqqqqqqqqqqqpgqy2jfag2jfhre8aem7kvz3ncrcq6ta52zys5s27cge2", //orcpunks
      "erd1qqqqqqqqqqqqqpgqfewmp7u7ea2p6pmu9sgv8hn8d3kgtje4ys5sjen2lu", //maka-bros
      "erd1qqqqqqqqqqqqqpgqw763s7ea3jksp6gr98kmj6psehtu3flndswsswu7hk", //mr ghost
    ];
    let hasNft = false;
    creators.every(async (creator) => {
      const data = {
        user_wallet: address,
        creator_wallet: creator,
      };
      const nft = await nftHelper(data);
      // console.log(`nft=${JSON.stringify(nft)}`)
      if (nft.length > 0) {
        hasNft = true;
        setHeHasNft(true);
        try {
          const response = await checkWalletAddress({walletAddress: address});
          if (response) {
            if (response.data.result) {
              loginHandler(response.data.data.oldId, response.data.data.apiKey, address, false)
              try {
                const response = await checkWalletAddress({walletAddress: address});
                if (response) {
                  if (response.data.result) {
                    loginHandler(response.data.data.oldId, response.data.data.apiKey, address, false)
                    return;
                  }
                  else {
                    const min = 4;
                    const max = 10;
                    const rand = min + Math.random() * (max - min);
                    let name = address.slice(address.length - rand, address.length)
                    const response1 = await checkName({displayName: name});
                    if (response1) {
                      if (response1.data.result) {
                        name = address.slice(address.length - rand - 1, address.length)
                      }
                      const response2 = await register({displayName: name, walletAddress: address});
                      if (response2) {
                        loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address, true)
                      }
                      else {
                        console.log('3');
                        // navigate(routes.login);
                      }
                    }
                    else {
                      console.log('4');
                      // navigate(routes.login);
                    }
                  }
                }
                else {
                  console.log('5');
                  // navigate(routes.login);
                }
              } catch (e) {
                console.log(e);
                // navigate(routes.login);
              }
            }
            else {
              const min = 4;
              const max = 10;
              const rand = min + Math.random() * (max - min);
              let name = address.slice(address.length - rand, address.length)
              const response1 = await checkName({displayName: name});
              if (response1) {
                if (response1.data.result) {
                  name = address.slice(address.length - rand - 1, address.length)
                }
                const response2 = await register({displayName: name, walletAddress: address});
                if (response2) {
                  loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address, true)
                  return;
                }
                else {
                  console.log('3');
                  // navigate(routes.login);
                }
              }
              else {
                console.log('4');
                // navigate(routes.login);
              }
            }
          }
          else {
            console.log('5');
            // navigate(routes.login);
          }
        } catch (e) {
          console.log(e);
          // navigate(routes.login);
        }
      }
      return true;
    });
    const EFFORT_CHECK = 0;
    const effort = await effortHelper({
      user_wallet: address,
    });
    // console.log(`effort=${effort}; hasNft=${hasNft}`)
    if (effort < Number(process.env.REACT_APP_MINIMUM_EFFORT) && !hasNft) {
      if (ALLOWED_ADDRESSES.filter((a) => a === address).length > 0) {
        trueLogin(address)
      }
      else {
        setShowDialog(true);
      }
    }
    else {
      if (localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"))) {
        //do nothing
      }
      else {
        trueLogin(address);
      }
    }
  }, [])

  const trueLogin = async (address) => {
    try {
      const response = await checkWalletAddress({walletAddress: address});
      if (response) {
        if (response.data.result) {
          loginHandler(response.data.data.oldId, response.data.data.apiKey, address, false)
        }
        else {
          const min = 4;
          const max = 10;
          const rand = min + Math.random() * (max - min);
          let name = address.slice(address.length - rand, address.length)
          const response1 = await checkName({displayName: name});
          if (response1) {
            if (response1.data.result) {
              name = address.slice(address.length - rand - 1, address.length)
            }
            const response2 = await register({displayName: name, walletAddress: address});
            if (response2) {
              loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address, true)
            }
            else {
              console.log('3');
              // navigate(routes.login);
            }
          }
          else {
            console.log('4');
            // navigate(routes.login);
          }
        }
      }
      else {
        console.log('5');
        // navigate(routes.login);
      }
    } catch (e) {
      console.log(e);
      // navigate(routes.login);
    }
  }

  const addressMaiar = localStorage.getItem("maiarAddress");
  console.log("Maiar Address", addressMaiar);
  const { address } = useGetAccountInfo();

  // useEffect(() => {
  //   if (address) {
  //     setTimeout(() => {
  //       logout(`${window.location.origin}/maiar`);
  //     }, 2000);
  //     // localStorage.removeItem("walletconnect");
  //   }
  // }, []);

  return (
    <>
      <div className="container">
        <div style={{ color: "#ffffff" }}>
          {/* <h2>Maiar Address</h2>
          {addressMaiar && addressMaiar} */}
        </div>
        { (showDialog && !heHasNft) && 
          <div className={classes.tutorialContainer} onClick={() => {navigate(routes.login)}}>
            <img src={NoEffort} className={classes.tutorialImage}/> 
          </div>
        }
      </div>
    </>
  );
};

export default MaiarPage;

export const image = {
  // FavoritesLogo: require("../assets/images/logo/famers.png"),
  GiftBoxImage: require("../assets/images/funtype-images/giftBoxImg.png"),
  TicketImage: require("../assets/images/header-images/ticket-header.png"),
  // Level1Image: require("../assets/images/postloader/famer_win_1.png"),
  // Level2Image: require("../assets/images/postloader/famer_win_2.png"),
  // Level3Image: require("../assets/images/postloader/famer_win_3.png"),
  // Level4Image: require("../assets/images/postloader/famer_win_4.png"),
  // Level5Image: require("../assets/images/postloader/famer_win_5.png"),
  TapWinGiftBoxImage: require("../assets/images/GiftBoxImage.png"),
  // ScrachCardFrontImage: require("../assets/images/postloader/scratch_cover.png"),
  // BenggaCoinImage: require("../assets/images/postloader/open_box.png"),

  // TutorialFamer: require("../assets/images/tutorial/famer.png"),

  // LogoFamers: require("../assets/images/logo/famers.png"),
};

export const labels = {
  YWon: "You won",
  YLose: "Try again",
  Tickets: "Tickets",
  YNowWon: "You now have",
  TapWin: "Tap to win",
  FBonus: "Famer Bonus",
  WinMuch: "Win as much as",
  AvailableCoins: "goes to Available Coins",
  CoinsProtectTomorrow: "goes to Coins to Protect tomorrow",
};

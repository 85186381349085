import React from 'react'

export const EndGame = ({board, onRestart, notifyWon, notifyLost, setMaxTile, maxTile}) => {
    var contents = '';
    setMaxTile(maxTile);
    if (board.hasWon()) {
        contents = 'Good Job!';
        setTimeout(() => {
            notifyWon(maxTile);
        }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
    } else if (board.hasLost()) {
        contents = 'Game Over';
        setTimeout(() => {
            notifyLost(maxTile);
        }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
    }
    return null;
    // if (!contents) {
    //     return null;
    // }
    // return (
    //     <div className='overlay'>
    //         <p className='message'>{contents}</p>
    //         <button className="tryAgain" onClick={onRestart} onTouchEnd={onRestart}>Try again</button>
    //     </div>
    // );
};
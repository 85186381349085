import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  homeContentLandscape: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    transform: "rotate(270deg)",
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top: "50%",
    left: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  description: {
    color: "#ffffff",
    fontSize: 12,
    margin: 0,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  lastmoddate: {
    color: "#bbbbbb",
    fontSize: 10,
    marginTop: 5,
    maxWidth: 150,
    minWidth: 170,
    backgroundColor: "transparent",
    paddingRight: 10,
  },
  historyContainer: {
    height: 120,
    width: 120,
    marginLeft: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileImage: {
    width: 84,
    height: 84,
    borderRadius: 42,
    marginLeft: 15,
    marginRight: 15,
    border: "2px solid white",
  },
  profileName: {
    width: 84,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 5,
    textAlign: 'center',
    fontSize: 14,
    color: 'white',
    fontFamily: 'CanaroBook'
  },
  titleContainer: {
    minWidth: 480,
    maxWidth: 480,
    height: 80,
    marginTop: 45,
    marginBottom: 45,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#db3eb1",
    fontFamily: "canaroMedium",
    fontSize: 16,
    maxWidth: 480,
    minWidth: 480,
    textAlign: "center",
    marginTop: 20,
  },
  subtitle: {
    color: "white",
    fontFamily: "canaroMedium",
    fontSize: 14,
    maxWidth: 480,
    minWidth: 480,
    textAlign: "center",
    fontFamily: "CanaroMedium",
  },
  logo: {
    width: 80,
    height: 80,
    borderRadius: 50,
    border: "2px solid #00acb8",
    marginTop: 20,
    fontFamily: "CanaroBook",
  },
}));

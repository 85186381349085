import Axios from "./axios";

export const nftHelper = async (data) => {
  try {
    const response = await Axios.get(
      `/${data.user_wallet}/nfts?creator=${
        data?.creator_wallet ? data?.creator_wallet : ""
      }`
    );
    if (response.status === 200) {
      if (data.collection) {
        const filteredArr = response.data?.filter((item, i) =>
          item.collection.includes(data.collection)
        );
        return filteredArr;
      }
      // console.log(`nftHelper=${JSON.stringify(response.data)}`)
      return response.data;
    }
  } catch (error) {
    return error.response;
  }
};

import React, { Fragment, FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import './Clue.css';
import Background from '../../assets/images/common-images/tip_background.png';
import { isMobile } from 'react-device-detect';

interface Props {
  setting: string,
  category: Clue[],
  scale: number
}

interface Clue {
  clue: string,
  isCorrect: boolean
}

const Clue: FunctionComponent<Props> = ({ setting, category, scale }) => {
  const ariaLabel: string = `Clue: ${category}.`
  const ariaDescribedBy: string = 'category-description'
  // console.log(`setting=${setting}`)
  return (
    <Fragment>
      <div className="ClueContainer" style={{top: isMobile ? scale < 1 ? '40px' : '65px' : '110px', transform: `scale(${scale})`}}>
        <img src={Background} className="ClueContainer"/>
        <div
          className="Clue"
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
        >
          <p className="SettingText">{setting}</p>
          {category.map((i) => <p style={{color: i.isCorrect ? '#3fff3f' : 'white', lineHeight: '14px', fontSize: '16px'}}>{i.clue}</p>)}
        </div>
      </div>
      <p id={ariaDescribedBy} className="sr-only">
        This is the category of the word to guess. Use keyboard to guess
        letters.
      </p>
    </Fragment>
  )
}

Clue.propTypes = {
  category: PropTypes.object.isRequired
}

export default Clue

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    // maxWidth: '480px',
    // width: '100%',
    // padding: '0 20px',
    // minHeight: '100vh',
    // height: 'auto',
    // position: 'relative',
    // background: '#000',
    // margin: '0 auto'
    height: '100%',
    maxWidth: '480px',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
    flexDirection: 'column'
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  list: {
    display: 'flex',
    paddingLeft: 2,
    height: '45px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  Nom: {
    width: '10%',
  },
  avt: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    marginBottom: 10
  },
  number: {
    color: '#00acb8',
    fontSize: '13px',
    fontWeight: '500',
  },
  userid: {
    fontWeight: '500',
    color: 'white',
    fontSize: '13px',
    width: "16%",
    textAlign: 'right'
  },
  usercode: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: '500',
    width: "25%",
    marginLeft: 10,
    fontFamily: 'CanaroBook'
  },
  para: {
    textAlign: 'initial'
  },
}));
import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';


export default makeStyles((theme) => ({

  

  homeContainer: {
    // height: '100%',
    // maxWidth: '480px',
    // width: '100%',
    // margin: '0 auto',
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    //   height: "100%",
    // },
    // flexDirection: 'column'
    height: '100%',
    maxWidth: '480px',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
    flexDirection: 'column'
  },

  homeContent: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
  power: {
    margin: '0',
    color: '#3daaba',
    fontSize: "13px",
    paddingLeft: '5px'
  },
  icons: {
    fontSize: '16px !important',
    color: '#c8d1d9',
    position: 'absolute',
    left: '0',
    top: '0',
    cursor: 'pointer'
  },
  diff: {
    backgroundColor: 'red',
    width: '33.33%',
  },
  ramk_comn: {
    width: '33.33%',
    backgroundColor: '#c3c2c3',
    border: '2.5px solid #39a8b2'
  },
  mainbord: {
    backgroundImage: 'linear-gradient(#743ad5, #d53a9d),linear-gradient(to right, green, gold)',
    padding: 2,
    backgroundColor: '#000',
    position: 'relative',
    borderRadius: 10
  },
  gradient: {
    backgroundColor: '#000',
    paddingTop: 25,
    borderRadius: 10,
    width: '100%',
    height: '100%'
  },
  btn: {
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    color: '#c8d1d9',
    position: 'absolute',
    right: '1.5%',
    top: '0',
    marginRight: '10px'
  },
  logo: {
    width: '45px',
    height: '45px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: ' 2px solid #39a8b2',
    position: 'absolute',
    left: '41%',
    top: '-6%',
  },
  img: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  leadhead: {
    color: '#dedede',
    fontSize: '13px',
    letterSpacing: '2px',
    textAlign: 'center',
    backgroundColor: 'transparent'
  },
  date: {
    color: '#db3eb1', fontSize: '10px', textAlign: 'center'
  },
  outer_main: {
    padding: '0 25px',
    marginTop: '36px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',

  },
  innerleft: {
    width: '31%',
    backgroundColor: '#c3c2c3',
    border: '3px solid #39a8b2',
    borderRadius: '10px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  innerright: {
    width: '30.33%',
    backgroundColor: '#c3c2c3',
    border: '3px solid #39a8b2',
    borderRadius: '0 10px 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  middlebord: {
    left: '36.5%',
    width: '28%',
    backgroundColor: '#c3c2c3',
    border: '3px solid #39a8b2',
    borderRadius: '10px 10px 0 0',
    position: 'absolute',
    bottom: '0',
    height: '115px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  innerhead: {
    color: '#000',
    fontSize: '11px',
    margin: '2px 0',
    fontWeight: '700',
    textAlign: 'center'
  },
  rounded: {

    width: '40px',
    height: '40px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: ' 2px solid #39a8b2',
  },
  rounded_mid: {
    width: '54px',
    height: '54px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: ' 2px solid #39a8b2',
  },
  imgrounded: {

    height: '100%',
    width: '100%',
    borderRadius: '50%',
  },

  name: {
    fontSize: '12px',
    fontWeight: '400',
    textAlign: 'center',
    fontFamily: 'CanaroBook'
  },
  user: {
    fontSize: '12px',
    paddingTop: '0px',
    color: '#4c9d9b',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  list: {
    display: 'flex',
    paddingLeft: 2,
    height: '45px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  Nom: {
    width: '10%',
  },
  avt: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    marginBottom: 10
  },

  number: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: '500',

  },
  userid: {
    fontWeight: '500',
    color: '#4c9d9b',
    fontSize: '13px',
    width: "24%",
    textAlign: 'right'
  },
  usercode: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: '500',
    width: "20%",
    marginLeft: 10,
    fontFamily: 'CanaroBook'
  },
  para: {
    textAlign: 'initial'
  },
  header: {
    display: 'flex'
  },

  // Progress bar
  progress: {
    backgroundColor: '#ccd1d4',
    borderRadius: '5px',
    position: 'relative',
    margin: '15px 20px',
    border: "2px solid #c1589c",
  },
  progress_done: {
    background: '#0b1928',
    borderRadius: '4px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    height: '20%',
    width: ' 0',
    opacity: ' 0',
    transition: '1s ease 0.3s',
  },
  // Progress bar
  customProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "22px",
    overflow: "hidden",
    background: "#e9ecef",
    borderRadius: "8px",
    position: "relative",
    border: "3px solid #c7569f",
    marginTop: "15px",
  },
  customProgressBar: {
    display: "flex",
    height: "100%",
    background: "#c7569f",
    position: "absolute",
    left: 0,
    top: 0,
    transition: "width 3s ease",
  },
  customProgressText: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#0000000",
    position: "relative",
  },
  giftBoxContent: {
    background:
      "linear-gradient(184deg, rgba(73,253,245,1) 0%, rgba(39,165,164,1) 0%, rgba(199,86,159,1) 100%)",
    borderRadius: "20px",
    padding: "3px",
    marginTop: "80px",
    position: "relative",
  },
  giftBoxSmallImageBox: {
    border: "3px solid #379ea3",
    borderRadius: "100px",
    width: "70px",
    height: "70px",
    background: "#fdfefe",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "-35px",
    transform: "translateX(-50%)",
    zIndex: 1
  },
  giftBoxSmallImage: {
    width: 64,
    height: 64,
    borderRadius: '50%'
  },
  giftBoxImageVideoContent: {
    background: "#000000",
    padding: "35px 15px",
    borderRadius: "18px",
    overflow: "auto",
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 480px)': {
      minHeight: "580px",
      maxHeight: "580px",
    },
    '@media (max-width: 400px)': {
      minHeight: "445px",
      maxHeight: "445px",
    }
  },
  giftBoxImage: {
    width: "100%",
    minHeight: "548px",
    maxHeight: "548px",
    objectFit: "contain",
    '@media (max-width: 480px)': {
      minHeight: "440px",
      maxHeight: "440px"
    },
    '@media (max-width: 400px)': {
      minHeight: "331px",
      maxHeight: "331px"
    }
  }
}));

import Axios from "./axios";

export const effortHelper = async (data) => {
  try {
    const response = await Axios.get(
      `/${data.user_wallet}/tokens/${process.env.REACT_APP_ESDT}`
    );
    if (response.status === 200) {
      // console.log(`response=${JSON.stringify(response)}`)
      const responseData = response.data;
      if (responseData.balance) {
        const amount = responseData.balance / 10  ** responseData.decimals
        console.log(`amount=${amount}`)
        return amount;
      }
      return 0;
    }
    else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

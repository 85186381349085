import { makeStyles } from "@material-ui/core/styles";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  mainfooter: {
    position: "absolute",
    // top:
    //   (isIOS && "calc(100vh - 230px)") ||
    //   (isAndroid && "calc(80vh - 70px)") ||
    //   (!isMobile && "calc(100vh - 179px)"),
    width: "100%",
    height: 50,
    zIndex: 11
    // backgroundColor: 'red'
  },
  morefooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    color: "#949494",
  },
  leftfootericn: {
    flex: 0.5,
    cursor: "pointer",
    height: 50,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 20,
    zIndex: 3,
    objectFit: "contain",
    marginRight: (isMobile && 280) ||
    (!isMobile && 380),
  },
  rightfootericn: {
    flex: 0.5,
    cursor: "pointer",
    height: 50,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 20,
    zIndex: 3,
    objectFit: "contain",
    marginRight: (isMobile && 280) ||
    (!isMobile && 380),
  },
  hostContainer: {
    top: 80,
    width: "100%",
    position: "absolute",
    height: 120,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  hostedBy: {
    fontFamily: "CanaroBook",
    fontSize: 14,
    width: "100%",
    color: "#eeeeee",
    textAlign: "center",
  },
  hostImgContainer: {
    display: "flex",
    width: 82,
    height: 82,
    justifyContent: "center",
    // backgroundColor: 'red',
    // borderRadius: '50%',
    border: "1px solid #00acb8",
    borderRadius: "50%",
    marginTop: 0,
  },
  hostImgContainer2: {
    display: "flex",
    width: 80,
    height: 80,
    // backgroundColor: 'red',
    // borderRadius: '50%',
    // border: "1px solid #00acb8"
    borderRadius: "50%",
  },
  hostImg: {
    justifyContent: "center",
    width: 80,
    height: 80,
    left: 20,
    borderRadius: "50%",
    objectFit: "cover",
  },
  tutorialImage: {
    width: '320px',
    height: '320px',
    objectFit: "cover",
  },
  tutorialContainer: {
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 10
  },
  confettiContainer: {
    width: "100%",
    height: "100px",
    objectFit: "stretch",
    position: 'absolute'
  },
  confettiTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "100%",
    height: "100px",
    marginTop: 5,
    zIndex: 10,
    // borderRadius: '12px',
    backgroundColor: 'transparent',
    padding: '20px',
    position: 'absolute',
    top: 0
  },
  confettiText: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'white',
    paddingTop: '12px',
    paddingLeft: '30px',
    paddingRight: '30px',
    zIndex: 1
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top: "50%",
    left: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
}));
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Lotto from './assets/lottery.mp4';
import LottoPix from './assets/lottery.png';
import LottoGif from './assets/lotto.gif';
import './App.css';
import BallOff from './assets/ball_1.png';
import BallOn from './assets/ball_2.png';
import Shamrock from './assets/shamrock.png';
import WinSound from './assets/lotto_match.mp3';

const MAX_IDS = 6;

const App = (props) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [winIds, setWinIds] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [counter, setCounter] = useState(0);
  const [winningNumbers, setWinningNumbers] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const [sound, setSound] = useState(false);
  let timer;
  const winData = props.winData;

  const handleSpinClick = (e) => {
    e.preventDefault();
    if (selectedIds.length === MAX_IDS) {
      setIsStarted(true);
      timer = setTimeout(() => {
        setCounter(counter + 1);
      }, 3000)
    }
  };

  const feelingLucky = () => {
    setSelectedIds([...props.data].sort(() => Math.random() - 0.5).slice(0, MAX_IDS));
  }

  useEffect(() => {
    setSound(false);
    if (counter >= 1 && counter <= MAX_IDS) {
      const winNum = winData[counter-1];
      setWinIds([...winIds, winNum])
      if (selectedIds.some((i) => i === winNum)) {
        setWinningNumbers(winningNumbers + 1);
        setSound(true);
      }
      if (counter === MAX_IDS) {
        setIsDone(true);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        setCounter(counter + 1);
      }, 3000)
    }
    else if (counter === MAX_IDS + 1) {
      clearTimeout(timer);
      if (winningNumbers == 0) {
        props.notifyLost();
      }
      else {
        props.notifyWon(winningNumbers);
      }
    }
  }, [counter])

  const addId = (id: number) => {
    if (selectedIds.some((i) => i === id)) {
      var array = [...selectedIds]; // make a separate copy of the array
      var index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedIds([...array]);
      }
    }
    else if (selectedIds.length < MAX_IDS) {
      setSelectedIds([...selectedIds, id]);
    }
  }

  function WinningSound() {
    return (
    <audio autoplay="autoplay" className="player" preload="false">
      {/* <source src="https://andyhoffman.codes/random-assets/img/slots/winning_slot.wav" /> */}
      <source src={WinSound} />
    </audio>  
    );
  }

  return (
    <div className="wheelroot">
      <div align="center" style={{marginTop: 0}}>
        {/* <h1 align="center">Roulette Game</h1> */}
        {/* <hr /> */}
        <br />
        { sound && <WinningSound/> }
        <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
          {/* {isStarted && !isDone && <video
              src={Lotto}
              playsInline
              autoPlay
              loop
              muted
              preload="auto"
              style={{width: '260px', height: '260px'}}
            />
          } */}
          {isStarted && !isDone && <img src={LottoGif}
              style={{width: '260px', height: '260px'}}
            />
          }
          {(!isStarted || isDone)&&
            <img src={LottoPix} style={{width: '260px', height: '260px', objectFit: 'cover'}} />
          }
          {!isStarted && 
            <button className="lottoStart" onClick={handleSpinClick}>
              START
            </button>
          }
        </div>
        <div className="winContainer">
          {winIds.map((e: any, i: number) => <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img style={{width: '50px', height: '50px'}} src={BallOn} key={i} />
              <p className="selNum2" >{e}</p>
            </div>)}{[...Array(MAX_IDS-winIds.length)].map((e: any, i: number) => <img style={{width: '50px', height: '50px'}} src={BallOff} key={i} />)}
        </div>
        {!isStarted && <ul className="numContainer">
          <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
            <h2 className="yourNum">Select {MAX_IDS} Numbers</h2>
            <img src={Shamrock} style={{width: '40px', height: '40px', position: 'absolute', right: 40}} onClick={feelingLucky}/>
          </div>
          {props.data.map((item) => {
            return <button key={item} className="lottobutton" style={{backgroundColor: selectedIds.some((i) => i === item) ? 'orange' : 'lightgray'}} onClick={(e) => {
              e.preventDefault();
              addId(item)}
            }>{item}</button>
          })}
          </ul>
        }
        {isStarted && <div className="yourNumbersContainer">
            <h2 className="yourNum">Your Numbers</h2>
            <div className="yourNumberContainer">
              {selectedIds.map((e: any, i: number) => 
                <div key={i} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: winIds.some((i) => i === e) ? 1 : 0.35}}>
                  <img style={{width: '50px', height: '50px'}} src={BallOn} key={i} />
                  <p className="selNum" >{e}</p>
                </div>
              )}
            </div>
          </div>
        }
        <br />
        {/* <hr /> */}
      </div>
    </div>
  );
};

export default App;

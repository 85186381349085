import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import './TipsLeft.css'
import HeartOn from '../../assets/tip_on.png';
import HeartOff from '../../assets/tip_off.png';

interface Props {
  tipsLeft: number
  prizes: any
  prizeClick: (i: number) => void
}

const TOTAL_TIPS = 5

const TipsLeft: FunctionComponent<Props> = ({ tipsLeft, prizes, prizeClick }) => {
  const ariaLabel: string = `Guesses left: ${tipsLeft}`
  // console.log(`TOTAL_TIPS-tipsLeft=${TOTAL_TIPS-tipsLeft}`)
  // console.log(`prizes=${JSON.stringify(prizes)}`)
  return (
    <div className="TipsContainer">
      <div className="tipsLeft" aria-label={ariaLabel}>
        {[...Array(TOTAL_TIPS-tipsLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOff} key={i} />)}{[...Array(tipsLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOn} key={i} />)}
        {/* &nbsp;{(TOTAL_TIPS-tipsLeft) < TOTAL_TIPS && `=` } */}
        =
        {/* {(TOTAL_TIPS-tipsLeft) < TOTAL_TIPS &&  */}
           <div style={{display: 'block', cursor: 'pointer'}} onClick={() => prizeClick(TOTAL_TIPS-tipsLeft)}><img src={prizes[TOTAL_TIPS-tipsLeft].imageSmall} style={{width: 36, height: 36, objectFit: 'cover', marginLeft: '5px'}} /></div>
        {/* } */}
      </div>
    </div>
  )
}

TipsLeft.propTypes = {
  tipsLeft: PropTypes.number.isRequired
}

export default TipsLeft

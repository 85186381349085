import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  homeContainer: {
    maxWidth: '480px',
    padding: "0 20px",
    minHeight: "100vh",
    height: "auto",
    position: "relative",
    background: "#000000",
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      maxWidth: '600px'
    },
    // margin: "0 auto",
  },
  homeContent: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },

  topbar: {
    display: 'flex',
    padding: '10px',
    color: "#338090"
  },
  powerL:{
    textAlign:"left",
    width:'10%'
  },
  powerR:{
    fontSize: '13px',
    width:'80%'
  },
 
  power:{
    position:'absolute',
    color: "#3daaba",
    paddingLeft:'15px',
    paddingTop:'20px',
    fontSize:'14px',

  },
  Mian:{
   textAlign:'-webkit-center',
   marginTop:'10px'
  },
  logo: {
    width: '70px',
    height: '70px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: ' 2px solid #39a8b2',
    marginBottom:'30px',
    marginTop:"16px"
  },
  img: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
  },
  score:{
    color:'#fff',
    fontSize:'14px',
    fontWeight: 600
  },
  point:{
    fontFamily: 'Montserrat, sans-serif',
    fontSize:'52px',
    margin:'20px 0'
  },
  envolop:{
    width: 120,
    height: 120,
    objectFit: 'contain',
    marginBottom:'35px'
  },
  you:{
    fontSize:"13px",
    color:"#949494",
    fontWeight: 600
  },
  timing:{
    margin:'0',
    color:'#b1348f',
    marginTop: 5
  },
  rank:{
    marginTop: 40,
    fontSize:"10px",
    color:'white'

  },
  position:{
    fontWeight:'500'
  },
  footer:{
    display:'flex',
    justifyContent:'space-between',
    color:'#fff',
    padding:'0 10px',
    color:"#949494",
  },
  shield:{
    fontSize:'13px',
    margin:'10px 0 0 0 '
  },
  sheildicon:{
    color:'#12acb8',
    cursor:'pointer'
  },
  footericn:{
    cursor:'pointer'
  },
  mainshield:{
    marginBottom:'7px'
  },
  balance:{
   color:'#3daaba',
   paddingLeft:4
  },
  hostedBy: {
    fontSize: '15px',
    fontWeight: '500',
    color: '#bbb',
    marginTop: '10px',
    fontFamily: 'canaroBook',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bonus: {
    margin: '10px',
    color: '#00acb8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 400,
    fontFamily: 'canaroBook',
  },
  postloaderImg: {
    width: '182px',
    height: '182px',
    objectFit: 'contain',
    margin: '10px auto 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

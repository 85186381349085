import { NetworkType } from "@elrondnetwork/dapp-core";

export const decimals = 4;
export const denomination = 18;
export const gasPerDataByte = 1500;
export const timeout = 10000; // 10 sec

export const walletConnectBridgeAddresses = [
  "https://bridge.walletconnect.org",
];
export const walletConnectBridge = walletConnectBridgeAddresses[0];

export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/";

export const contractAddress =
  "erd1qqqqqqqqqqqqqpgquvt728n40ssd8n2qns9jrlqpwq2jc4rj4cysfuj3ad";

export const dAppName = "Dapp";

// export const network = {
//   id: "testnet",
//   name: "testnet",
//   egldLabel: "xEGLD",
//   walletAddress: "https://testnet-wallet.elrond.com",
//   apiAddress: "https://testnet-api.elrond.com",
//   gatewayAddress: "https://testnet-gateway.elrond.com",
//   explorerAddress: "http://testnet-explorer.elrond.com",
//   graphQlAddress: "https://testnet-exchange-graph.elrond.com/graphql",
// };

export const network = {
  id: "main",
  name: "main",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com",
  graphQlAddress: "https://exchange-graph.elrond.com/graphql",
};
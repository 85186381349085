import Wordle from '../assets/images/wordle.png';
import WordleWhite from '../assets/images/wordle_white.png';
import Numblr from '../assets/images/numblr.png';
import NumblrWhite from '../assets/images/numblr_white.png';
import Topic from '../assets/images/topic.png';
import TopicWhite from '../assets/images/topic_white.png';

export const doblekaholFooterMenu = [
  {
    label: 'KAHOL',
    icon: Topic,
    iconWhite: TopicWhite,
  },
  {
    label: 'PANALO',
    icon: Wordle,
    iconWhite: WordleWhite,
  },
  {
    label: 'SWERTE',
    icon: Numblr,
    iconWhite: NumblrWhite,
  },
];

import { DictionaryType } from './'

const dictionary_en: DictionaryType[] = [
  {
    questionId: 100084,
    category: 'The price of this supplement is 365 pesos per 250ml bottle.',
    words: [
      'Rondlex Folic'
    ]
  },
{
    questionId: 100085,
    category: 'This supplement targets OBGYN.',
    words: [
      'Rondlex Folic'
    ]
  },
{
    questionId: 100086,
    category: 'It has 400mcg folic acid content.',
    words: [
      'Rondlex Folic'
    ]
  },
{
    questionId: 100087,
    category: 'The recommended dosage per day for Rondlex Folic is 3 __________.',
    words: [
      'tablespoons'
    ]
  },
{
    questionId: 100088,
    category: 'One of the MD Specialty target of Rondlex Folic.',
    words: [
      'General Medicine'
    ]
  },
{
    questionId: 100089,
    category: 'The price of this supplement is 39 pesos per capsule.',
    words: [
      'Rondlex Forte'
    ]
  },
{
    questionId: 100090,
    category: 'This supplement targets Nephrology.',
    words: [
      'Rondlex Forte'
    ]
  },
{
    questionId: 100091,
    category: 'It has 45mg Ascorbic acid content.',
    words: [
      'Rondlex Forte'
    ]
  },
{
    questionId: 100092,
    category: 'The recommended dosage per day for Rondlex Forte is 2 __________.',
    words: [
      'Capsules'
    ]
  },
{
    questionId: 100093,
    category: 'One of the target specialty of Rondlex Forte',
    words: [
      'Nephrology'
    ]
  },
];


export default dictionary_en
import React, { Component, Fragment } from 'react'
import Alphabet from '../../components/Alphabet'
import Category from '../../components/Category'
import GameResult from '../../components/GameResult'
import GuessesLeft from '../../components/GuessesLeft'
import LanguageSwitcher from '../../components/LanguageSwitcher'
import { LetterType } from '../../components/Letter'
import NewWord from '../../components/NewWord'
import Word from '../../components/Word'
import dictionary_pharma from '../../dictionaries/pharma'
import dictionary_en from '../../dictionaries/dictionary-en'
import dictionary_fi from '../../dictionaries/dictionary-fi'
import dictionary_es from '../../dictionaries/dictionary-es'
import './App.css'
import { getJoinSummary } from '../../../../services/ApiService'
import { ids } from '../../../../constants'

interface State {
  alphabet: string
  letters: LetterType[]
  category: any
  guessedLetters: Set<string>
  guessedTheWord: boolean
  guessesLeft: number
  language: string
  word: LetterType[],
  selectedPrize: number,
  showPrize: boolean
}

class App extends Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = this.initialState()
    this.changeLanguage = this.changeLanguage.bind(this)
    this.selectLetter = this.selectLetter.bind(this)
    this.startNewGame = this.startNewGame.bind(this)
    this.addKeyPressListener()
  }

  initialState(language: string = 'gb'): State {
    const alphabet: string = this.getAlphabet(language)

    const letters: LetterType[] = Array.from(alphabet).map(letter => ({
      letter,
      disabled: false
    }))

    const dictionaries = this.getDictionaries()
    const dictionary: Object | undefined = dictionaries.get(language)
    const category = this.randomCategory(dictionary)

    const word: LetterType[] = Array.from(this.randomWord(category)).map(
      letter => ({
        letter,
        disabled: false
      })
    )

    const guessedLetters: Set<string> = new Set()
    const guessesLeft: number = 6
    const guessedTheWord: boolean = false
    const selectedPrize: number = -1
    const showPrize: boolean = false

    this.props.setWord(word.map(item => item.letter).join(''));
    this.props.setQuestionId(category.questionId);

    return {
      alphabet,
      letters,
      language,
      category,
      word,
      guessedLetters,
      guessesLeft,
      guessedTheWord,
      selectedPrize,
      showPrize
    }
  }

  getAlphabet(language: string): string {
    const defaultAlphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

    return language === 'gb'
      ? defaultAlphabet
      : language === 'fi'
      ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ'
      : language === 'es'
      ? 'AÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ'
      : defaultAlphabet
  }

  getDictionaries(): Map<string, Object> {
    const dictionaries: Map<string, Object> = new Map()
    dictionaries.set('fi', dictionary_fi)
    dictionaries.set('gb', dictionary_en)
    dictionaries.set('es', dictionary_es)
    if (Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypePharma) {
      dictionaries.set('gb', dictionary_pharma)
    }

    return dictionaries
  }

  randomCategory(dictionary: any) {
    const categories = []

    for (const entry of dictionary.entries()) {
      // eslint-disable-next-line
      let [index, object] = entry
      categories.push(object)
    }

    if (this.props.fromNotification && this.props.questionId) {
      const data = categories.filter((i) => i.questionId === this.props.questionId)
      if (data.length > 0) {
        return data[0]
      }
    }
    else if (typeof this.props.wins !== 'undefined' && typeof this.props.set1 !== 'undefined'
      && typeof this.props.set2 !== 'undefined' && typeof this.props.set3 !== 'undefined') 
    {
      // console.log(`this.props.wins=${this.props.wins}`)
      if (this.props.wins > 0) {
        if (this.props.wins >= 8) {
          const data = categories.filter((i) => this.props.set3.some((a) => a === i.questionId))
          if (data.length > 0) {
            return data[Math.floor(Math.random() * data.length)]
          }
        }
        else if (this.props.wins >= 4) {
          const data = categories.filter((i) => this.props.set2.some((a) => a === i.questionId))
          if (data.length > 0) {
            return data[Math.floor(Math.random() * data.length)]
          }
        }
      }
      const data = categories.filter((i) => this.props.set1.some((a) => a === i.questionId))
      if (data.length > 0) {
        return data[Math.floor(Math.random() * data.length)]
      }
    }
    return categories[Math.floor(Math.random() * categories.length)]
  }

  randomWord(category: { words: string[] }): string {
    return category.words[
      Math.floor(Math.random() * category.words.length)
    ].toUpperCase()
  }

  changeLanguage(language: string): void {
    this.setState(this.initialState(language))
  }

  selectLetter(selectedLetter: string): void {
    this.updateGameStatus(this.state, selectedLetter)
  }

  updateGameStatus(state: State, selectedLetter: string): void {
    if (state.guessesLeft > 0 && !state.guessedTheWord) {
      const nextState: State = { ...state }
      const alreadyGuessedLetter: boolean = state.guessedLetters.has(
        selectedLetter
      )

      if (!alreadyGuessedLetter) {
        nextState.guessedLetters.add(selectedLetter)
        const letter: LetterType | undefined = nextState.letters.find(
          letter => letter.letter === selectedLetter
        )

        if (letter) {
          letter.disabled = true
        }
      }

      const guessedTheWord: boolean = nextState.word
        .filter(letter => !['-', ' '].includes(letter.letter))
        .every(letter => nextState.guessedLetters.has(letter.letter))

      const lettersFoundInWord: boolean =
        nextState.word.filter(letter => letter.letter === selectedLetter)
          .length > 0

      if (guessedTheWord) {
        nextState.guessedTheWord = true;
      } else if (!alreadyGuessedLetter && !lettersFoundInWord) {
        nextState.guessesLeft--
      }

      nextState.word.forEach(letter =>
        letter.letter === selectedLetter ? (letter.disabled = true) : ''
      )

      this.setState(nextState, () => {
        // console.log(`nextState=${JSON.stringify(nextState)}`)
        if (guessedTheWord) {
          this.props.notifyWon(6 - nextState.guessesLeft, state.word.map(item => item.letter).join(''))
        }
        else if (nextState.guessesLeft == 0) {
          setTimeout(() => {
            this.props.notifyLost(nextState.word.map(item => item.letter).join(''));
          }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
        }
      })
    }
  }

  startNewGame(): void {
    this.setState(this.initialState(this.state.language))
  }

  addKeyPressListener(): void {
    document.addEventListener('keydown', event => {
      const letter: string = event.key.toUpperCase()

      if (this.state.alphabet.includes(letter)) {
        this.selectLetter(letter)
      }
    })
  }

  prizeClick(i: number): void {
    // console.log('here')
    this.setState({showPrize: true, selectedPrize: i});
  }

  render() {
    const languages: string[] = ['fi', 'gb', 'es']
    console.log(`this.state.word=${JSON.stringify(this.state.word)}`)  
    return (
      <div className="topic-main-bg" style={{backgroundImage: `url(${this.props.background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundBlendMode: 'luminosity'}}>
        {/* <img src={this.props.background} style={{resize: 'stretch', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}></img> */}
        <Fragment>
          {/* <p className="sr-only">
            Welcome to play word guess game. Try to guess the word from the given
            category. Use keyboard to guess the letters.
          </p> */}
          <Category category={this.state.category} />
          <Word
            word={this.state.word}
            guessedTheWord={this.state.guessedTheWord}
          />
          <Alphabet letters={this.state.letters} onClick={this.selectLetter} />
          <div className="footer">
            <GuessesLeft guessesLeft={this.state.guessesLeft} prizes={this.props.prizes} prizeClick={(i) => this.prizeClick(i)} />
            {/* <GameResult
              guessedTheWord={this.state.guessedTheWord}
              guessesLeft={this.state.guessesLeft}
            /> */}
            {/* <LanguageSwitcher
              languages={languages}
              onClick={this.changeLanguage}
            /> */}
            {/* <NewWord onClick={this.startNewGame} /> */}
          </div>
        </Fragment>
        {this.state.showPrize && (
          <div
            className="tutorialContainer"
            onClick={() => this.setState({showPrize: false})}
          >
            <img src={this.props.prizes[this.state.selectedPrize].imageBig} className="tutorialImage" />
            <p className="close">X</p>
          </div>
        )}
      </div>
    )
  }
}

export default App

import { Clue, clueClass, CluedLetter, clueWord } from "./clue";
import Coin from "./assets/images/header-images/Coin.png";
import './Row.css';

export enum RowState {
  LockedIn,
  Editing,
  Pending,
}

interface RowProps {
  rowState: RowState;
  wordLength: number;
  cluedLetters: CluedLetter[];
  annotation?: string;
  prize: any;
  prizeClick: {(): void}
}

export function Row(props: RowProps) {
  const isLockedIn = props.rowState === RowState.LockedIn;
  const isEditing = props.rowState === RowState.Editing;
  const letterDivs = props.cluedLetters
    .concat(Array(props.wordLength).fill({ clue: Clue.Absent, letter: "" }))
    .slice(0, props.wordLength)
    .map(({ clue, letter }, i) => {
      let letterClass = "Row-letter";
      if (isLockedIn && clue !== undefined) {
        letterClass += " " + clueClass(clue);
      }
      return (
        <td
          key={i}
          className={letterClass}
          aria-live={isEditing ? "assertive" : "off"}
          aria-label={
            isLockedIn
              ? letter.toUpperCase() +
                (clue === undefined ? "" : ": " + clueWord(clue))
              : ""
          }
        >
          {letter}
        </td>
      );
    });
  let rowClass = "Row";
  if (isLockedIn) rowClass += " Row-locked-in";
  return (
    <tr className={rowClass}>
      {letterDivs}
      {props.annotation && (
        <span className="Row-annotation">{props.annotation}</span>
      )}
      {/* {props.prize.amount > 0 && <div style={{display: 'block', cursor: 'pointer'}} onClick={() => props.prizeClick()}><img src={Coin} style={{width: 50, height: 50, objectFit: 'cover', marginLeft: '30px', padding: 8}} /></div>}
      {typeof props.prize.product !== 'undefined' && <div style={{display: 'block', cursor: 'pointer'}} onClick={() => props.prizeClick()}><img src={props.prize.product.imageBig} style={{width: 50, height: 50, objectFit: 'cover', marginLeft: '30px'}} /></div>} */}
      <div style={{display: 'block', cursor: 'pointer', width: 72}} /> 
      {/* onClick={() => props.prizeClick()}><img src={props.prize} style={{width: 36, height: 36, objectFit: 'cover', marginLeft: '30px'}} /></div> */}
    </tr>
  );
}

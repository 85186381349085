import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InnerPageHeader from "../../Components/InnerPageHeader";
import ScoreCounter from "../../Components/ScoreCounter";
import { routes } from "../../constants";
import useStyles from "./styles";
import profile from "../../assets/images/common-images/user_placeholder.png";
import {
  formatNumberWithComma
} from "../../utils/utils";
import { getRondleAttackableUsers, addRondleSteal } from "../../services/ApiService";

const Steal = () => {
  const styles = {
    wordContainer: {
      position: 'absolute',
      maxWidth: '480px',
      top: 50,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      width: '100%',
      height: '20%',
      zIndex: 2,
    },
    title: {
      color: '#db3eb1',
      fontSize: '12px',
      fontWeight: 600,
      textAlign: 'center',
      fontFamily: 'canaroMedium',
      marginTop: '35px',
    },
    word: {
      color: 'white',
      fontSize: '20px',
      fontWeight: 600,
      textAlign: 'center',
      fontFamily: 'canaroMedium',
      marginTop: '10px',
    },
    amountContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'space-evenly',
      maxWidth: '480px'
    },
    amount: {
      width: '27%',
      marginTop: '10px',
      marginLeft: '2.5%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      paddingTop: '7px',
      paddingRight: '7%',
      cursor: 'pointer'
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      position: 'absolute',
      bottom: 40,
      width: '100vw',
      maxWidth: '480px'
    },
    buttonBuyContainer: {
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 1,
      border: "1px solid #28BBB1",
      backgroundColor: "#28BBB1",
      width: 150,
      height: 30,
      textAlign: "center",
      borderRadius: 30,
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      fontFamily: 'CanaroMedium'
    },
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const props = useLocation().state;
  const [selectedId, setSelectedId] = useState();
  const [seletedAmount, setSelectedAmount] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await getRondleAttackableUsers();
      //  console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data);        
      }
    } catch (e) {
      console.log(`error=${e}`);
    }
  };

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    document.body.classList.add("not-scrollable");

    // returned function will be called on component unmount 
    return () => {
      document.body.classList.remove("not-scrollable");
    }
  }, [])

  useEffect(() =>  {
    fetchData();
  }, [])

  // const data = [
  //   {
  //     id: 131233,
  //     avatarBig: '',
  //     displayName: '6jqkvmvj6',
  //     amount: 100
  //   },
  //   {
  //     id: 131232,
  //     avatarBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1652939437121.jpg',
  //     displayName: 'mguu7',
  //     amount: 250
  //   },
  //   {
  //     id: 131224,
  //     avatarBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1652776295199.png',
  //     displayName: 's9p2d4t',
  //     amount: 750
  //   },
  //   {
  //     id: 131223,
  //     avatarBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1652873299497.jpeg',
  //     displayName: '3ewpj',
  //     amount: 300
  //   },{
  //     id: 131221,
  //     avatarBig: '',
  //     displayName: 'gtsxf2wg4',
  //     amount: 650
  //   }
  // ]

  const onStealPress = async () => {
    try {
      const selected = data.filter((item) => item.id === selectedId)
      const param = {
        "engagementId": props.engagementId,
        "defenderId": selected[0].id,
        "defender": selected[0].display_name,
        "challenger": props.userData.displayName,
        "betAmount": 50,
        "gameId": 1,
        "difficultyClassId": 1,
        "questionId": props.questionId,
        "background": props.background,
      }
      await addRondleSteal(param);
      navigate(routes.home);
    } catch (e) {
      console.log(`error=${e}`);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.home)} title='Steal' style={{position: 'absolute'}}/>
        <div style={styles.wordContainer}>
          <span style={styles.title}>Learn the Topic</span>
          <span style={styles.word}>{props.word}</span>
          <span style={styles.title}>Bet Amount</span>
          <div style={styles.amountContainer}>
            <div style={styles.amount} onClick={() => setSelectedAmount(50)}>
              <ScoreCounter balance={50}/>
            </div>
            {/* <div style={styles.amount} onClick={() => setSelectedAmount(100)}>
              <ScoreCounter balance={100}/>
            </div>
            <div style={styles.amount} onClick={() => setSelectedAmount(200)}>
              <ScoreCounter balance={200}/>
            </div> */}
          </div>
          <span style={styles.title}>Select Player</span>
          <div
            style={{
              padding: "0px 10px 10px 10px",
              marginTop: 5,
              minHeight: 100,
              width: '100%',
              cursor: 'pointer'
            }}
          >
            {data.map((e, index) => {
              return (
                <div key={`profile-1${index}`} className={classes.list} onClick={() => setSelectedId(e.id)}>
                  <div className={classes.Nom}>
                    {e.id === selectedId && <p className={classes.number}>&#10003;</p>}
                  </div>
                  <img
                    src={e.avatar_big ? e.avatar_big : profile}
                    className={classes.avt}
                  />
                  <div className={classes.usercode} style={{color: e.id === selectedId ? '#00acb8' : 'white'}}>
                    <p className={classes.para}>{e.display_name}</p>
                  </div>
                  <div className={classes.userid} style={{color: e.id === selectedId ? '#00acb8' : 'white'}}>
                    <p>{formatNumberWithComma(Math.min(e.coin_amount, e.remaining_amount), 0)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.buttonBuyContainer} onClick={onStealPress}>
            STEAL
          </button>
        </div>
      </div>
    </>
  );
};

export default Steal;

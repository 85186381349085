import axios from "axios";
import CryptoJS from "crypto-js";
import qs from "qs";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common = {
  "Content-Type": "application/json",
};

const setAuthHeader = (token) => {
  axios.defaults.headers.common.Authorization = token;
};

const resetHeader = () => {
  axios.defaults.headers.common = {
    "Content-Type": "application/json",
  };
};

const generateHeader = (params = {}, method = "GET", header = null) => {
  const body = method === "GET" ? qs.stringify(params) : JSON.stringify(params);
  const hash = CryptoJS.HmacSHA256(
    body,
    `${process.env.REACT_APP_SIGNING_KEY}`
  ).toString(CryptoJS.enc.Hex);
  return {
    ...axios.defaults.headers.common,
    ...(header || {}),
    "x-hash-signature": hash,
  };
};

const generateAdminHeader = (params = {}, method = "GET", header = null) => {
  const body = method === "GET" ? qs.stringify(params) : JSON.stringify(params);
  const hash = CryptoJS.HmacSHA256(
    body,
    `${process.env.REACT_APP_SIGNING_KEY}`
  ).toString(CryptoJS.enc.Hex);
  return {
    ...axios.defaults.headers.common,
    ...(header || {}),
    "x-hash-signature": hash,
    'X-Admin-ID': 'admin'
  };
};

const adminGet = (path, params = {}, headers = null) => {
  return new Promise((resolve, reject) => {
    try {
      const body = qs.stringify(params);
      const _headers = generateAdminHeader(params, "GET", headers);
      axios
        .get(path + (body ? "?" : "") + body, {
          headers: _headers,
        })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

const adminPost = (path, params = {}, headers = null) => {
  return new Promise((resolve, reject) => {
    try {
      const _headers = generateAdminHeader(params, "POST", headers);
      // console.log(params, _headers);
      axios
        .post(path, params, { headers: _headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

const getResponse = (res) => {
  if (res && (res.status === 200 || res.status === 201 || res.status === 204)) {
    return { data: res.data, headers: res.headers };
  }
  throw new Error("Some error occur");
};

const get = (path, params = {}, headers = null) => {
  return new Promise((resolve, reject) => {
    try {
      const body = qs.stringify(params);
      const _headers = generateHeader(params, "GET", headers);
      axios
        .get(path + (body ? "?" : "") + body, {
          headers: _headers,
        })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

const post = (path, params = {}, headers = null) => {
  return new Promise((resolve, reject) => {
    try {
      const _headers = generateHeader(params, "POST", headers);
      // console.log(params, _headers);
      axios
        .post(path, params, { headers: _headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

const put = (path, params = {}, headers = null) => {
  const _headers = generateHeader(params, "PUT", headers);
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(path, params, { headers: _headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

export default { get, post, put, setAuthHeader, resetHeader, adminGet, adminPost };

import React, { useEffect, useState } from 'react';
import ProfileIcon from '../ProfileIcon';
import ScoreCounter from '../ScoreCounter';
import useStyles from './styles';

import {
  getUserBalance
} from "../../services/ApiService";

const Header = (props) => {
  const classes = useStyles();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    fetchGetBalance();
  }, [])

  const fetchGetBalance = async () => {
    try {
      const response = await getUserBalance();

      if (response) {
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), process.env.REACT_APP_ENCRYPT_KEY).toString();
        localStorage.setItem(
          "balance",
          JSON.stringify(response.data.data)
        );
        setBalance(response.data.data.coinAmount);
        // setTickets(response.data.data.ticketQuantity);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.profileContainer}>
        <ProfileIcon setSidebarOpen={props.setSidebarOpen} avatar={props.avatar} notificationCount={props.notificationCount}/>
      </div>
      <div className={classes.balanceContainer}>
        <ScoreCounter balance={balance}/>
      </div>
      <div className={classes.buyContainer}>
        <p className={classes.buy}>BUY</p>
      </div>
    </div>
  );
};

export default Header;

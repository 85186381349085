import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // homeContainer: {
  //   height: '100%',
  //   position: 'relative',
  //   display: 'flex',
  //   alignItems: 'center',
  //   [theme.breakpoints.down("xs")]: {
  //     width: "100%",
  //     height: "100%",
  //   },
  //   flexDirection: 'column'
  // },
  colcontainer: {
    height: '100%',
    maxWidth: '480px',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  detailContent: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
  receiveImage: {
    width: 280,
    objectFit: 'contain'
  },
  displayDetailImage: {
    display: 'flex',
    justifyContent: 'center'
  },
  staticLabel: {
    marginTop: -50,
    color: 'white',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'CanaroBook'
  },
  divider: {
    backgroundColor: '#00acb8',
    width: '100%',
    height: 1,
    marginTop: 30
  },
  staticLabel2: {
    marginTop: 20,
    color: '#bbd3d3',
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'CanaroMedium'
  },
  address: {
    marginTop: 40,
    color: 'white',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'CanaroBook',
    maxWidth: 280,
    wordWrap: 'break-word'
  },
  staticLabel3: {
    marginTop: 60,
    color: '#bbd3d3',
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'CanaroMedium'
  },
  link: {
    color: '#00acb8',
    textDecorationLine: 'underline',
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'CanaroMedium'
  },
  claimed: {
    marginTop: -10,
    color: '#db3eb1',
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'CanaroBook',
  },
  staticLabel4: {
    color: '#db3eb1',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'CanaroMedium'
  },
}));

import { maxWidth } from "@mui/system";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import InnerPageHeader from "../../../Components/InnerPageHeader";
import { ids, routes } from "../../../constants";
import useStyles from "./styles";

const LessonDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const props = useLocation().state;
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [type, setType] = useState(0);
  const [title, setTitle] = useState("");
  const [leftButtonLabel, setLeftButtonLabel] = useState("");
  const [rightButtonLabel, setRightButtonLabel] = useState("");
  const [data ] = useState(props.lessonData);
  // const [data, setData] = useState([
  //   {
  //     id: 1,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1644636431460.jpeg',
  //     profileImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653292638277.jpg',
  //     topics: ['https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653292922238.jpg',
  //       'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1647422457482.jpeg',
  //       'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1650975987337.jpeg'
  //     ],
  //     links: [{
  //       imageBig: "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653296284889.jpg",
  //       link: "https://maka-bros.com"
  //     }]
  //   },
  //   {
  //     id: 2,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643449215888.jpeg',
  //     profileImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/F548213023954BGB055.jpg',
  //     topics: [],
  //     links: []
  //   },
  //   {
  //     id: 3,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643448321384.jpeg',
  //     profileImageBig: '',
  //     topics: [],
  //     links: []
  //   },
  //   {
  //     id: 4,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643447114253.jpeg',
  //     profileImageBig: '',
  //     topics: [],
  //     links: []
  //   },
  //   {
  //     id: 5,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1644636431460.jpeg',
  //     profileImageBig: '',
  //     topics: [],
  //     links: []
  //   },
  //   {
  //     id: 6,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643449215888.jpeg',
  //     profileImageBig: '',
  //     topics: [],
  //     links: []
  //   },
  //   {
  //     id: 7,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643448321384.jpeg',
  //     profileImageBig: '',
  //     topics: [],
  //     links: []
  //   },
  //   {
  //     id: 8,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643447114253.jpeg',
  //     profileImageBig: '',
  //     topics: [],
  //     links: []
  //   }
  // ]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  const getHeader = () => {
    switch (type) {
      case 0:
        setTitle('Profile');
        setLeftButtonLabel(Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypePharma ? 'Products' : 'Projects');
        setRightButtonLabel('Topics');
        break;
      case 1:
        setTitle('Topics');
        setLeftButtonLabel('Profile');
        setRightButtonLabel('Links');
        break;
      case 2:
        setTitle('Links');    
        setLeftButtonLabel('Topics');
        setRightButtonLabel(Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypePharma ? 'Products' : 'Projects');
        break;
    }
  }

  useEffect(() => {
    getHeader();
    // console.log(`data=${data}`)
    // console.log(`JSON.parse(data.profile)=${JSON.parse(data.profile)}`)
    // console.log(`JSON.parse(data.topics)=${JSON.parse(data.topics)}`)
    // console.log(`JSON.parse(data.links)=${JSON.parse(data.links)}`)
  },[type])

  const onLeftPress = () => {
    switch (type) {
      case 0:
        navigate(routes.lesson);
        break;
      case 1:
        setType(0);
        break;
      case 2:
        setType(1);
        break;    
    }
  }

  const onRightPress = () => {
    switch (type) {
      case 2:
        navigate(routes.lesson);
        break;
      case 1:
        setType(2);
        break;
      case 0:
        setType(1);
        break;    
    }
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.homeContent}>
          {type === 0 && data && JSON.parse(data.profile).length > 0 &&
            JSON.parse(data.profile).map((item, index) => (
              <img
                key={index}
                src={item}
                className={classes.homeContent}
                onClick={() => navigate(routes.lessonDetails, {state: {data: item}})}
              />
            ))
          }
          {type === 1 && data && JSON.parse(data.topics).length > 0 &&
            JSON.parse(data.topics).map((item, index) => (
              <img
                key={index}
                src={item}
                className={classes.homeContent}
                onClick={() => navigate(routes.lessonDetails, {state: {data: item}})}
              />
            ))
          }
          {type === 2 && data && JSON.parse(data.links).length > 0 &&
            JSON.parse(data.links).map((item, index) => (
              <img
                key={index}
                src={item.imageBig}
                className={classes.homeContent}
                onClick={() => window.open(item.link, "_blank")}
              />
            ))
          }
        </div>
      </div>
      <div style={{position: 'fixed', left: isMobile ? 0 : 'calc(50vw - 250px)', top: 0, height: '45px', paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignSelf: 'center', width: '100%', maxWidth: '480px'}}>
        <InnerPageHeader
          goBackClick={() => {
            navigate(routes.home);
          }}
          title={title}
          leftButtonLabel={leftButtonLabel}
          onLeftPress={onLeftPress}
          rightButtonLabel={rightButtonLabel}
          onRightPress={onRightPress}
        />
      </div>
    </>
  );
};

export default LessonDetails;

import { useEffect, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import InnerPageHeader from "../../Components/InnerPageHeader";
import useStyles from './style';
import Coin from '../../assets/images/header-images/Coin.png';
import HeartOn from '../../assets/images/common-images/heart_on.png';
import HeartOff from '../../assets/images/common-images/heart_off.png';
import BulbOn from '../../assets/images/common-images/tip_on.png';
import BulbOff from '../../assets/images/common-images/tip_off.png';
import Home from '../../assets/images/footer-images/home.png';
import Repeat from '../../assets/images/footer-images/repeat.png';
import Steal from '../../assets/images/footer-images/steal.png';
import Lesson from '../../assets/images/sidebar-images/lesson.png';
import Lose from "../../assets/images/gifs/postloader_lose.gif";
import CoinWin from "../../assets/images/gifs/postloader_win.gif";
import Confetti from '../../assets/images/gifs/confetti.gif';
import SoundCoinFountain from '../../assets/sounds/coin_fountain.mp3';
import SoundConfetti from '../../assets/sounds/confetti.mp3';
import SoundLose0 from "../../assets/sounds/lose_0.mp3";
import SoundLose1 from "../../assets/sounds/lose_1.mp3";
import SoundLose2 from "../../assets/sounds/lose_2.mp3";
import MoreFooter from "../../Components/MoreFooter";
import { ids, routes } from "../../constants";

enum GameState {
  Playing,
  Won,
  Lost,
}

function Postloader(props) {
  const styles = {
    mainContainer: {
      // backgroundColor: "red",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
    },

    arrowContainer: {
      position: "absolute",
      left: 10,
      marginBottom: 680,
      cursor: "pointer",
    },
    backArrow: {
      width: 30,
    },
    modalCircle: {
      position: "absolute",
      backgroundColor: "white",
      width: "90px",
      height: "90px",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "50px",
      left: (isMobile && "39%") || (!isMobile && "41%"),
      top: (isIOS && 'calc(10vh + 45px)') || 
      (isMobile && 'calc(10vh + 15px)') || 
      (!isMobile && 'calc(27vh + 15px)'),
      border: "2px solid #28BBB1",
      zIndex: 2
    },
    lockImage: {
      width: 60,
      margin: "auto",
      display: "flex",
      paddingTop: 12,
    },
    modalBox: {
      backgroundColor: "white",
      height: "65%",
      maxHeight: 375,
      minWidth: 250,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      border: "2px solid #28BBB1",
      marginBottom: isMobile ? 150 : 0
    },
    headerContainer: {
      width: "100%",
      height: 100,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      marginTop: "-20px",
    },
    headerText: {
      color: "grey",
      fontWeight: "700",
      fontSize: "15px",
      justifyContent: "center",
      paddingTop: 80,
      textAlign: "center",
      width: "100%",
    },
    text: {
      color: "grey",
      fontSize: "12px",
      justifyContent: "center",
      paddingTop: 5,
      textAlign: "center",
      width: "100%",
    },
    squareImageContainer: {
      width: "80%",
      height: 100,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      margin: "auto",
      paddingTop: 10,
      paddingBottom: 10,
    },
    squareImage: {
      width: 200,
      height: 150,
      borderRadius: "10px",
      margin: "auto",
      justifyContent: "center",
      display: "flex",
      objectFit: 'contain'
    },

    scoreCounterContainer: {
      width: "70%",
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: 'center',
      position: 'absolute',
      bottom: 140,
      left: '22%'
    },

    priceText: {
      width: "55%",
      height: 50,
      // alignItems: "center",
      // justifyContent: "center",
      color: "white",
      fontWeight: "700",
      fontSize: "12px",
      // textAlign: "center",
      paddingTop: 20,
      paddingLeft: 10,
    },

    scoreContainer: {
      width: "40%",
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "auto",
      marginLeft: 0
    },
    scoreImage: {
      width: 30,
      alignItems: "center",
      justifyContent: "center",
      // paddingLeft: 10,
    },
    scoreLabel: {
      color: "white",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: 1,
      paddingLeft: 15,
      paddingTop: 5
    },
    amountLabel: {
      color: "white",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: 1,
      paddingLeft: 5,
      paddingTop: 5
    },
    descriptionContainer: {
      width: 280,
      height: 50,
      justifyContent: "space-evenly",
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
      position: "relative",
      paddingTop: 40,

      // margin: "auto",
    },
    description: {
      color: "grey",
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 1,
      textAlign: "center",
      // paddingLeft: 30,
    },
    ticketImage: {
      width: 50,
    },
    ticketContainer: {
      position: "relative",
      marginTop: "auto",
      marginBottom: "auto",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      position: 'absolute',
      bottom: 100
    },

    // buttonCancelContainer: {
    //   color: "grey",
    //   fontWeight: 500,
    //   fontSize: "15px",
    //   lineHeight: 1,
    //   border: "1px solid #28BBB1",
    //   width: 90,
    //   height: 25,
    //   textAlign: "center",
    //   borderRadius: 30,
    //   paddingTop: 0,
    //   justifyContent: "center",
    //   cursor: "pointer",
    // },
    buttonCancelContainer: {
      backgroundColor: "transparent",
      fontWeight: 500,
      fontSize: "15px",
      width: 50,
      height: 50,
      position: 'absolute',
      right: (isMobile && "17%") ||
      (!isMobile && "23%"),
      borderColor: 'transparent',
      textAlign: "center",
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      color: '#707070',
      fontFamily: 'CanaroMedium'
    },
    buttonBuyContainer: {
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 1,
      border: "1px solid #28BBB1",
      backgroundColor: "#28BBB1",
      width: 150,
      height: 30,
      textAlign: "center",
      borderRadius: 30,
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      fontFamily: 'CanaroMedium'
    },
    prizeContainer: {
      position: 'absolute',
      maxWidth: '480px',
      bottom: 0,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      width: '100%',
      height: '100%',
      zIndex: 2,
      backgroundColor: 'black'
    }
  };

  const [gameResult, setGameResult] = useState(false);
  
  const classes = useStyles();
  const navigate = useNavigate();
  const COIN_ANIMATION_DELAY = 2800;

  useEffect(() => {
    if (props.gameState === GameState.Won) {
      const timer = setTimeout(() => {
        setGameResult(true);
      }, COIN_ANIMATION_DELAY);
      return () => clearTimeout(timer);
    }
    else if (props.gameState === GameState.Lost) {
      const timer = setTimeout(() => {
        setGameResult(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [props.gameState])

  const onHomePress = () => {
    localStorage.setItem("footerIndex", '0');
    localStorage.setItem("itemIndex", '0');
    navigate(routes.home);
  }

  const onRepeatPress = () => {
    setGameResult(false);
    props.onRepeatPress();
  }

  const onStealPress = () => {
    navigate(routes.steal, {state: { word: props.word, userData: props.userData, 
      questionId: props.questionId, engagementId: props.engagementId, background: props.background }});
  }

  const onLessonPress = () => {
    const item = props.lessonData.filter((i) => i.source.id === props.sourceId)
    if (item.length > 0) {
      navigate(routes.lessonDetails, {state: {lessonData: item[0], fromPostloader: true}})
    }
    else {
      navigate(routes.lesson)
    }
  }

  const MAX_GUESS = typeof props.maxGuess !== 'undefined' ? props.maxGuess : 6;

  return (
    <>
      {props.gameState === GameState.Won && (
        <div style={styles.prizeContainer}>
          <img src={props.avatarBig} className={classes.logo} />
          <span className={classes.correctAnswer}>{props.correctText}</span>
          <span className={classes.answer}>{props.word}</span>
          {gameResult === false && props.hofWinData.amount > 0 && <><img
              src={CoinWin + "?a=" + Math.random()}
              alt="jackpot ticket"
              className={classes.scratchWinCoinImage}
            />
            {/* <iframe src={SoundCoinFountain}
              frameborder='0'
              allow='autoplay; encrypted-media'
              style={{width: 0, height: 0}}
              title='video'
            /> */}
            </>
          }
          {gameResult === false && props.hofWinData.product && <><img
              src={Confetti + "?a=" + Math.random()}
              alt="jackpot ticket"
              className={classes.scratchWinCoinImage}
            />
            {/* <iframe src={SoundConfetti}
              frameborder='0'
              allow='autoplay; encrypted-media'
              style={{width: 0, height: 0}}
              title='video'
            /> */}
            </>
          }
          {gameResult === true && <>
            <span className={classes.scratchWinMuch}>{Number(process.env.REACT_APP_RONDLE_TYPE) == ids.rondleTypeDobleKahol ? 'Nanalo ka!' : 'You earned'}</span>
            {props.hofWinData.amount > 0 && (
              <div>
                <div className={classes.coinImageText}>
                  <div className={classes.coinImageTextRow}>
                    <img
                      src={Coin}
                      className={classes.coinYellowImage}
                    />
                    <h2 className={classes.coinText}>
                      {props.hofWinData.amount}
                    </h2>
                  </div>
                </div>
                <div className={classes.availableProtectCoin}>
                </div>
                <div className={classes.scoreCounterRow}>
                </div>
              </div>
            )}
            {props.hofWinData.product && (
              <div>
                <img
                  src={props.hofWinData.product.imageBig}
                  alt="bengga coin img"
                  className={classes.scratchWinProductImage}
                />
              </div>
            )}
            {props.showHeart && <div style={{marginTop: '-20px', justifyContent: 'center', width: '100%', display: 'flex', backgroundColor: 'transparent'}}>
              {[...Array(props.guessLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOn} key={i} />)}{[...Array(MAX_GUESS-props.guessLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOff} key={i} />)}
            </div>}
            {props.showBulb && <div style={{marginTop: '-20px', justifyContent: 'center', width: '100%', display: 'flex', backgroundColor: 'transparent'}}>
              {[...Array(props.guessLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={BulbOn} key={i} />)}{[...Array(MAX_GUESS-props.guessLeft)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={BulbOff} key={i} />)}
            </div>}

            {/* <div style={styles.buttonContainer}>
              <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
                BACK
              </button>
            </div> */}
            { props.fromNotification ? <MoreFooter
                leftIcon={Lesson}
                onLeftIconPress={onLessonPress}
              /> : props.showSteal ? <MoreFooter
                leftIcon={Steal}
                rightIcon={Repeat}
                onLeftIconPress={onStealPress}
                onRightIconPress={onRepeatPress}
                // onCenterIconPress={onStealPress}
              /> : <MoreFooter
                leftIcon={Lesson}
                rightIcon={Repeat}
                onLeftIconPress={onLessonPress}
                onRightIconPress={props.onRepeatPress}
              />
            }
          </>}
          <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
        </div>
      )}
      {props.gameState === GameState.Lost && (
        <div style={styles.prizeContainer}>
          <img src={props.avatarBig} className={classes.logo} />
          <span className={classes.correctAnswer}>{props.correctText}</span>
          <span className={classes.answer}>{props.word}</span>
          {gameResult === false && <><img
              src={Lose + "?a=" + Math.random()}
              alt="jackpot ticket"
              className={classes.loseImg}
            />
            {/* <iframe
              src={
                Math.random() % 2 == 0
                  ? SoundLose0
                  : Math.random() % 2 == 0
                  ? SoundLose1
                  : SoundLose2
              }
              frameborder="0"
              allow="autoplay; encrypted-media"
              style={{ width: 0, height: 0 }}
              title="video"
            /> */}
            </>
          }
          {gameResult === true && <>
            <span className={classes.scratchWinMuch}>{props.timeOut ?  Number(process.env.REACT_APP_RONDLE_TYPE) == ids.rondleTypeDobleKahol ? 
              'Ubos na oras' : "Time's up" : props.fromNotification ? 'You lose' : 
              Number(process.env.REACT_APP_RONDLE_TYPE) == ids.rondleTypeDobleKahol ? 'Subok ulit' : 'Try again' 
            }</span>
            <div>
              <img
                src={props.postloaderImageBig}
                alt="bengga coin img"
                className={classes.lostImage}
              />
            </div>

            {/* <div style={styles.buttonContainer}>
              <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
                BACK
              </button>
            </div> */}
            { props.fromNotification ? <MoreFooter
                leftIcon={Lesson}
                onLeftIconPress={onLessonPress}
              /> : props.showSteal ? <MoreFooter
                leftIcon={Steal}
                rightIcon={Repeat}
                onLeftIconPress={onStealPress}
                onRightIconPress={onRepeatPress}
                // onCenterIconPress={onStealPress}
              /> : <MoreFooter
                leftIcon={Lesson}
                rightIcon={Repeat}
                onLeftIconPress={onLessonPress}
                onRightIconPress={props.onRepeatPress}
              />
            }
            <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
            </div>
          </>}  
        </div>
      )}
    </>
  )
}

export default Postloader;
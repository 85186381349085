import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import './GuessesLeft.css'
import HeartOn from '../../../../assets/images/common-images/heart_on.png';
import HeartOff from '../../../../assets/images/common-images/heart_off.png';

interface Props {
  guessesLeft: number
  prizes: any
  prizeClick: (i: number) => void
}

const GuessesLeft: FunctionComponent<Props> = ({ guessesLeft, prizes, prizeClick }) => {
  const ariaLabel: string = `Guesses left: ${guessesLeft}`
  // console.log(`6-guessesLeft=${6-guessesLeft}`)
  // console.log(`prizes=${JSON.stringify(prizes)}`)
  return (
    <div className="GuessContainer">
      <div className="GuessesLeft" aria-label={ariaLabel}>
        {[...Array(guessesLeft)].map((e: any, i: number) => <img style={{width: '24px', height: '24px'}} src={HeartOn} key={i} />)}{[...Array(6-guessesLeft)].map((e: any, i: number) => <img style={{width: '24px', height: '24px'}} src={HeartOff} key={i} />)}
        &nbsp;{(6-guessesLeft) < 6 && `=` }
        {(6-guessesLeft) < 6 && 
           <div style={{display: 'block', cursor: 'pointer'}} onClick={() => prizeClick(6-guessesLeft)}><img src={prizes[6-guessesLeft].imageSmall} style={{width: 36, height: 36, objectFit: 'cover', marginLeft: '5px'}} /></div>
        }
      </div>
    </div>
  )
}

GuessesLeft.propTypes = {
  guessesLeft: PropTypes.number.isRequired
}

export default GuessesLeft

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScoreImage from "../../assets/images/header-images/Coin.png";
import { checkAndPayOneTimeFee, coinUse, getOneTimePay, payOneTimeFee } from "../../services/ApiService";
import { ids } from "../../constants";
import { formatNumberWithComma } from "../../utils/utils";
import { isIOS, isMobile } from "react-device-detect";
import Logo from '../../assets/images/wordle.png';
import Tips from '../../assets/images/footer-images/tips.png';

const EntryFeeCover = (props) => {
  const navigate = useNavigate();

  const styles = {
    mainContainer: {
      // backgroundColor: "red",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
    },

    arrowContainer: {
      position: "absolute",
      left: 10,
      marginBottom: 680,
      cursor: "pointer",
    },
    backArrow: {
      width: 30,
    },
    modalCircle: {
      position: "absolute",
      backgroundColor: "white",
      width: "90px",
      height: "90px",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "45px",
      left: (isMobile && "39%") || (!isMobile && "41%"),
      top: (isIOS && 'calc(10vh + 40px)') || 
      (isMobile && 'calc(10vh + 35px)') || 
      (!isMobile && 'calc(27vh + 15px)'),
      border: "2px solid #28BBB1",
      zIndex: 2
    },
    lockImage: {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      margin: "auto",
      display: "flex",
    },
    modalBox: {
      backgroundColor: "white",
      height: (isIOS && '60vh') || 
      (isMobile && '65vh') || 
      (!isMobile && '65vh'),
      maxHeight: 375,
      minWidth: 250,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      border: "2px solid #28BBB1",
      position: "absolute",
      top: (isIOS && 'calc(10vh + 85px)') || 
      (isMobile && 'calc(10vh + 60px)') || 
      (!isMobile && 'calc(27vh + 60px)'),
    },
    headerContainer: {
      width: "100%",
      height: 100,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      marginTop: "-20px",
    },
    headerText: {
      color: "grey",
      fontWeight: "700",
      fontSize: "15px",
      justifyContent: "center",
      paddingTop: 80,
      textAlign: "center",
      width: "100%",
    },
    text: {
      color: "grey",
      fontSize: "12px",
      justifyContent: "center",
      paddingTop: 5,
      textAlign: "center",
      width: "100%",
    },
    squareImageContainer: {
      width: "80%",
      height: 100,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      margin: "auto",
      paddingTop: 10,
      paddingBottom: 10,
    },
    squareImage: {
      width: 200,
      height: 150,
      borderRadius: "10px",
      margin: "auto",
      justifyContent: "center",
      display: "flex",
      objectFit: 'contain'
    },

    scoreCounterContainer: {
      width: "90%",
      left: '5%',
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginTop: 90,
      marginLeft: 0,
      // margin: "auto",
      backgroundColor: "#5fc9bf",
      borderRadius: 6,
      position: "relative",
    },

    priceText: {
      width: "55%",
      height: 50,
      // alignItems: "center",
      // justifyContent: "center",
      color: "white",
      fontWeight: "700",
      fontSize: "18px",
      // textAlign: "center",
      paddingTop: '10px',
      paddingLeft: 10,
    },

    scoreContainer: {
      width: "40%",
      height: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "auto",
      marginLeft: 0
    },
    scoreImage: {
      width: 30,
      alignItems: "center",
      justifyContent: "center",
      // paddingLeft: 10,
    },
    scoreLabel: {
      color: "white",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: 1,
      paddingLeft: 15,
      paddingTop: 5
    },
    amountLabel: {
      color: "white",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: 1,
      paddingLeft: 5,
      paddingTop: 5
    },
    descriptionContainer: {
      width: 280,
      height: 50,
      justifyContent: "space-evenly",
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
      position: "relative",
      paddingTop: 40,

      // margin: "auto",
    },
    description: {
      color: "grey",
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 1,
      textAlign: "center",
      // paddingLeft: 30,
    },
    ticketImage: {
      width: 50,
    },
    ticketContainer: {
      position: "relative",
      marginTop: "auto",
      marginBottom: "auto",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      paddingTop: 10,
    },

    // buttonCancelContainer: {
    //   color: "grey",
    //   fontWeight: 500,
    //   fontSize: "15px",
    //   lineHeight: 1,
    //   border: "1px solid #28BBB1",
    //   width: 90,
    //   height: 25,
    //   textAlign: "center",
    //   borderRadius: 30,
    //   paddingTop: 0,
    //   justifyContent: "center",
    //   cursor: "pointer",
    // },
    buttonCancelContainer: {
      backgroundColor: "transparent",
      fontWeight: 500,
      fontSize: "15px",
      width: 50,
      height: 50,
      position: 'absolute',
      right: "2%",
      borderColor: 'transparent',
      textAlign: "center",
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      color: '#707070',
      fontFamily: 'CanaroMedium'
    },
    imageTips: {
      width: 50,
      height: 50,
      paddingTop: 10,
      paddingBottom: 20,
      paddingLeft: 15,
      paddingRight: 15
    },
    buttonTipsContainer: {
      backgroundColor: "transparent",
      fontWeight: 500,
      fontSize: "15px",
      width: 50,
      height: 50,
      position: 'absolute',
      left: "0%",
      borderColor: 'transparent',
      textAlign: "left",
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      color: '#707070',
      fontFamily: 'CanaroMedium'
    },
    buttonBuyContainer: {
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 1,
      border: "1px solid #28BBB1",
      backgroundColor: "#28BBB1",
      width: 100,
      height: 30,
      textAlign: "center",
      borderRadius: 30,
      paddingTop: 0,
      justifyContent: "center",
      cursor: "pointer",
      fontFamily: 'CanaroMedium'
    },
  };

  const [isFree, setIsFree] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [fetchedOneTime, setFetchedOneTime] = useState(false);
  const [oneTimePayData, setOneTimePayData] = useState(props.oneTimePayData);
  const [isClicked, setIsClicked] = useState(false);

  // const fetchOneTimePay = async() => {
  //   try {
  //     const response = await getOneTimePay();
  //     if (response) {
  //       setOneTimePayData(response.data.data);
  //       // console.log(`oneTimePayData=${JSON.stringify(response.data.data)}; props.engagementData.newId=${props.engagementData.newId}`)
  //       const paidData = response.data.data.filter((item) => item.engagementId === props.engagementData.newId)
  //       if (paidData.length > 0) {
  //         setIsPaid(true)
  //         setFetchedOneTime(true);
  //         setTimeout(() => {
  //           props.onJoin();
  //         }, 1000);
  //       }
  //       else {
  //         fetchOneTimePay(true);
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  useEffect(async () => {
    if (props.engagementData.joinFeeType == 1) { //free
      setIsFree(true);
    }
    else {
      // if (typeof oneTimePayData !== 'undefined' && oneTimePayData) {
      //   const paidData = oneTimePayData.filter((item) => item.engagementId === props.engagementData.newId)
      //   if (paidData.length > 0) {
      //     setIsPaid(true)
      //     setFetchedOneTime(true)
      //     setTimeout(() => {
      //       props.onJoin();
      //     }, 1000);
      //   }
      // }
      // else {
      //   await fetchOneTimePay();
      // }
    }
  }, [])

  const onJoin = async () => {
    if (isFree) {
      props.onJoin();
    }
    else {      
      if (!isPaid) {
        const balance = localStorage.getItem("balance") && JSON.parse(localStorage.getItem("balance"));
        if (balance.coinAmount < props.engagementData.joinFee) {
          window.alert(`Not enough coins`);
        }
        else {
          if (!isClicked) {
            setIsClicked(true);
            try {
              const feeParam = {
                "engagementId": props.engagementData.newId,
                "engagementTitle": props.engagementData.title,
                "amount": props.engagementData.joinFee,
                "pot": 1,
                "isOneTime": 0
              }
              
              await payOneTimeFee(feeParam)

              let txnType = ids.txnTypeRondleWordFee;
              if (props.engagementData.funTypeFamilyId == ids.FunTypeFamilyRondleTopics) {
                txnType = ids.txnTypeRondleTopicFee;
              }
              else if (props.engagementData.funTypeFamilyId == ids.FunTypeFamilyRondleNumbers) {
                txnType = ids.txnTypeRondleNumberFee;
              }
              const coinParam = {
                "transactionType": txnType,
                "description": `Entry fee in ${props.engagementData.title} Channel`,
                "amount": -props.engagementData.joinFee,
                "deviceBalance": 0,
                "serverBalance": 0
              }
              await coinUse(coinParam)
            }
            catch(e) {
              console.log(e)
            }
            props.onJoin();
          }
        }
      }
      else {
        props.onJoin();
      }
    }
  }

  return (
    <div style={styles.mainContainer}>
      {/* <div style={styles.arrowContainer} onClick={() => history("/")}>
        <img style={styles.backArrow} src={Arrow} alt="Arrow" />
      </div> */}
      <div style={styles.modalCircle}>
        <img style={styles.lockImage} src={props.engagementData.source.avatarBig} alt="lock" />
      </div>
      <div style={styles.modalBox}>
        <div >
          <button onClick={() => props.onCancel()} style={styles.buttonCancelContainer}>
            X
          </button>  
          <button onClick={() => props.onTips()} style={styles.buttonTipsContainer}>
            <img src={Tips} style={styles.imageTips}/>
          </button>  
        </div>
        <div style={styles.headerContainer}>
          {/* <div style={styles.headerText}>{`Win up to`}</div> */}
        </div>
        <div style={styles.squareImageContainer}>
          <img style={styles.squareImage} src={props.engagementData.preloaderImageBig} alt="Egld" />
        </div>
        <div style={styles.scoreCounterContainer}>
          <label style={styles.priceText}>{"Entry fee = "}</label>
          <div style={styles.scoreContainer}>
            <img style={styles.scoreImage} src={ScoreImage} alt="Score" />
            <label style={styles.amountLabel}>{formatNumberWithComma(props.engagementData.joinFee,0)}</label>
          </div>
        </div>

        <div style={styles.buttonContainer}>
          <button style={styles.buttonBuyContainer} onClick={() => onJoin()}>
            PLAY
          </button>
        </div>
      </div>
    </div>
  );
};

export default EntryFeeCover;

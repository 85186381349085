import { DictionaryType } from './'

const dictionary_en: DictionaryType[] = [
  {
    questionId: 10101,
    category: '',
    words: [
      'Michael Jackson'
    ],
    questions: [
      {
        correct_answer: "Singer",
        incorrect_answers: [
          "Actor"
        ]
      },
      {
        correct_answer: "American",
        incorrect_answers: [
          "Australian"
        ]
      },
      {
        correct_answer: "Male",
        incorrect_answers: [
          "Female"
        ]
      },
      {
        correct_answer: "From group to solo",
        incorrect_answers: [
          "From solo to group"
        ]
      },
      {
        correct_answer: "Pop singer",
        incorrect_answers: [
          "Rocker"
        ]
      },
      {
        correct_answer: "King of Pop",
        incorrect_answers: [
          "Queen of Pop"
        ]
      }
    ]
  },
  {
    questionId: 10202,
    category: '',
    words: [
      'Elvis Presley'
    ],
    questions: [
      {
        correct_answer: "Celebrity",
        incorrect_answers: [
          "Politician"
        ]
      },
      {
        correct_answer: "Male",
        incorrect_answers: [
          "Female"
        ]
      },
      {
        correct_answer: "International",
        incorrect_answers: [
          "Local"
        ]
      },
      {
        correct_answer: "Singer",
        incorrect_answers: [
          "Drummer"
        ]
      },
      {
        correct_answer: "American",
        incorrect_answers: [
          "Australian"
        ]
      },
      {
        correct_answer: "King of Rock and Roll",
        incorrect_answers: [
          "Queen of Rock and Roll"
        ]
      }
    ]
  }
]

export default dictionary_en

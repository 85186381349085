import { QuestionType } from './'

const doblekahol: QuestionType[] = [
  {
    questionId: 100094,
    setting: "Dinidilaan",
    questions: [
      {
        correct_answer: "Maikli",
        incorrect_answers: [
          "Mahaba"
        ]
      },
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Mabango",
        incorrect_answers: [
          "Mabaho"
        ]
      },
      {
        correct_answer: "Sinusubo",
        incorrect_answers: [
          "Hinihigop"
        ]
      },
      {
        correct_answer: "Kumakatas",
        incorrect_answers: [
          "Tuyot"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter I",
        incorrect_answers: [
          "Nag-uumpisa sa letter T"
        ]
      },
      {
        correct_answer: "Ice Candy",
        incorrect_answers: [
          "Iced Coffee",
          "Tahong",
          "Ice Cream"
        ]
      },
    ]
  },
  {
    questionId: 100095,
    setting: "Pinapatungan",
    questions: [
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Makinis",
        incorrect_answers: [
          "Magaspang"
        ]
      },
      {
        correct_answer: "Malaki",
        incorrect_answers: [
          "Maliit"
        ]
      },
      {
        correct_answer: "Nilalapagan",
        incorrect_answers: [
          "Hinihigaan"
        ]
      },
      {
        correct_answer: "Apat ang paa",
        incorrect_answers: [
          "Dalawa ang paa"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter L",
        incorrect_answers: [
          "Nag-uumpisa sa letter B"
        ]
      },
      {
        correct_answer: "Lamesa",
        incorrect_answers: [
          "Lapag",
          "Bangko"
        ]
      },
    ]
  },
{
    questionId: 100096,
    setting: "Hinahawakan",
    questions: [
      {
        correct_answer: "Malambot",
        incorrect_answers: [
          "Matigas"
        ]
      },
      {
        correct_answer: "Maliit",
        incorrect_answers: [
          "Malaki"
        ]
      },
      {
        correct_answer: "Pinipisil",
        incorrect_answers: [
          "Hindi napipisil"
        ]
      },
      {
        correct_answer: "Tuyo",
        incorrect_answers: [
          "Basa"
        ]
      },
      {
        correct_answer: "Nakakagana",
        incorrect_answers: [
          "Nakakabagot"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter D"
        ]
      },
      {
        correct_answer: "Stress Ball",
        incorrect_answers: [
          "Sapatos",
          "Duhat"
        ]
      },
    ]
  },
{
    questionId: 100097,
    setting: "Nagpapatigas",
    questions: [
      {
        correct_answer: "Malapad",
        incorrect_answers: [
          "Manipis"
        ]
      },
      {
        correct_answer: "Basa",
        incorrect_answers: [
          "Tuyo"
        ]
      },
      {
        correct_answer: "Malamig",
        incorrect_answers: [
          "Mainit"
        ]
      },
      {
        correct_answer: "Pampatigas",
        incorrect_answers: [
          "Pampalambot"
        ]
      },
      {
        correct_answer: "Ginagamitan ng kuryente",
        incorrect_answers: [
          "Ginagamitan ng baterya"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter F",
        incorrect_answers: [
          "Nag-uumpisa sa letter T"
        ]
      },
      {
        correct_answer: "Freezer",
        incorrect_answers: [
          "Fan",
          "Tolda"
        ]
      },
    ]
  },
{
    questionId: 100098,
    setting: "Lumalambot",
    questions: [
      {
        correct_answer: "Masarap",
        incorrect_answers: [
          "Masakit"
        ]
      },
      {
        correct_answer: "Kinakain",
        incorrect_answers: [
          "Iniinom"
        ]
      },
      {
        correct_answer: "Malinamnam",
        incorrect_answers: [
          "Maalat"
        ]
      },
      {
        correct_answer: "Maitim",
        incorrect_answers: [
          "Maputi"
        ]
      },
      {
        correct_answer: "Parihaba",
        incorrect_answers: [
          "Parisukat"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter T",
        incorrect_answers: [
          "Nag-uumpisa sa letter K"
        ]
      },
      {
        correct_answer: "Talong",
        incorrect_answers: [
          "Tahong",
          "Kuhol"
        ]
      },
    ]
  },
{
    questionId: 100099,
    setting: "Tumitigas",
    questions: [
      {
        correct_answer: "Maliit",
        incorrect_answers: [
          "Malaki"
        ]
      },
      {
        correct_answer: "Mataba",
        incorrect_answers: [
          "Payat"
        ]
      },
      {
        correct_answer: "Masarap sa pakiramdam",
        incorrect_answers: [
          "Masama sa pakiramdam"
        ]
      },
      {
        correct_answer: "Pampalamig",
        incorrect_answers: [
          "Pampainit"
        ]
      },
      {
        correct_answer: "Kumakatas",
        incorrect_answers: [
          "Tuyot"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter Y",
        incorrect_answers: [
          "Nag-uumpisa sa letter K"
        ]
      },
      {
        correct_answer: "Yelo",
        incorrect_answers: [
          "Yema",
          "Kamao"
        ]
      },
    ]
  },
{
    questionId: 100100,
    setting: "Pinapasok",
    questions: [
      {
        correct_answer: "Maliit",
        incorrect_answers: [
          "Malaki"
        ]
      },
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "May butas",
        incorrect_answers: [
          "Walang butas"
        ]
      },
      {
        correct_answer: "Pabilog",
        incorrect_answers: [
          "Pahaba"
        ]
      },
      {
        correct_answer: "Hindi nakakain",
        incorrect_answers: [
          "Nakakain"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter D"
        ]
      },
      {
        correct_answer: "Singsing",
        incorrect_answers: [
          "Sinturon",
          "Daliri"
        ]
      },
    ]
  },
{
    questionId: 100101,
    setting: "Kumikiliti",
    questions: [
      {
        correct_answer: "Payat",
        incorrect_answers: [
          "Mataba"
        ]
      },
      {
        correct_answer: "Nakakagigil",
        incorrect_answers: [
          "Nakakasakit"
        ]
      },
      {
        correct_answer: "Nakakatindig balahibo",
        incorrect_answers: [
          "Nakakagalit"
        ]
      },
      {
        correct_answer: "Pang linis",
        incorrect_answers: [
          "Pampadumi"
        ]
      },
      {
        correct_answer: "Pang tenga",
        incorrect_answers: [
          "Pang paa"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter C",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Cotton buds",
        incorrect_answers: [
          "Chalk",
          "Pusa"
        ]
      },
    ]
  },
  {
    questionId: 100102,
    setting: "Pinapahinog",
    questions: [
      {
        correct_answer: "Hinuhubaran",
        incorrect_answers: [
          "Dinadamitan"
        ]
      },
      {
        correct_answer: "Matamis",
        incorrect_answers: [
          "Maalat"
        ]
      },
      {
        correct_answer: "Madilaw",
        incorrect_answers: [
          "Maitim"
        ]
      },
      {
        correct_answer: "Malambot",
        incorrect_answers: [
          "Matigas"
        ]
      },
      {
        correct_answer: "Haba",
        incorrect_answers: [
          "Bilog"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Saging",
        incorrect_answers: [
          "Sigarilyas",
          "Pakwan"
        ]
      },
    ]
  },
  {
    questionId: 100103,
    setting: "Nilulunok",
    questions: [
      {
        correct_answer: "Malinamnam",
        incorrect_answers: [
          "Matabang"
        ]
      },
      {
        correct_answer: "Mapula",
        incorrect_answers: [
          "Maitim"
        ]
      },
      {
        correct_answer: "Malambot",
        incorrect_answers: [
          "Matigas"
        ]
      },
      {
        correct_answer: "Pahaba",
        incorrect_answers: [
          "Paparisukat"
        ]
      },
      {
        correct_answer: "Nakakain",
        incorrect_answers: [
          "Hindi nakakain"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter H",
        incorrect_answers: [
          "Nag-uumpisa sa letter T"
        ]
      },
      {
        correct_answer: "Hotdog",
        incorrect_answers: [
          "Hollow block",
          "Tocino"
        ]
      },
    ]
  },
  {
    questionId: 100104,
    setting: "Sinusubo",
    questions: [
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Dinidilaan",
        incorrect_answers: [
          "Hinihigop"
        ]
      },
      {
        correct_answer: "Mabango",
        incorrect_answers: [
          "Mabaho"
        ]
      },
      {
        correct_answer: "Malinamnam",
        incorrect_answers: [
          "Matabang"
        ]
      },
      {
        correct_answer: "May ulo",
        incorrect_answers: [
          "Walang ulo"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter L",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Lollipop",
        incorrect_answers: [
          "Langka",
          "Papaya"
        ]
      },
    ]
  },
  {
    questionId: 100105,
    setting: "Binabasa",
    questions: [
      {
        correct_answer: "Pinapatigas",
        incorrect_answers: [
          "Pinapalambot"
        ]
      },
      {
        correct_answer: "Binabasa",
        incorrect_answers: [
          "Tinutuyo"
        ]
      },
      {
        correct_answer: "Ipinapasok",
        incorrect_answers: [
          "Inilalabas"
        ]
      },
      {
        correct_answer: "Manipis",
        incorrect_answers: [
          "Mataba"
        ]
      },
      {
        correct_answer: "May kulay",
        incorrect_answers: [
          "Walang kulay"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Sinulid",
        incorrect_answers: [
          "Shorts",
          "Punda"
        ]
      },
    ]
  },
  {
    questionId: 100106,
    setting: "Pampasarap",
    questions: [
      {
        correct_answer: "Basa",
        incorrect_answers: [
          "Tuyo"
        ]
      },
      {
        correct_answer: "Malabnaw",
        incorrect_answers: [
          "Malapot"
        ]
      },
      {
        correct_answer: "Maalat",
        incorrect_answers: [
          "Matabang"
        ]
      },
      {
        correct_answer: "Maitim",
        incorrect_answers: [
          "Madilaw"
        ]
      },
      {
        correct_answer: "Nakakain",
        incorrect_answers: [
          "Hindi nakakain"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter T",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Toyo",
        incorrect_answers: [
          "Tuyo",
          "Pusit"
        ]
      },
    ]
  },
  {
    questionId: 100107,
    setting: "Nanggagapang",
    questions: [
      {
        correct_answer: "Mabuhok",
        incorrect_answers: [
          "Kalbo"
        ]
      },
      {
        correct_answer: "Makati",
        incorrect_answers: [
          "Hindi makati"
        ]
      },
      {
        correct_answer: "Sumusundot",
        incorrect_answers: [
          "Nangangagat"
        ]
      },
      {
        correct_answer: "Mataba",
        incorrect_answers: [
          "Payat"
        ]
      },
      {
        correct_answer: "Kumakapit",
        incorrect_answers: [
          "Bumibitaw"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter H",
        incorrect_answers: [
          "Nag-uumpisa sa letter K"
        ]
      },
      {
        correct_answer: "Higad",
        incorrect_answers: [
          "Harina",
          "Karayom"
        ]
      },
    ]
  },
  {
    questionId: 100108,
    setting: "Hinihigop",
    questions: [
      {
        correct_answer: "Bilugan",
        incorrect_answers: [
          "Haba"
        ]
      },
      {
        correct_answer: "Maitim",
        incorrect_answers: [
          "Maberde"
        ]
      },
      {
        correct_answer: "Malagkit",
        incorrect_answers: [
          "Maganit"
        ]
      },
      {
        correct_answer: "Matamis",
        incorrect_answers: [
          "Maasim"
        ]
      },
      {
        correct_answer: "Kinakain",
        incorrect_answers: [
          "Niluluwa"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter D"
        ]
      },
      {
        correct_answer: "Sago",
        incorrect_answers: [
          "Sabaw",
          "Dinuguan"
        ]
      },
    ]
  },
  {
    questionId: 100109,
    setting: "Pampagana",
    questions: [
      {
        correct_answer: "Pahaba",
        incorrect_answers: [
          "Pabilog"
        ]
      },
      {
        correct_answer: "Namumula",
        incorrect_answers: [
          "Nangingitim"
        ]
      },
      {
        correct_answer: "Pinipiga",
        incorrect_answers: [
          "Hinihimay"
        ]
      },
      {
        correct_answer: "Kumakatas",
        incorrect_answers: [
          "Tuyot"
        ]
      },
      {
        correct_answer: "Maanghang",
        incorrect_answers: [
          "Matabang"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter B"
        ]
      },
      {
        correct_answer: "Sili",
        incorrect_answers: [
          "Sampalok",
          "Bawang"
        ]
      },
    ]
  },
  {
    questionId: 100110,
    setting: "Pampainit",
    questions: [
      {
        correct_answer: "Maitim",
        incorrect_answers: [
          "Maputi"
        ]
      },
      {
        correct_answer: "Butil-butil",
        incorrect_answers: [
          "Buo-buo"
        ]
      },
      {
        correct_answer: "Mapait",
        incorrect_answers: [
          "Mapakla"
        ]
      },
      {
        correct_answer: "Nakakapaso",
        incorrect_answers: [
          "Nakakatunaw"
        ]
      },
      {
        correct_answer: "Pampagising",
        incorrect_answers: [
          "Pampalungkot"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter K",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Kape",
        incorrect_answers: [
          "Kawali",
          "Plantsa"
        ]
      },
    ]
  },
  {
    questionId: 100111,
    setting: "Panghimagas",
    questions: [
      {
        correct_answer: "Pabilog",
        incorrect_answers: [
          "Pahaba"
        ]
      },
      {
        correct_answer: "Binibiyak",
        incorrect_answers: [
          "Binubuka"
        ]
      },
      {
        correct_answer: "Hinahagod",
        incorrect_answers: [
          "Nilalamas"
        ]
      },
      {
        correct_answer: "Maputi",
        incorrect_answers: [
          "Maitim"
        ]
      },
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter B",
        incorrect_answers: [
          "Nag-uumpisa sa letter K"
        ]
      },
      {
        correct_answer: "Buko",
        incorrect_answers: [
          "Banana Que",
          "Kamote Que"
        ]
      },
    ]
  },
  {
    questionId: 100112,
    setting: "Nilalamas",
    questions: [
      {
        correct_answer: "Minamasa",
        incorrect_answers: [
          "Kinukurot"
        ]
      },
      {
        correct_answer: "Malinamnam",
        incorrect_answers: [
          "Matabang"
        ]
      },
      {
        correct_answer: "Malambot",
        incorrect_answers: [
          "Makunat"
        ]
      },
      {
        correct_answer: "Matambok",
        incorrect_answers: [
          "Manipis"
        ]
      },
      {
        correct_answer: "Mabango",
        incorrect_answers: [
          "Mabantot"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter M",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Monay",
        incorrect_answers: [
          "Mukha",
          "Paa"
        ]
      },
    ]
  },
  {
    questionId:  100113,
    setting: "Nilalabasan",
    questions: [
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Maliit ang butas",
        incorrect_answers: [
          "Malaki ang butas"
        ]
      },
      {
        correct_answer: "Nagko-kontrol",
        incorrect_answers: [
          "Hindi nagko-kontrol"
        ]
      },
      {
        correct_answer: "Maligamgam",
        incorrect_answers: [
          "Kumukulo"
        ]
      },
      {
        correct_answer: " Tumutulo",
        incorrect_answers: [
          " Natutuyot"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter G",
        incorrect_answers: [
          "Nag-uumpisa sa letter K"
        ]
      },
      {
        correct_answer: "Gripo",
        incorrect_answers: [
          "Gasul",
          "Kanal"
        ]
      },
    ]
  },
  {
    questionId:  100114,
    setting: "Sinasakyan",
    questions: [
      {
        correct_answer: "Binabayaran",
        incorrect_answers: [
          "Tinatakbuhan"
        ]
      },
      {
        correct_answer: "Inuupuan",
        incorrect_answers: [
          "Tinatayuan"
        ]
      },
      {
        correct_answer: "Binibitinan",
        incorrect_answers: [
          "Hinaharangan"
        ]
      },
      {
        correct_answer: "Inaangkasan",
        incorrect_answers: [
          "Binibitiwan"
        ]
      },
      {
        correct_answer: "Tatlo ang paa",
        incorrect_answers: [
          "Dalawa ang paa"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter T",
        incorrect_answers: [
          "Nag-uumpisa sa letter K"
        ]
      },
      {
        correct_answer: "Tricycle",
        incorrect_answers: [
          "Tiyubibo",
          "Kabayo"
        ]
      },
    ]
  },
  {
    questionId:  100115,
    setting: " Tumatalsik",
    questions: [
      {
        correct_answer: " Tulog",
        incorrect_answers: [
          "Gising"
        ]
      },
      {
        correct_answer: " Nag-iinit",
        incorrect_answers: [
          "Nanlalamig"
        ]
      },
      {
        correct_answer: "Madulas",
        incorrect_answers: [
          "Magaspang"
        ]
      },
      {
        correct_answer: "Nakakasunog",
        incorrect_answers: [
          "Nakakaputi"
        ]
      },
      {
        correct_answer: " Dilaw",
        incorrect_answers: [
          "Pula"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter M",
        incorrect_answers: [
          "Nag-uumpisa sa letter L"
        ]
      },
      {
        correct_answer: "Mantika",
        incorrect_answers: [
          "Muta",
          "Laway"
        ]
      },
    ]
  },
  {
    questionId:  100116,
    setting: "Ipinantitira",
    questions: [
      {
        correct_answer: "Nakakatakot",
        incorrect_answers: [
          "Nakakatuwa"
        ]
      },
      {
        correct_answer: "Kinakapa",
        incorrect_answers: [
          "Binabasa"
        ]
      },
      {
        correct_answer: "Kinakalabit",
        incorrect_answers: [
          "Tinutulak"
        ]
      },
      {
        correct_answer: "Pumuputok",
        incorrect_answers: [
          "Umuusok"
        ]
      },
      {
        correct_answer: "Nakakapatay",
        incorrect_answers: [
          "Nakakabuhay"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter B",
        incorrect_answers: [
          "Nag-uumpisa sa letter P"
        ]
      },
      {
        correct_answer: "Baril",
        incorrect_answers: [
          "Balisong",
          "Pana"
        ]
      },
    ]
  },
  {
    questionId:  100117,
    setting: "Hinihimas",
    questions: [
      {
        correct_answer: "Nag iinit",
        incorrect_answers: [
          "Nanlalamig"
        ]
      },
      {
        correct_answer: "Nagbibigay liwanag",
        incorrect_answers: [
          "Nagbibigay dilim"
        ]
      },
      {
        correct_answer: "Naglalangis",
        incorrect_answers: [
          "Nagtutubig"
        ]
      },
      {
        correct_answer: "Genie",
        incorrect_answers: [
          "Jenny"
        ]
      },
      {
        correct_answer: "May mitsa",
        incorrect_answers: [
          "Walang mitsa"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter L",
        incorrect_answers: [
          "Nag-uumpisa sa letter B"
        ]
      },
      {
        correct_answer: "Lampara",
        incorrect_answers: [
          "Lampin",
          "Baso"
        ]
      },
    ]
  },
  {
    questionId:  100118,
    setting: "Hinihimas",
    questions: [
      {
        correct_answer: "Manipis",
        incorrect_answers: [
          "Mataba"
        ]
      },
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Pinapasukan",
        incorrect_answers: [
          "Pinapatungan"
        ]
      },
      {
        correct_answer: "Binabantayan",
        incorrect_answers: [
          "Dinidiligan"
        ]
      },
      {
        correct_answer: "Gawa sa bakal",
        incorrect_answers: [
          "Gawa sa kahoy"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter R",
        incorrect_answers: [
          "Nag-uumpisa sa letter B"
        ]
      },
      {
        correct_answer: "Rehas",
        incorrect_answers: [
          "Relo",
          "Braso"
        ]
      },
    ]
  },
  {
    questionId:  100119,
    setting: "Nilulusutan",
    questions: [
      {
        correct_answer: "Matulis",
        incorrect_answers: [
          "Mapurol"
        ]
      },
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Masikip",
        incorrect_answers: [
          "Maluwag"
        ]
      },
      {
        correct_answer: "Manipis",
        incorrect_answers: [
          "Mataba"
        ]
      },
      {
        correct_answer: "PInapasukan",
        incorrect_answers: [
          "Dinidilaan"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter K",
        incorrect_answers: [
          "Nag-uumpisa sa letter B"
        ]
      },
      {
        correct_answer: "Karayom",
        incorrect_answers: [
          "Klasrum",
          "Bodega"
        ]
      },
    ]
  },
{
    questionId: 100120,
    setting: "Ipinapasok",
    questions: [
      {
        correct_answer: "Inilalabas",
        incorrect_answers: [
          "Itinatapon"
        ]
      },
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Pahaba",
        incorrect_answers: [
          "Pabilog"
        ]
      },
      {
        correct_answer: "Pang bukas",
        incorrect_answers: [
          "Pang sara"
        ]
      },
      {
        correct_answer: "May ngipin",
        incorrect_answers: [
          "Walang ngipin"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter L"
        ]
      },
      {
        correct_answer: "Susi",
        incorrect_answers: [
          "Sinturon",
          "Lapis"
        ]
      },
    ]
  },
{
    questionId: 100121,
    setting: "Sinisipsip",
    questions: [
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Maliit",
        incorrect_answers: [
          "Malaki"
        ]
      },
      {
        correct_answer: "Hinihigop",
        incorrect_answers: [
          "Dinidilaan"
        ]
      },
      {
        correct_answer: "Maitim",
        incorrect_answers: [
          "Maputi"
        ]
      },
      {
        correct_answer: "Malinamnam",
        incorrect_answers: [
          "Matabang"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter S",
        incorrect_answers: [
          "Nag-uumpisa sa letter T"
        ]
      },
      {
        correct_answer: "Suso",
        incorrect_answers: [
          "Sugpo",
          "Tsokolate"
        ]
      },
    ]
  },
{
    questionId: 100122,
    setting: "Binabaon",
    questions: [
      {
        correct_answer: "Matulis",
        incorrect_answers: [
          "Mapurol"
        ]
      },
      {
        correct_answer: "Matigas",
        incorrect_answers: [
          "Malambot"
        ]
      },
      {
        correct_answer: "Nakalabas ang ulo",
        incorrect_answers: [
          "Nakatago ang ulo"
        ]
      },
      {
        correct_answer: "Tumutusok",
        incorrect_answers: [
          "Kumikiliti"
        ]
      },
      {
        correct_answer: "Sinasagad",
        incorrect_answers: [
          "Binababawan"
        ]
      },
      {
        correct_answer: "Nag-uumpisa sa letter P",
        incorrect_answers: [
          "Nag-uumpisa sa letter T"
        ]
      },
      {
        correct_answer: "Pako",
        incorrect_answers: [
          "Pala",
          "Turnilyo"
        ]
      },
    ]
  },
];

export default doblekahol;
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  footer:{
    display:'flex',
    justifyContent:'space-between',
    color:'#fff',
    padding:'0 10px',
    color:"#949494",
    marginBottom: 80,
  },
  footericn:{
    cursor:'pointer',
    width: 50,
    height: 50,
    padding: 10
  },
  mainfooter: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0
  }
}));

import { useEffect, useRef, useState } from "react";
import { Row, RowState } from "../../Row";
import dictionary from "./dictionary/dictionary.json";
import { Clue, clue, describeClue, violation } from "../../clue";
import { Keyboard } from "../../Keyboard";
import targetList from "../../targets.json";
import './WordGame.css';
import "./styles.css";
import Logo from '../../assets/images/wordle.png'
import useStyles from './style';
import Coin from '../../assets/images/header-images/Coin.png';
import easy from "./targets/easy.json";
import medium from "./targets/medium.json";
import hard from "./targets/hard.json";
import doblekahol from "./targets/doblekahol.json";
import HeartOn from '../../assets/images/common-images/heart_on.png';
import HeartOff from '../../assets/images/common-images/heart_off.png';

import {
  describeSeed,
  dictionarySet,
  Difficulty,
  gameName,
  pick,
  resetRng,
  seed,
  speak,
  urlParam,
} from "./util";
import { decode, encode } from "../../base64";
import { isIOS, isMobile } from "react-device-detect";
import { coinUse, getWinEnggR2, payOneTimeFee, getWinEnggR2Sequence, completeR2Win, addToJoin, addFamer, getWinEnggR2New, completeR2Win2 } from "../../services/ApiService";
import { ids } from "../../constants";
import { useNavigate } from "react-router-dom";
import InnerPageHeader from "../../Components/InnerPageHeader";
import Postloader from "../Postloader";

enum GameState {
  Playing,
  Won,
  Lost,
}

interface GameProps {
  maxGuesses: number;
  hidden: boolean;
  difficulty: Difficulty;
  colorBlind: boolean;
  keyboardLayout: string;
  prizeImage: string;
  joinFee: number;
  newId: number;
  sourceId: number;
  title: string;
  difficultyClassId: number;
  joinRequirement: any;
  avatar: string;
  postloaderImage: string;
  lessonData: any;
  engagementId: number;
}

const targets = targetList.slice(0, targetList.indexOf("murky") + 1); // Words no rarer than this one
const minLength = 4;
const defaultLength = 5;
const maxLength = 11;
const limitLength = (n: number) =>
  n >= minLength && n <= maxLength ? n : defaultLength;

function randomTarget(targets: Array<string>, wordLength: number): string {
  const eligible = targets.filter((word) => word.length === wordLength);
  let candidate: string;
  do {
    candidate = pick(eligible);
  } while (/\*/.test(candidate));
  return candidate;
}

function getChallengeUrl(target: string): string {
  return (
    window.location.origin +
    window.location.pathname +
    "?challenge=" +
    encode(target)
  );
}

let initChallenge = "";
let challengeError = false;
try {
  initChallenge = decode(urlParam("challenge") ?? "").toLowerCase();
} catch (e) {
  console.warn(e);
  challengeError = true;
}
if (initChallenge && !dictionarySet.has(initChallenge)) {
  initChallenge = "";
  challengeError = true;
}

function parseUrlLength(): number {
  const lengthParam = urlParam("length");
  if (!lengthParam) return defaultLength;
  return limitLength(Number(lengthParam));
}

function parseUrlGameNumber(): number {
  const gameParam = urlParam("game");
  if (!gameParam) return 1;
  const gameNumber = Number(gameParam);
  return gameNumber >= 1 && gameNumber <= 1000 ? gameNumber : 1;
}

function Game(props: GameProps) {
  const [gameState, setGameState] = useState(GameState.Playing);
  const [guesses, setGuesses] = useState<string[]>([]);
  const [currentGuess, setCurrentGuess] = useState<string>("");
  const [challenge, setChallenge] = useState<string>(initChallenge);
  const [wordLength, setWordLength] = useState(
    challenge ? challenge.length : parseUrlLength()
  );
  const [gameNumber, setGameNumber] = useState(parseUrlGameNumber());
  const [target, setTarget] = useState(() => {
    resetRng();
    // Skip RNG ahead to the parsed initial game number:
    // console.log(`props.difficultyClassId=${props.difficultyClassId}`)
    // const words = props.difficultyClassId == 1 ? easy : props.difficultyClassId == 2 ? medium : hard;
    let words = easy;
    if (Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypeDobleKahol) {
      words = doblekahol;
    }
    for (let i = 1; i < gameNumber; i++) randomTarget(words, wordLength);
    return challenge || randomTarget(words, wordLength);
  });
  const [hint, setHint] = useState<string>(
    challengeError
      ? `Invalid challenge string, playing random game.`
      : `Make your first guess!`
  );
  const currentSeedParams = () =>
    `?seed=${seed}&length=${wordLength}&game=${gameNumber}`;

  const [isClicked, setIsClicked] = useState(false);
  const [showPrizes, setShowPrizes] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(0);
  const [hofWinData, setHofWinData] = useState({
    amount: 10,
    product: null
  })
  // const [hofWinData, setHofWinData] = useState({
  //   amount: 0,
  //   product: {
  //     "id": 20021,
  //     "name": "1 EGLD",
  //     "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648799030951.png"
  //   }
  // })
  // const [r2, setR2] = useState([{amount: -1},{amount: -1},{amount: -1},{amount: -1},{amount: -1},{amount: -1}]);
  const [r2, setR2] = useState({amount: -1});
  const [sequence, setSequence] = useState(1);
  const [winComparisonId, setWinComparisonId] = useState();
  const [isDone, setIsDone] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(`target=${target}`)
  // }, [])

  useEffect(() => {
    if (seed) {
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + currentSeedParams()
      );
    }
  }, [wordLength, gameNumber]);
  const tableRef = useRef<HTMLTableElement>(null);
  const startNextGame = () => {
    if (challenge) {
      // Clear the URL parameters:
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    setChallenge("");
    const newWordLength = limitLength(wordLength);
    setWordLength(newWordLength);
    // console.log(`props.difficultyClassId=${props.difficultyClassId}`)
    // const words = props.difficultyClassId == 1 ? easy : props.difficultyClassId == 2 ? medium : hard;
    const words = easy;
    setTarget(randomTarget(words, newWordLength));
    setHint("");
    setGuesses([]);
    setCurrentGuess("");
    setGameState(GameState.Playing);
    setGameNumber((x) => x + 1);
  };

  async function share(copiedHint: string, text?: string) {
    const url = seed
      ? window.location.origin + window.location.pathname + currentSeedParams()
      : getChallengeUrl(target);
    const body = url + (text ? "\n\n" + text : "");
    if (
      /android|iphone|ipad|ipod|webos/i.test(navigator.userAgent) &&
      !/firefox/i.test(navigator.userAgent)
    ) {
      try {
        await navigator.share({ text: body });
        return;
      } catch (e) {
        console.warn("navigator.share failed:", e);
      }
    }
    try {
      await navigator.clipboard.writeText(body);
      setHint(copiedHint);
      return;
    } catch (e) {
      console.warn("navigator.clipboard.writeText failed:", e);
    }
    setHint(url);
  }

  const onKey = (key: string) => {
    if (gameState !== GameState.Playing) {
      // if (key === "Enter") {
      //   startNextGame();
      // }
      return;
    }
    if (guesses.length === props.maxGuesses) return;
    if (/^[a-z]$/i.test(key)) {
      setCurrentGuess((guess) =>
        (guess + key.toLowerCase()).slice(0, wordLength)
      );
      tableRef.current?.focus();
      setHint("");
    } else if (key === "Backspace") {
      setCurrentGuess((guess) => guess.slice(0, -1));
      setHint("");
    } else if (key === "Enter") {
      if (currentGuess.length !== wordLength) {
        setHint("Too short");
        return;
      }
      let words = easy;
      if (Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypeDobleKahol) {
        words = doblekahol;
      }
      if (!words.includes(currentGuess)) {
        setHint("Not a valid word");
        return;
      }
      for (const g of guesses) {
        const c = clue(g, target);
        const feedback = violation(props.difficulty, c, currentGuess);
        if (feedback) {
          setHint(feedback);
          return;
        }
      }
      setGuesses((guesses) => guesses.concat([currentGuess]));
      setCurrentGuess((guess) => "");

      const gameOver = (verbed: string) =>
        `You ${verbed}! The answer was ${target.toUpperCase()}. (Enter to ${
          challenge ? "play a random game" : "play again"
        })`;

      if (currentGuess === target) {
        // processWin();
        fetchR2();
        uploadJoin(true);
        _addFamer();
        // setHint(gameOver("won"));
        setIsDone(true);
        setTimeout(() => {
          setGameState(GameState.Won);
        }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
      } else if (guesses.length + 1 === props.maxGuesses) {
        uploadJoin(false);
        // setHint(gameOver("lost"));
        setTimeout(() => {
          setGameState(GameState.Lost);
        }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
      } else {
        setHint("");
        speak(describeClue(clue(currentGuess, target)));
      }
    }
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        onKey(e.key);
      }
      if (e.key === "Backspace") {
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [currentGuess, gameState]);

  let letterInfo = new Map<string, Clue>();

  const prizeClick = (i: number) => {
    setShowPrizes(true);
    setSelectedPrize(i);
  }

  useEffect(() => {
    const wonData = r2;
    // console.log(`wonData=${JSON.stringify(wonData)}`)
    setHofWinData({
      amount:  wonData.amount,
      product: wonData.product
    })
  }, [r2])

  const processWin = async () => {
    // const wonData = r2[guesses.length]; 
    // setHofWinData({
    //   amount:  wonData.amount,
    //   product: wonData.product
    // })
    // try {
    //   await completeR2Win({
    //     engagementId: props.newId,
    //     win: guesses.length + 1,
    //     sequence,
    //     word: target.toUpperCase(),
    //     txnType: ids.txnTypeRondleWordWin
    // });
    // } catch (e) {
    //   console.log(e);
    // }
    try {
      await completeR2Win2({
        winComparisonId: winComparisonId,
        txnType: ids.txnTypeRondleWordWin
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (winComparisonId) {
      processWin()
    }
  }, [winComparisonId])

  const uploadJoin = async (isWon) => {
    try {
      const joinData = [
        {
          engagement_id: props.newId,
          engagement_title: props.title,
          record_time: new Date().toISOString().slice(0, -5),
          win_criteria: isWon ? 0 : 1,
          amount: 0,
          tickets: 0,
          difficulty_level: props.difficultyClassId,
          type: 1 //words - 1; topic - 2; numbers - 3; 
        },
      ];
      await addToJoin(joinData);
      // console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  }

  const tableRows = Array(props.maxGuesses)
    .fill(undefined)
    .map((_, i) => {
      const guess = [...guesses, currentGuess][i] ?? "";
      const cluedLetters = clue(guess, target);
      const lockedIn = i < guesses.length;
      if (lockedIn) {
        for (const { clue, letter } of cluedLetters) {
          if (clue === undefined) break;
          const old = letterInfo.get(letter);
          if (old === undefined || clue > old) {
            letterInfo.set(letter, clue);
          }
        }
      }
      return (
        <Row
          key={i}
          wordLength={wordLength}
          rowState={
            lockedIn
              ? RowState.LockedIn
              : i === guesses.length
              ? RowState.Editing
              : RowState.Pending
          }
          cluedLetters={cluedLetters}
          prize={props.joinRequirement[i].imageSmall}
          prizeClick={() => prizeClick(i)}
        />
      );
    });
  
  const playAgain = async () => {
    const balance = localStorage.getItem("balance") && JSON.parse(localStorage.getItem("balance"));
    if (balance.coinAmount < props.joinFee) {
      window.alert(`Not enough coins`);
    }
    else {
      if (!isClicked) {
        setIsClicked(true);
        try {
          const feeParam = {
            "engagementId": props.newId,
            "engagementTitle": props.title,
            "amount": props.joinFee,
            "pot": 1,
            "isOneTime": 0
          }
          
          await payOneTimeFee(feeParam)

          const coinParam = {
            "transactionType": ids.txnTypeTourneyFee,
            "description": `Entry fee in R: Word`,
            "amount": -props.joinFee,
            "deviceBalance": 0,
            "serverBalance": 0
          }
          await coinUse(coinParam)
        }
        catch(e) {
          console.log(e)
        }
        startNextGame();
      }
    }
  }

  const fetchR2 = async () => {
    try {
      // console.log(`6-guesses.length=${6-guesses.length}`)
      const response = await getWinEnggR2New({engagementId: props.newId, score: 6-guesses.length, word: encodeURIComponent(target)});
      //  console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setR2(response.data);
        setWinComparisonId(response.data.winComparisonId);
      }
    } catch (e) {
      console.log(`error=${e}`);
    }
  };

  const _addFamer = async () => {
    try {
      await addFamer({ engagementId: props.newId })
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   fetchR2();
  //   fetchSequence();
  // }, [])

  // const fetchSequence = async () => {
  //   try {
  //     const response = await getWinEnggR2Sequence({engagementId: props.newId});
  //     //  console.log(`response=${JSON.stringify(response)}`)
  //     if (response && response.data.sequence) {
  //       setSequence(response.data.sequence + 1);
  //     }
  //   } catch (e) {
  //     console.log(`error=${e}`);
  //   }
  // };

  const onQuitPress = () => {
    uploadJoin(false);
    setGameState(GameState.Lost);
    // setGameState(GameState.Won);
  }

  return (
    <div className="Game" style={{ display: props.hidden ? "none" : "block" }}>
      <div className="Game-options">
        <div style={{position: 'absolute', top: '95px', justifyContent: 'center', width: '100%', display: 'flex', backgroundColor: 'transparent'}}>
          {[...Array(6-guesses.length+(isDone ? 1 : 0))].map((e: any, i: number) => <img style={{width: '24px', height: '24px'}} src={HeartOn} key={i} />)}{[...Array(guesses.length-(isDone ? 1 : 0))].map((e: any, i: number) => <img style={{width: '24px', height: '24px'}} src={HeartOff} key={i} />)}
        </div>
        {/* <label htmlFor="wordLength">Letters:</label>
        <input
          type="range"
          min={minLength}
          max={maxLength}
          id="wordLength"
          disabled={
            gameState === GameState.Playing &&
            (guesses.length > 0 || currentGuess !== "" || challenge !== "")
          }
          value={wordLength}
          onChange={(e) => {
            const length = Number(e.target.value);
            resetRng();
            setGameNumber(1);
            setGameState(GameState.Playing);
            setGuesses([]);
            setCurrentGuess("");
            setTarget(randomTarget(length));
            setWordLength(length);
            setHint(`${length} letters`);
          }}
        ></input> */}
        {/* <button
          style={{ flex: "0 0 auto" }}
          disabled={gameState !== GameState.Playing || guesses.length === 0}
          onClick={() => {
            setHint(
              `The answer was ${target.toUpperCase()}. (Enter to play again)`
            );
            setGameState(GameState.Lost);
            (document.activeElement as HTMLElement)?.blur();
          }}
        >
          Give up
        </button> */}
      </div>
      <table
        className="Game-rows"
        tabIndex={0}
        aria-label="Table of guesses"
        ref={tableRef}
      >
        <tbody>{tableRows}</tbody>
      </table>
      <p style={{position: 'absolute', top: '190px', right: isIOS ? '11%' : isMobile ? '38px' : '80px', color: '#ffd700', fontSize: 12}}>Prizes</p>
      <img onClick={() => prizeClick(0)} src={props.joinRequirement[0].imageSmall} style={{position: 'absolute', top: '195px', width: '110px', height: '110px', objectFit: 'cover', 
        marginLeft: '30px', right: isIOS ? '1%' : isMobile ? '0px' : '40px', cursor: 'pointer'}} />
      <p
        role="alert"
        style={{
          userSelect: /https?:/.test(hint) ? "text" : "none",
          whiteSpace: "pre-wrap",
          color: '#e0e0e0',
          textAlign: 'center'
        }}
      >
        {hint || `\u00a0`}
      </p>
      <Keyboard
        layout={props.keyboardLayout}
        letterInfo={letterInfo}
        onKey={onKey}
      />
      {/* <div className="Game-seed-info">
        {challenge
          ? "playing a challenge game"
          : seed
          ? `${describeSeed(seed)} — length ${wordLength}, game ${gameNumber}`
          : "playing a random game"}
      </div> */}
      <p>
        {/* <button
          onClick={() => {
            share("Link copied to clipboard!");
          }}
        >
          Share a link to this game
        </button>{" "} */}
        {/* {gameState !== GameState.Playing && (
          <button
            onClick={() => {
              const emoji = props.colorBlind
                ? ["⬛", "🟦", "🟧"]
                : ["⬛", "🟨", "🟩"];
              const score = gameState === GameState.Lost ? "X" : guesses.length;
              share(
                "Result copied to clipboard!",
                `${gameName} ${score}/${props.maxGuesses}\n` +
                  guesses
                    .map((guess) =>
                      clue(guess, target)
                        .map((c) => emoji[c.clue ?? 0])
                        .join("")
                    )
                    .join("\n")
              );
            }}
          >
            Share emoji results
          </button>
        )} */}
      </p>
      {gameState === GameState.Playing && 
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} hasQuitBtn onQuitPress={onQuitPress} title='' style={{position: 'absolute'}}/>
        </div>
      }
      <Postloader gameState={gameState} hofWinData={hofWinData} guessLeft={6-guesses.length+1} correctText='CORRECT ANSWER:' 
        word={target.toUpperCase()} 
        avatarBig={props.avatar} postloaderImageBig={props.postloaderImage} 
        sourceId={props.sourceId} engagementId={props.engagementId}
        showHeart={true}
        onRepeatPress={() => {
          localStorage.setItem("autoJoin", "1");
          navigate(-1);
        }}
        lessonData={props.lessonData}
      />
      {/* {gameState === GameState.Won && (
        <div style={styles.prizeContainer}>
          <img src={props.avatar} className={classes.logo} />
          <span className={classes.correctAnswer}>CORRECT ANSWER:</span>
          <span className={classes.answer}>{target.toUpperCase()}</span>
          <span className={classes.scratchWinMuch}>You won</span>
          {hofWinData.amount > 0 && (
            <div>
              <div className={classes.coinImageText}>
                <div className={classes.coinImageTextRow}>
                  <img
                    src={Coin}
                    className={classes.coinYellowImage}
                  />
                  <h2 className={classes.coinText}>
                    {hofWinData.amount}
                  </h2>
                </div>
              </div>
              <div className={classes.availableProtectCoin}>
              </div>
              <div className={classes.scoreCounterRow}>
              </div>
            </div>
          )}
          {hofWinData.product && (
            <div>
              <img
                src={hofWinData.product.imageBig}
                alt="bengga coin img"
                className={classes.scratchWinProductImage}
              />
            </div>
          )}
          <div style={{marginTop: '-20px', justifyContent: 'center', width: '100%', display: 'flex', backgroundColor: 'transparent'}}>
            {[...Array(6-guesses.length+1)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOn} key={i} />)}{[...Array(guesses.length-1)].map((e: any, i: number) => <img style={{width: '36px', height: '36px'}} src={HeartOff} key={i} />)}
          </div>

          <div style={styles.buttonContainer}>
            <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
              BACK
            </button>
          </div>
          <div style={{position: 'absolute', top:0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
        </div>
      )}
      {gameState === GameState.Lost && (
        <div style={styles.prizeContainer}>
          <img src={props.avatar} className={classes.logo} />
          <span className={classes.correctAnswer}>CORRECT ANSWER:</span>
          <span className={classes.answer}>{target.toUpperCase()}</span>
          <span className={classes.scratchWinMuch}>Try again</span>
          <div>
            <img
              src={props.postloaderImage}
              alt="bengga coin img"
              className={classes.lostImage}
            />
          </div>

          <div style={styles.buttonContainer}>
            <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
              BACK
            </button>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
        </div>
      )} */}
      {showPrizes && (
        <div
          className="tutorialContainer"
          onClick={() => setShowPrizes(false)}
        >
          <img src={props.joinRequirement[selectedPrize].imageBig} className="tutorialImage" />
          <p className="close">X</p>
        </div>
      )}
    </div>
  );
}

export default Game;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  footerMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuIcon: {
    width: 40,
    height: 40,
    objectFit: 'contain'
    // backgroundColor: 'red',
  },
  menuLabel: {
    color: 'white',
    fontSize: 12,
    cursor: 'pointer',
    marginTop: 5,
    lineHeight: 1
  },
}));

// @flow
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import Ajv from "ajv";
import { AqMiniappSdk, Messages, Events } from "./polyfill";
import { FunTypeSelector, getFriends1 } from "./selectors/FunTypeSelector";
import { SelectorMode } from "./selectors/FunTypeSelector";
import DismissableAlert from "./components/DismissableAlert";
// import { PreviewTable } from './components/PreviewTable';
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  // Radio,
  Checkbox,
  Panel,
} from "react-bootstrap";
import { v1 as uuidv1 } from "uuid";
import Base64JS from "base64-js";
// import iphone from "./img/iphone.png";
// import nav_gradient from "./img/nav_gradient.png";
// import nav_back_white from "./img/nav_back_white.png";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";

import FamerLoader from "./gameLoader/famer";
import TourneyLoader from "./gameLoader/tourney";

import FamerResult from "./gameResult/famer";
import TourneyResult from "./gameResult/tourney";

import { closeTab } from "../../services/closeTab";
import { routes } from "../../constants";
import { ids } from "../../constants";
import UnableGame from "../../assets/images/common-images/cannot_load_game.png";

import { getBestScore } from "../../services/ApiService";

const VERSION = "1.0.19";
const ON_DATA_TIMEOUT_MS = 8000;
const INFORM_TIMEOUT_MS = 10000;
const ON_READY_DELAY_MS = 600;
// const ON_LOADED_DELAY_MS = 1000;
const MIN_DIFFICULTY_LEVEL = 1;
const MAX_DIFFICULTY_LEVEL = 5;
const DEFAULT_DIFFICULTY_LEVEL = 1;

const SET_RESULT_SCHEMA = {
  $schema: "http://json-schema.org/draft-07/schema#",
  id: "1234",
  definitions: {
    winCriteria: {
      type: "integer",
      minValue: 0,
      maxValue: 2,
    },
    score: {
      type: "object",
      required: ["value"],
      properties: {
        value: {
          type: "integer",
        },
        target: {
          type: "integer",
        },
      },
    },
  },
  type: "object",
  required: ["resultImageUrl", "winCriteria", "score"],
  properties: {
    resultImageUrl: {
      type: "string",
      format: "uri",
    },
    winCriteria: {
      $ref: "#/definitions/winCriteria",
    },
    score: {
      $ref: "#/definitions/score",
    },
  },
};

const opponent = {
  id: "cnfwwEbsEemROf7SZ5DS4Q",
  displayName: "Alexander Owens",
  avatarBig: "http://cdn1.benggaapp.com/720/F223166904807J51R2G.jpg",
  avatarSmall: "http://cdn1.benggaapp.com/160/F223166904807J51R2G.jpg",
};

const FunTypeRunner = () => {
  // const [tempTargetUrl, setTempTargetUrl] = useState(targetUrl);
  // const props = window.state;
  const props = useLocation().state;
  const navigate = useNavigate();
  if (!props) {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    navigate(routes.login);
  }
  const [targetUrl, setTargetUrl] = useState(props?.data.funType.webUrl);
  const [previewData, setPreviewData] = useState(null);
  const [joinOutputData, setJoinOutputData] = useState([]);
  const [hasTargetScore, setHasTargetScore] = useState(props?.hasTarget);
  const [isSinglePlayer, setIsSinglePlayer] = useState(true);
  const [videoVisible, setVideoVisible] = useState(false);
  const [targetScore, setTargetScore] = useState(5);
  const [winImage, setWinImage] = useState(
    "https://s3-ap-southeast-1.amazonaws.com/fma-sdk/simulator/static/media/gift.png"
  );
  const [selectorMode, setSelectorMode] = useState("none");
  const [webImageSelectorData, setWebImageSelectorData] = useState({
    key: "",
    title: "",
    data: [],
  });
  const [galleryImageSelectorData, setGalleryImageSelectorData] = useState({
    key: "",
    title: "",
  });
  const [variableContent, setVariableContent] = useState();
  const [friendSelectorData, setFriendSelectorData] = useState({
    key: "",
  });
  const generateNonce = () => {
    // Random nonce is used to make URL unique so, iFrame can be reloaded
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
  };
  const user =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));

  const [nonce, setNonce] = useState(generateNonce());
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [engagementInfoValue, setEngagementInfoValue] = useState({});
  const [hasClickedGo, setHasClickedGo] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [informDidTimeout, setInformDidTimeout] = useState(false);
  // const [tabClose, setTabClose] = useState(false);
  const [result, setResult1] = useState(null);
  console.log("***result***", result);
  // setTimeout(() => {
  // !tabClose &&
  // closeTab(props?.data.title, window, tabClose, setTabClose, navigate);
  // }, 2000);
  const [difficultyLevel, setDifficultyLevel] = useState(
    props?.hasTarget
      ? props?.joinSummary.length > 0
        ? Math.min(props?.joinSummary[0].wins + 1, MAX_DIFFICULTY_LEVEL)
        : DEFAULT_DIFFICULTY_LEVEL
      : 3
  );
  const [randomizeDifficultyLevel, setRandomizeDifficultyLevel] = useState(
    false
  );
  const [informMessage, setInformMessage] = useState("");
  const [joinSdk, setJoinSdk] = useState();
  const [hasSentOnData, setHasSentOnData] = useState(false);
  const [isSoundMuted, setIsSoundMuted] = useState(props.isSoundMuted);
  const [appActiveState, setAppActiveState] = useState("active");
  const [engagementData, setEngagementData] = useState(props?.data);
  const [engagementSource, setEngagementSource] = useState(props?.data.source);
  const [highScore, setHighScore] = useState(0);
  const [highScoreDate, setHighScoreDate] = useState();
  const [newHighScore, setNewHighScore] = useState(false);
  const [showUnableGame, setShowUnableGame] = useState(false);

  const [source, setSource] = useState({
    id: "RVO_YE8EEeikXwq8J4CZ6g",
    displayName: "Anna Smith",
    avatarBig:
      "https://getfyt.s3.amazonaws.com/users_avatar/10454/2016-09-21t193052883525-yourtrainer.com-profiles-california-santa-barbara-36205ec-lydia-kitahara-photos_resize.jpg",
    avatarSmall:
      "https://getfyt.s3.amazonaws.com/users_avatar/10454/2016-09-21t193052883525-yourtrainer.com-profiles-california-santa-barbara-36205ec-lydia-kitahara-photos_resize.jpg",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const joinIFrameRef = useRef();
  const joinSdkRef = useRef();
  const informReadyTimeoutRef = useRef();
  const informLoadedTimeoutRef = useRef();
  const consoleAreaRef = useRef();

  // let id = generateId();

  // useEffect(() => {
  //   setupSdk();
  // }, []);

  useEffect(() => {
    if (!user) {
      navigate(routes.login);
    }
  }, [appActiveState, hasClickedGo, isReady, result]);

  useEffect(() => {
    return (
      informReadyTimeoutRef.current &&
      clearTimeout(informReadyTimeoutRef.current)
    );
  }, [informReadyTimeoutRef.current]);

  useEffect(() => {
    if (result) {
      const message = `setResult() ${JSON.stringify(result, null, 2)}`;
      logFromMiniApp(message);
    }
  }, [result]);

  useEffect(async () => {
    if (engagementData.funTypeFamilyId === ids.FunTypeFamilyTourney) {
      try {
        const response = await getBestScore({
          engagementId: engagementData.newId,
        });
        if (response) {
          if (
            typeof response.data !== "undefined" &&
            typeof response.data.data !== "undefined"
          ) {
            setHighScore(response.data.data.bestScore);
            setHighScoreDate(response.data.data.lastModifiedDate);
            // setRank(response.data.data.rank);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  });

  useEffect(() => {
    //fixes
    if (isReady && hasClickedGo && appActiveState === "active") {
      logFromSimulator(
        `onAppStateChange: ${JSON.stringify(currentAppState())}`
      );
      joinSdk.sendMessageToFunType(
        Events.ON_APP_STATE_CHANGE,
        "default",
        currentAppState(),
        false
      );
    }
    if (isReady && hasClickedGo && appActiveState === "inactive") {
      logFromSimulator(
        `onAppStateChange: ${JSON.stringify(currentAppState())}`
      );
      joinSdk.sendMessageToFunType(
        Events.ON_APP_STATE_CHANGE,
        "default",
        currentAppState(),
        false
      );
    }
  }, [appActiveState]);

  useEffect(() => {
    if (isReady && hasClickedGo) {
      logFromSimulator(
        `onAppStateChange: ${JSON.stringify(currentAppState())}`
      );
      joinSdk.sendMessageToFunType(
        //fixes
        Events.ON_APP_STATE_CHANGE,
        "default",
        currentAppState(),
        false
      );
    }
  }, [isSoundMuted]);

  const interval = useRef(setTimeout(() => {}, 0));

  useEffect(() => {
    interval.current = setTimeout(() => {
      // console.log(`testing isReady=${isReady}; hasClickedGo=${hasClickedGo}`)
      if (!isReady) {
        // window.alert('Unable to load game.  Please try again at a later time.');
        // navigate(-1);
        setShowUnableGame(true);
      }
      clearTimeout(interval.current);
    }, ON_DATA_TIMEOUT_MS);
    return () => {
      clearTimeout(interval.current); // cleanup
    };
  }, [isReady]);

  // useEffect(() => {
  //   if (difficultyLevel) {
  //     completion();
  //   }
  // }, [difficultyLevel]);

  function setupSdk() {
    console.log(
      "joinIFrameRef.current.contentWindow",
      joinIFrameRef.current.contentWindow
    );
    let joinSdk = new AqMiniappSdk(
      getOrigin(targetUrl),
      joinIFrameRef.current.contentWindow
    );
    joinSdk.addMessageHandler(
      Messages.MESSAGE_SHOW_WEB_IMAGE_SELECTOR,
      showWebImageSelector()
    );
    joinSdk.addMessageHandler(
      Messages.MESSAGE_SHOW_GALLERY_IMAGE_SELECTOR,
      showGalleryImageSelector()
    );
    joinSdk.addMessageHandler(
      Messages.MESSAGE_SHOW_TITLE_INPUT,
      showTitleInput()
    );
    joinSdk.addMessageHandler(
      Messages.MESSAGE_SHOW_FRIENDS_SELECTOR,
      showFriendsSelector()
    );
    joinSdk.addMessageHandler(
      Messages.MESSAGE_SHOW_FRIENDS_SELECTOR_PROMISE,
      showFriendsSelectorPromise()
    );
    joinSdk.addMessageHandler(Messages.MESSAGE_GET_FRIENDS, getFriends1());
    joinSdk.addMessageHandler(
      Messages.MESSAGE_SHOW_PREVIEW_WITH_DATA,
      showPreviewWithData()
    );
    joinSdk.addMessageHandler(Messages.MESSAGE_PUBLISH_STATUS, publishStatus());
    joinSdk.addMessageHandler(Messages.MESSAGE_JOIN, join());
    joinSdk.addMessageHandler(Messages.MESSAGE_SET_RESULT, (param) =>
      setResult(param)
    );
    joinSdk.addMessageHandler(Messages.MESSAGE_SET_APP_DATA, setAppData());
    joinSdk.addMessageHandler(Messages.MESSAGE_INFORM_READY, () =>
      informReady()
    );
    joinSdk.addMessageHandler(Messages.MESSAGE_INFORM_LOADED, informLoaded());
    joinSdk.addMessageHandler(Messages.MESSAGE_END, (param) => end(param));
    joinSdk.addMessageHandler(
      Messages.MESSAGE_CREATE_USER_BET,
      createUserBet()
    );
    joinSdk.addMessageHandler(Messages.MESSAGE_CLAIM_USER_BET, claimUserBet());

    joinSdk.shouldHandleEvent = () => {
      return true;
    };
    joinSdk.funTypeWindow = joinIFrameRef.current.contentWindow;
    // joinSdk = joinSdk; // having some issue
    setJoinSdk(joinSdk);
  }

  const getOrigin = (url) => {
    var parser = document.createElement("a");
    parser.href = url;
    return `${parser.protocol}//${parser.host}`;
  };

  const currentSdk = () => {
    return joinSdk; // having some issue
  };

  const currentAppData = () => {
    console.log(`currentAppData.difficultyLevel=${difficultyLevel}`);
    let variableContent = JSON.parse(engagementData?.variableContent);
    if (props.userData) {
      if (typeof variableContent.targetScore === "undefined") {
        // navigate(-1);
        // window.alert("Cannot load game.  Please try again later.");
      } else {
        let targetScore = variableContent.targetScore;
        if (targetScore.length >= (props.userData.difficultyClassId - 1) * 5) {
          targetScore = targetScore.slice(
            (props.userData.difficultyClassId - 1) * 5,
            props.userData.difficultyClassId * 5
          );
          variableContent.targetScore = targetScore;
        } else {
          // navigate(-1);
          // window.alert("Cannot load game.  Please try again later.");
        }
      }
    }

    var data = {
      source: source,
      engagementSource: engagementSource,
      engagementInfo: variableContent,
      opponent,
      difficultyLevel: difficultyLevel,
      hasTargetScore: hasTargetScore,
      isSinglePlayer: isSinglePlayer,
      isSoundMuted: isSoundMuted,
    };
    return data;
  };

  const currentAppState = () => {
    return {
      state: appActiveState,
      isSoundMuted: isSoundMuted,
    };
  };

  const generateId = () => {
    let arr = new Array(16);
    uuidv1(null, arr, 0);
    return Base64JS.fromByteArray(arr)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  };

  const isEngagementInfoValid = () => {
    try {
      if (Array.isArray(engagementData?.variableContent)) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  };

  const showWebImageSelector = (param) => {
    if (!param) return;
    let { key, title, imageUrls } = param;
    if (key) {
      setWebImageSelectorData({
        key: key,
        title: title ? title : "Select an Item",
        data: imageUrls ? imageUrls : [],
      });
      setSelectorMode("webImage");
    }
  };

  const onAlertDismissed = () => {
    hideAlert();
  };

  const showAlert = (alertMessage) => {
    setIsAlertVisible(true);
    setAlertMessage(alertMessage);
  };

  const hideAlert = () => {
    setIsAlertVisible(false);
    setAlertMessage("");
  };

  const onWebImageSelected = (item) => {
    setSelectorMode("none");
    // this.setState({ selectorMode: "none" });
    currentSdk().sendMessageToFunType(
      //issue
      Messages.MESSAGE_SHOW_WEB_IMAGE_SELECTOR,
      webImageSelectorData.key,
      item,
      false
    );
  };

  const showGalleryImageSelector = (param) => {
    if (!param) return;
    let { key, title } = param;
    if (key) {
      setGalleryImageSelectorData({
        key: key,
        title: title ? title : "Select an Item",
      });
      setSelectorMode("gallery");
    }
  };

  const onGalleryImageSelected = (item) => {
    setSelectorMode("none");
    currentSdk().sendMessageToFunType(
      //issue
      Messages.MESSAGE_SHOW_GALLERY_IMAGE_SELECTOR,
      galleryImageSelectorData.key,
      item,
      false
    );
  };

  const showTitleInput = (param) => {
    setSelectorMode("title");
  };

  const onTitleInputResult = (text) => {
    setSelectorMode("none");

    currentSdk().sendMessageToFunType(
      //issue
      Messages.MESSAGE_SHOW_TITLE_INPUT,
      "default",
      text,
      false
    );
  };

  const showFriendsSelector = (param) => {
    if (!param) return;

    let { key } = param;
    if (key) {
      setFriendSelectorData({
        key: key,
        message: Messages.MESSAGE_SHOW_FRIENDS_SELECTOR,
      });
      setSelectorMode("friend");
    }
  };

  const showFriendsSelectorPromise = (param) => {
    setFriendSelectorData({
      key: "",
      message: Messages.MESSAGE_SHOW_FRIENDS_SELECTOR_PROMISE,
    });
    setSelectorMode("friend");
  };

  const onFriendsSelected = (friends) => {
    setSelectorMode("none");

    currentSdk() &&
      currentSdk().sendMessageToFunType(
        //issue
        friendSelectorData.message,
        friendSelectorData.key,
        friends,
        false
      );
  };

  const getFriends = () => {
    currentSdk() &&
      currentSdk().sendMessageToFunType(
        //issue
        Messages.MESSAGE_GET_FRIENDS,
        "default",
        getFriends(),
        false
      );
  };

  const createTableJoinData = (param, shouldTruncate = true) => {
    let tablePreviewData = [];
    for (let k in param) {
      let sanitized = param[k];
      if (sanitized) {
        sanitized = JSON.stringify(sanitized, null, 2);
      } else if (sanitized && sanitized.length > 100 && shouldTruncate) {
        sanitized = `${sanitized.substring(0, 100)}...`;
      }
      if (sanitized) {
        tablePreviewData.push({ key: k, value: sanitized });
      }
    }
    return tablePreviewData;
  };

  const showPreviewWithData = (param) => {
    // Inject current user as source
    if (!param) return;
    param.source = source;
    param.engagementSource = engagementSource;
    let id = generateId();

    setPreviewData({
      mode: "preview",
      previewData: param,
      createOutputData: createTableJoinData(param),
    });
    joinSdk.sendMessageToFunType(
      //resume
      Messages.MESSAGE_ON_DATA,
      "default",
      param,
      false
    );
  };

  const join = (param) => {
    setJoinOutputData(createTableJoinData(param, false));
    logFromMiniApp(`join() ${JSON.stringify(param, null, 2)}`);
  };

  const setResult = async (param) => {
    console.log(`result log = ${JSON.stringify(param)}`);
    setResult1(param);
    if (param.score.value > highScore) {
      setNewHighScore(true);
      setHighScore(param.score.value);
      setHighScoreDate(new Date());
    }
  };

  const end = (param) => {
    setHasEnded(true);
    logFromMiniApp(`end()`);
  };

  const setAppData = (param) => {
    logFromMiniApp(`setAppData(): ${JSON.stringify(param, null, 2)}`);
  };

  const informReady = () => {
    if (isAppLoaded) {
      console.log(`informReady() was called before informLoaded()`);
      logFromSimulator(`informReady() was called before informLoaded()`);
    } else if (isReady) {
      console.log(`informReady() was called more than once.`);
      logFromSimulator(`informReady() was called more than once.`);
    } else {
      if (informReadyTimeoutRef.current) {
        clearTimeout(informReadyTimeoutRef.current); //check this also
      }
      logFromMiniApp(`informReady()`);
      setTimeout(() => {
        // this.setState({ isReady: true });
        setIsReady(true);
        clearTimeout(interval.current);
        console.log(`isReady`);
      }, ON_READY_DELAY_MS);
    }
  };

  const informLoaded = (param) => {
    console.log("isAppLoaded", isAppLoaded);
    if (isAppLoaded) {
      logFromSimulator(`informLoaded() was called more than once.`);
    } else {
      if (informLoadedTimeoutRef.current) {
        clearTimeout(informLoadedTimeoutRef.current); //check
      }
      console.log("come in else ");

      logFromMiniApp(`informLoaded()`);
      // this.setState({ isAppLoaded: true });
      setIsAppLoaded(true);
    }
  };

  const publishStatus = (param) => {
    if (!param) return;
    logConsole(
      `publishStatus(): id = ${this.id} status = ${param.status.toString()}`
    );
  };

  // _publishStatus(param: Object) {
  //   this._logConsole(
  //     `publishStatus(): id = ${this.id} status = ${param.status.toString()}`
  //   );
  // }
  const createUserBet = (param) => {
    logFromMiniApp(`createUserBet()`);
    const response = {
      id: "pRkBcBXbEeeNCgJC_fmqfQ",
      displayName: "Cale Audie",
      avatarBig: "https://www.bma.org.uk/realdoctors/img/profile_2.jpg",
      avatarSmall: "https://www.bma.org.uk/realdoctors/img/profile_2.jpg",
    };
    currentSdk() &&
      currentSdk().sendMessageToFunType(
        //check
        Messages.MESSAGE_CREATE_USER_BET,
        "default",
        response,
        false
      );
  };

  const claimUserBet = (param) => {
    logFromMiniApp(`claimUserBet(): ${JSON.stringify(param, null, 2)}`);
    currentSdk() &&
      currentSdk().sendMessageToFunType(
        Messages.MESSAGE_CLAIM_USER_BET,
        "default",
        null,
        false
      );
  };

  const onJoinIFrameLoaded = () => {
    logFromSimulator(`Mini app loaded`);
    setTimeout(() => {
      setupSdk();
    }, 1000);
    // joinSdk.funTypeWindow = joinIFrameRef.current.contentWindow; //set ref and other stuff
    // console.log("joinSdk.funTypeWindow", joinSdk.funTypeWindow);
  };
  // $FlowFixMe

  const onMiniAppUrlChange = (e) => {
    // setTempTargetUrl(e.target.value);
  };

  // $FlowFixMe

  const onMiniAppUrlKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const onClickGoButton = () => {
    if (isEngagementInfoValid()) {
      hideAlert();
      if (!joinSdk) return;
      joinSdk.funTypeOrigin = getOrigin(
        //fix ref
        engagementData?.funType.webUrl
      );
      setTargetUrl(engagementData?.funType.webUrl);
      setNonce(generateNonce());
      setHasClickedGo(true);
      setHasEnded(false);
      setIsReady(false);
      setIsAppLoaded(false);
      setInformDidTimeout(false);
      setInformMessage("");
      setHasSentOnData(false);
      // forceUpdate();
      if (informReadyTimeoutRef.current) {
        clearTimeout(informReadyTimeoutRef.current); //fixes
      }
      if (informLoadedTimeoutRef.current) {
        clearTimeout(informLoadedTimeoutRef.current);
      }
      informLoadedTimeoutRef.current = setTimeout(() => {
        //fixes
        setInformDidTimeout(true);
        setInformMessage("informLoaded");
      }, INFORM_TIMEOUT_MS);
    } else {
      showAlert("Engagement Info is not a valid JSON Object");
    }
  };

  const completion = () => {
    //fixes
    const data = currentAppData(); //fixes
    console.log(`difficultyLevel=${difficultyLevel}`);
    logFromSimulator(`onReset(): ${JSON.stringify(data, null, 2)}`);
    joinSdk &&
      joinSdk.sendMessageToFunType(
        ///fixes
        Messages.MESSAGE_RESET,
        "default",
        data,
        false
      );
    setHasEnded(false);
  };

  const onClickResetButton = () => {
    if (randomizeDifficultyLevel) {
      // this.setState(
      //   {
      //     difficultyLevel: Math.floor(Math.random() * MAX_DIFFICULTY_LEVEL) + 1,
      //   },
      //   completion  //need to fixes
      // );
      setDifficultyLevel(Math.floor(Math.random() * MAX_DIFFICULTY_LEVEL) + 1);
      console.log(`random difficultyLevel`);
    } else {
      if (result.winCriteria == 0) {
        console.log(`increment difficultyLevel`);
        // console.log(`onReset.difficultyLevel=${difficultyLevel}`);
        const newDiffLevel = Math.min(
          difficultyLevel + 1,
          MAX_DIFFICULTY_LEVEL
        );
        console.log(
          `onReset.difficultyLevel=${difficultyLevel}; onReset.newDiffLevel=${newDiffLevel}`
        );
        setDifficultyLevel(newDiffLevel);
        let variableContent = JSON.parse(engagementData?.variableContent);
        if (props.userData) {
          if (typeof variableContent.targetScore === "undefined") {
            // navigate(-1);
            // window.alert("Cannot load game.  Please try again later.");
          } else {
            let targetScore = variableContent.targetScore;
            if (
              targetScore.length >=
              (props.userData.difficultyClassId - 1) * 5
            ) {
              targetScore = targetScore.slice(
                (props.userData.difficultyClassId - 1) * 5,
                props.userData.difficultyClassId * 5
              );
              variableContent.targetScore = targetScore;
            } else {
              // navigate(-1);
              // window.alert("Cannot load game.  Please try again later.");
            }
          }
        }
        var data = {
          source: source,
          engagementSource: engagementSource,
          engagementInfo: variableContent,
          opponent,
          difficultyLevel: newDiffLevel,
          hasTargetScore: hasTargetScore,
          isSinglePlayer: isSinglePlayer,
          isSoundMuted: isSoundMuted,
        };
        console.log(`onReset.data=${JSON.stringify(data)}`);
        logFromSimulator(`onReset(): ${JSON.stringify(data, null, 2)}`);
        if (engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight && engagementData.gameType == 3) { //hl tourney
          joinSdk &&
            joinSdk.sendMessageToFunType(
              ///fixes
              Messages.MESSAGE_RESET,
              "default",
              data,
              false
            );
        }
        else if (result.winCriteria == 0) {
          joinSdk &&
            joinSdk.sendMessageToFunType(
              Messages.MESSAGE_ON_DATA,
              "default",
              data,
              false
            );
        } else {
          joinSdk &&
            joinSdk.sendMessageToFunType(
              ///fixes
              Messages.MESSAGE_RESET,
              "default",
              data,
              false
            );
        }
        setHasEnded(false);
      } else {
        completion();
      }
    }
  };

  const onClickClearConsoleButton = () => {
    clear(consoleAreaRef.current); //need some changes ref
  };

  // _onClickClearConsoleButton() {
  //   this._clear(this.consoleArea);
  // }

  const sendVariableContent = () => {
    console.log("sendVariableContent", hasSentOnData);
    if (!hasSentOnData) {
      const data = currentAppData();
      logFromSimulator(`onData(): ${JSON.stringify(data, null, 2)}`);
      if (!joinSdk) {
        console.log("joinSdk not found", joinSdk);
        return;
      }
      joinSdk.funTypeWindow = joinIFrameRef.current.contentWindow; //fixes
      console.log("joinSdk", joinSdk);
      joinSdk.sendMessageToFunType(
        Messages.MESSAGE_ON_DATA,
        "default",
        data,
        false
      );
      setHasSentOnData(true);
      informReadyTimeoutRef.current = setTimeout(() => {
        // this.setState({ informDidTimeout: true, informMessage: "informReady" });
        setInformDidTimeout(true);
        setInformMessage("informReady");
        console.log("send informReady");
      }, INFORM_TIMEOUT_MS);
    } else {
      logFromSimulator(`onData already sent!`);
      console.log("sendVariableContent informReady", isReady);
      informReady();
    }
  };

  const onClickTriggerOnData = () => {
    sendVariableContent();
  };

  const onClickAppBecomesActiveButton = () => {
    setAppActiveState("active");
  };

  const onClickAppBecomesInactiveButton = () => {
    setAppActiveState("inactive");
  };

  const clear = (ref) => {
    const textArea = ReactDOM.findDOMNode(ref);
    textArea.value = "";
  };

  const onClickHasTargetScore = () => {
    setHasTargetScore(!hasTargetScore);
  };

  const onClickIsSinglePlayer = () => {
    setIsSinglePlayer(!isSinglePlayer);
  };

  const onClickMuteSound = () => {
    setIsSoundMuted(!isSoundMuted);
  };

  const onClickRandomizeDifficultyLevel = () => {
    setRandomizeDifficultyLevel(!randomizeDifficultyLevel);
  };

  const onClickLandscape = () => {
    setIsLandscape(!isLandscape);
  };

  // const onWinImageInputChange = () => {
  //   // this.setState({ winImage: this.winImageInput.value });
  //   setWinImage(winImageInput.value); //need fixes
  // };

  const onEngagementInfoInputChange = (e) => {
    // this.setState({ engagementInfoValue: e.target.value });
    setEngagementInfoValue(e.target.value);
  };

  const onDifficultyLevelChange = (e) => {
    var difficultyLevel1 = parseInt(e.target.value, 10);
    if (isNaN(difficultyLevel)) {
      difficultyLevel1 = DEFAULT_DIFFICULTY_LEVEL;
    } else if (difficultyLevel < MIN_DIFFICULTY_LEVEL) {
      difficultyLevel1 = MIN_DIFFICULTY_LEVEL;
    } else if (difficultyLevel > MAX_DIFFICULTY_LEVEL) {
      difficultyLevel1 = MAX_DIFFICULTY_LEVEL;
    }
    // this.setState({ difficultyLevel });
    setDifficultyLevel(difficultyLevel1);
  };

  const logFromSimulator = (text) => {
    logConsole(`simulator: ${text}`);
  };

  const logFromMiniApp = (text) => {
    logConsole(`mini-app: ${text}`);
  };

  const logConsole = (text) => {
    const message = `[${moment().format("HH:mm:ss.SSS")}] ${text}`;
    log(consoleAreaRef.current, message); //need fixes for consoleArea
  };

  const log = (ref, text) => {
    // const textArea = ReactDOM.findDOMNode(ref);
    // textArea.value += `${text}\n`;
    // textArea.scrollTop = textArea.scrollHeight;
  };

  let selector = null;
  let joinUrl = "about:blank";
  if (hasClickedGo) {
    joinUrl = `${targetUrl}?action=preview&nonce=${nonce}`;
  }
  console.log("joinUrl", joinUrl, targetUrl);
  if (selectorMode !== "none") {
    selector = (
      <FunTypeSelector
        className="selector"
        style={{
          position: "absolute",
          bottom: 0,
        }}
        mode={selectorMode}
        gallerySelector={{
          title: galleryImageSelectorData.title,
          imageRatio: 1,
          assetType: "Photos",
          onPress: () => onGalleryImageSelected(),
        }}
        webImageSelector={{
          title: webImageSelectorData.title,
          imageRatio: 320 / 578.0,
          data: webImageSelectorData.data,
          onPress: () => onWebImageSelected(),
        }}
        titleInput={{
          onEndEditing: () => onTitleInputResult(),
        }}
        // prizeSelector={{
        //   title: `SELECT GIFT FOR ${this.state.promoEditor.prizes[this.state.promoEditor.currentButtonIndex].title}`,
        //   loaders: this.props.loaders,
        //   onPress: this._onPrizeSelected.bind(this)
        // }}
        // prizeMethodSelector={{
        //   title: `SELECT METHOD FOR THIS ${this.state.promoEditor.prizes[this.state.promoEditor.currentButtonIndex].title}`,
        //   loaders: this.props.loaders,
        //   onPress: this._onMethodSelected.bind(this)
        // }}
        friendSelector={{
          onSelectFriends: () => onFriendsSelected(),
        }}
      />
    );
  }
  var iPhoneClass = "iPhone";
  var contentClass = "content contentPortrait portrait";
  var navGradientClass = "navGradient navGradientPortrait";
  var iFrameClass = "iFrame portrait";
  var loaderClass = "loader portrait";
  var versionClass = "version versionPortrait";

  if (isLandscape) {
    iPhoneClass = "iPhone iPhoneLandscape";
    contentClass = "content landscape contentLandscape";
    navGradientClass = "navGradient navGradientLandscape";
    iFrameClass = "iFrame landscape";
    loaderClass = "loader landscape";
    versionClass = "version versionLandscape";
  }

  var preloaderText = "Loading...";
  if (informDidTimeout) {
    preloaderText = `Did you forget to call ${informMessage}() ?`;
  }
  var button = null;
  if (isAppLoaded) {
    // button = <button onClick={this._onClickTriggerOnData.bind(this)}>Trigger onData</button>
    onClickTriggerOnData();
  } else {
    // button = <button onClick={this._onClickTriggerOnData.bind(this)} disabled>...</button>
  }

  var postloader = null;
  if (hasEnded) {
    // var result1 = "seResult not called";
    // if (result) {
    //   result1 = `result:\n${JSON.stringify(result, null, 2)}`;
    //   var ajv = new Ajv();
    //   var valid = ajv
    //     .addSchema(SET_RESULT_SCHEMA, "setResultSchema")
    //     .validate("setResultSchema", result);
    //   if (!valid) {
    //     result1 = `${ajv.errorsText()}\n${result1}\n`;
    //   } else {
    //     result1 = `No errors!\n${result1}\n`;
    //   }
    // }
    postloader = (
      <div className={"postLoader " + loaderClass}>
        <div>
          <span>Game Over</span>
          <p />
          <Button onClick={() => onClickResetButton()}>Reset</Button>
          <p />
          {/* <textarea 
              className='setResult'
              ref={(component) => { this.setResultTextArea = component; }}
            >{result}</textarea> */}
        </div>
      </div>
    );
  }

  useEffect(() => {
    setHasClickedGo(true);
    onClickGoButton();
  }, []);

  return (
    <div className="bg_container" style={{ position: "relative" }}>
      <div className="sandbox">
        {/* <img className={iPhoneClass} src={iphone} alt="iPhone" />         */}
        <div className={contentClass}>
          {/* {videoVisible && (
            <video
              src={engagementData.video}
              autoPlay
              loop
              muted
              alt="icon"
              className="bg_game_video"
              onClick={(e) => {
                setHasClickedGo(true);
                onClickGoButton(e);
                setVideoVisible(false);
              }}
            />
          )} */}
          <iframe
            ref={(component) => {
              joinIFrameRef.current = component; //fixes
            }}
            className="bg_game_iframe"
            src={joinUrl}
            onLoad={(e) => onJoinIFrameLoaded(e)}
          />
          <div className={navGradientClass}></div>
          {/* {selector} */}

          {!isReady &&
            hasClickedGo &&
            (engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer ||
              engagementData.funTypeFamilyId == ids.FunTypeFamilyHighlight) && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <FamerLoader
                  className={"preLoader " + loaderClass}
                  onSoundPress={(toggleSound) => {
                    setIsSoundMuted(!toggleSound);
                    joinSdk.sendMessageToFunType(
                      Events.ON_APP_STATE_CHANGE,
                      "default",
                      {
                        state: appActiveState,
                        isSoundMuted: !toggleSound,
                      },
                      false
                    );
                  }}
                >
                  <div>{preloaderText}</div>
                </FamerLoader>
              </div>
            )}

          {!isReady &&
            hasClickedGo &&
            (engagementData.funTypeFamilyId === ids.FunTypeFamilyTourney ||
              (engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight &&
                engagementData.gameType === 3)) && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <TourneyLoader
                  className={"preLoader " + loaderClass}
                  engagementData={engagementData}
                >
                  <div>{preloaderText}</div>
                </TourneyLoader>
              </div>
            )}

          {showUnableGame && (
            <div
              style={{
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                top: 0,
                left: 0,
                backgroundColor: "#000000d0",
                width: "100%",
                height: "85%",
              }}
              className="tutorialContainer"
              onClick={() => {
                setShowUnableGame(false);
                navigate(-1);
              }}
            >
              <img
                src={UnableGame}
                style={{
                  width: "320px",
                  height: "320px",
                  objectFit: "stretch",
                }}
              />
            </div>
          )}

          {/* <Button onClick={(e) => onClickGoButton(e)}>Reset</Button> */}
          {hasEnded &&
            result &&
            (engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer ||
              (engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight &&
                engagementData.gameType === 1)) && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "480px",
                  width: "100%",
                }}
              >
                {/* <h1>Result:{result.score.value}</h1> */}
                <FamerResult
                  engagementData={engagementData}
                  isWon={result.winCriteria == 0}
                  onRepeat={onClickResetButton}
                  difficultyLevel={difficultyLevel}
                />
              </div>
            )}
          {hasEnded &&
            result &&
            (engagementData.funTypeFamilyId === ids.FunTypeFamilyTourney ||
              (engagementData.funTypeFamilyId === ids.FunTypeFamilyHighlight &&
                engagementData.gameType === 3)) && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "480px",
                  width: "100%",
                }}
              >
                {/* <h1>Result:{result.score.value}</h1> */}
                <TourneyResult
                  engagementData={engagementData}
                  isWon={result.winCriteria == 0}
                  onRepeat={onClickResetButton}
                  difficultyLevel={difficultyLevel}
                  highScore={highScore}
                  highScoreDate={highScoreDate}
                  newHighScore={newHighScore}
                  score={result.score.value}
                />
              </div>
            )}
        </div>

        <Panel className="parametersBox" style={{ height: 0, width: 0 }}>
          <Checkbox
            inline
            onChange={(e) => onClickLandscape(e)}
            ref={(item) => {
              // this.isLandscapeCheckBox = item; //fixes
            }}
            checked={isLandscape ? "checked" : ""}
          >
            Landscape
          </Checkbox>
          <p />
          <ControlLabel>Mini App URL</ControlLabel>
          <Form inline>
            <FormControl
              style={{ width: 300 }}
              type="text"
              value={engagementData?.funType.webUrl}
              placeholder="Enter your mini-app URL (ex. http://localhost:8081/)"
              onChange={(e) => onMiniAppUrlChange(e)}
              onKeyDown={(e) => onMiniAppUrlKeyDown(e)}
            />{" "}
            <Button bsStyle="primary" onClick={(e) => onClickGoButton(e)}>
              Go
            </Button>{" "}
            <p />
            <DismissableAlert
              message={alertMessage}
              visible={isAlertVisible}
              onDismiss={(e) => onAlertDismissed(e)}
            />
            <ControlLabel>Events</ControlLabel>
            <p />
            <Button onClick={(e) => onClickAppBecomesActiveButton(e)}>
              App becomes active
            </Button>
            <Button onClick={(e) => onClickAppBecomesInactiveButton(e)}>
              App becomes inactive
            </Button>
            <p />
            <Checkbox
              inline
              onChange={(e) => onClickMuteSound(e)}
              checked={isSoundMuted ? "checked" : ""}
            >
              Mute Sound&nbsp;&nbsp;
            </Checkbox>
            <p />
            <ControlLabel>Mini App Data</ControlLabel>
            <p />
            <Checkbox
              inline
              onChange={(e) => onClickIsSinglePlayer(e)}
              checked={isSinglePlayer ? "checked" : ""}
            >
              Is Single Player&nbsp;&nbsp;
            </Checkbox>
            <p />
            <Checkbox
              inline
              onChange={(e) => onClickHasTargetScore(e)}
              checked={hasTargetScore ? "checked" : ""}
            >
              Has Target Score&nbsp;&nbsp;
            </Checkbox>
            <p />
            <span>
              Difficulty Level [{MIN_DIFFICULTY_LEVEL}..{MAX_DIFFICULTY_LEVEL}
              ]&nbsp;&nbsp;
            </span>
            <FormControl
              type="text"
              style={{ width: 50 }}
              value={difficultyLevel}
              placeholder="Enter difficulty level"
              onChange={(e) => onDifficultyLevelChange(e)} // onChange is required
            />
            &nbsp;&nbsp;
            <Checkbox
              inline
              onChange={(e) => onClickRandomizeDifficultyLevel(e)}
              ref={(item) => {
                // this.randomizeDifficultyLevelCheckBpx = item;
              }}
              checked={randomizeDifficultyLevel ? "checked" : ""}
            >
              Randomize on reset
            </Checkbox>
            <p />
          </Form>
          <p />
          <ControlLabel>Console</ControlLabel>{" "}
          <Button onClick={(e) => onClickClearConsoleButton(e)}>Clear</Button>
          <p />
          <FormControl
            componentClass="textarea"
            wrap="off"
            style={{
              padding: "2pt",
              height: "500px",
              fontFamily: '"Lucida Console", Monaco, monospace',
              fontSize: "8pt",
            }}
            readOnly
            ref={(item) => {
              consoleAreaRef.current = item;
            }}
          />
          <p />
          <ControlLabel>Engagement Info</ControlLabel>{" "}
          <FormControl
            componentClass="textarea"
            wrap="off"
            value={engagementInfoValue}
            style={{
              padding: "2pt",
              height: "200px",
              fontFamily: '"Lucida Console", Monaco, monospace',
              fontSize: "8pt",
            }}
            ref={(item) => {
              // this.engagementInfoArea = item;
            }}
            onChange={(e) => onEngagementInfoInputChange(e)}
          />
          <p />
        </Panel>
      </div>
    </div>
  );
};

export default FunTypeRunner;

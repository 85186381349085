import { QuestionType } from './'

const pharma: QuestionType[] = [
  {
    questionId: 100064,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "Key Msg: Effective",
        incorrect_answers: [
          "Key Msg: Competent"
        ]
      },
      {
        correct_answer: "Top Specialty: OBGYN",
        incorrect_answers: [
          "Top Specialty: Neurology"
        ]
      },
      {
        correct_answer: "365 pesos",
        incorrect_answers: [
          "39 pesos"
        ]
      },
      {
        correct_answer: "Bottle",
        incorrect_answers: [
          "Capsule"
        ]
      },
      {
        correct_answer: "Rondlex Folic",
        incorrect_answers: [
          "Rondlex Forte",
          "Rondlex F4",
          "Rondlex F16"
        ]
      },
    ]
  },
{
    questionId: 100065,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Iron Supplement",
        incorrect_answers: [
          "Vitamin D Supplement"
        ]
      },
      {
        correct_answer: "Key Msg: Safe and Well-Tolerated",
        incorrect_answers: [
          "Key Msg: Competent"
        ]
      },
      {
        correct_answer: "Effective for pregnant",
        incorrect_answers: [
          "Not Effective for pregnant"
        ]
      },
      {
        correct_answer: "Target Specialty",
        incorrect_answers: [
          "Ignored Specialty"
        ]
      },
      {
        correct_answer: "OB GYN",
        incorrect_answers: [
          "Nephrology"
        ]
      },
      {
        correct_answer: "365 pesos",
        incorrect_answers: [
          "39 pesos"
        ]
      },
      {
        correct_answer: "Rondlex Folic",
        incorrect_answers: [
          "Rondlex Forte",
          "Rondlex F4",
          "Rondlex F16"
        ]
      },
    ]
  },
{
    questionId: 100066,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Oral Supplement",
        incorrect_answers: [
          "Medicine"
        ]
      },
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "Vitamin B9",
        incorrect_answers: [
          "Vitamin A"
        ]
      },
      {
        correct_answer: "27mg Sucrosomial Iron",
        incorrect_answers: [
          "26mg Sucrosomial Iron"
        ]
      },
      {
        correct_answer: "400mcg Folic Acid",
        incorrect_answers: [
          "400mg Folic Acid"
        ]
      },
      {
        correct_answer: "Rondlex Folic",
        incorrect_answers: [
          "Rondlex Forte"
        ]
      },
    ]
  },
{
    questionId: 100067,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Rondlex Folic",
        incorrect_answers: [
          "Rondlex Forte"
        ]
      },
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "Key Msg: Well-Tolerated",
        incorrect_answers: [
          "Key Msg: Competent"
        ]
      },
      {
        correct_answer: "365 pesos",
        incorrect_answers: [
          "39 pesos"
        ]
      },
      {
        correct_answer: "Bottle",
        incorrect_answers: [
          "Capsule"
        ]
      },
      {
        correct_answer: "3 tablespoons per day",
        incorrect_answers: [
          "2 Capsules per day"
        ]
      },
    ]
  },
{
    questionId: 100068,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Rondlex Folic",
        incorrect_answers: [
          "Rondlex Forte"
        ]
      },
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "365 pesos",
        incorrect_answers: [
          "39 pesos"
        ]
      },
      {
        correct_answer: "Target Specialty",
        incorrect_answers: [
          "Ignored Specialty"
        ]
      },
      {
        correct_answer: "Mostly treats outpatiets",
        incorrect_answers: [
          "Treats patients with blood deficiency"
        ]
      },
      {
        correct_answer: "General Medicine",
        incorrect_answers: [
          "Hematology"
        ]
      },
    ]
  },
{
    questionId: 100069,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "Key Msg: Effective",
        incorrect_answers: [
          "Key Msg: Competent"
        ]
      },
      {
        correct_answer: "Top Specialty: Nephrology",
        incorrect_answers: [
          "Top Specialty: Neurology"
        ]
      },
      {
        correct_answer: "39 pesos",
        incorrect_answers: [
          "365 pesos"
        ]
      },
      {
        correct_answer: "Capsule",
        incorrect_answers: [
          "Bottle"
        ]
      },
      {
        correct_answer: "Rondlex Forte",
        incorrect_answers: [
          "Rondlex Folic"
        ]
      },
    ]
  },
{
    questionId: 100070,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Iron Supplement",
        incorrect_answers: [
          "Vitamin D Supplement"
        ]
      },
      {
        correct_answer: "Key Msg: Safe and Well-Tolerated",
        incorrect_answers: [
          "Key Msg: Competent"
        ]
      },
      {
        correct_answer: "Effective for kidney patients",
        incorrect_answers: [
          "Not Effective for kidney patients"
        ]
      },
      {
        correct_answer: "Target Specialty",
        incorrect_answers: [
          "Ignored Specialty"
        ]
      },
      {
        correct_answer: "Nephrology",
        incorrect_answers: [
          "OB GYN"
        ]
      },
      {
        correct_answer: "39 pesos",
        incorrect_answers: [
          "365 pesos"
        ]
      },
      {
        correct_answer: "Rondlex Forte",
        incorrect_answers: [
          "Rondlex Folic"
        ]
      },
    ]
  },
{
    questionId: 100071,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Oral Supplement",
        incorrect_answers: [
          "Medicine"
        ]
      },
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "Vitamin B9",
        incorrect_answers: [
          "Vitamin A"
        ]
      },
      {
        correct_answer: "15mg Sucrosomial Iron",
        incorrect_answers: [
          "27mg Sucrosomial Iron"
        ]
      },
      {
        correct_answer: "45mg Ascorbic Acid",
        incorrect_answers: [
          "400mg Ascorbic Acid"
        ]
      },
      {
        correct_answer: "Rondlex Forte",
        incorrect_answers: [
          "Rondlex Folic"
        ]
      },
    ]
  },
{
    questionId: 100072,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Rondlex Forte",
        incorrect_answers: [
          "Rondlex Folic"
        ]
      },
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "Key Msg: Well-Tolerated",
        incorrect_answers: [
          "Key Msg: Competent"
        ]
      },
      {
        correct_answer: "39 pesos",
        incorrect_answers: [
          "65 pesos"
        ]
      },
      {
        correct_answer: "Capsule",
        incorrect_answers: [
          "Bottle"
        ]
      },
      {
        correct_answer: "2 Capsules per day",
        incorrect_answers: [
          "1 Capsule per day"
        ]
      },
    ]
  },
{
    questionId: 100073,
    setting: "Rondlex",
    questions: [
      {
        correct_answer: "Rondlex Forte",
        incorrect_answers: [
          "Rondlex Folic"
        ]
      },
      {
        correct_answer: "Treatment & Prevention",
        incorrect_answers: [
          "Post-Sickness"
        ]
      },
      {
        correct_answer: "Iron Deficiency Anemia",
        incorrect_answers: [
          "Vitamin B-12 Deficiency"
        ]
      },
      {
        correct_answer: "39 pesos",
        incorrect_answers: [
          "365 pesos"
        ]
      },
      {
        correct_answer: "Target Specialty",
        incorrect_answers: [
          "Ignored Specialty"
        ]
      },
      {
        correct_answer: "Assists kidney patients",
        incorrect_answers: [
          "Assists pregnant patients"
        ]
      },
      {
        correct_answer: "Nephrology",
        incorrect_answers: [
          "OBGYN"
        ]
      },
    ]
  },
];

export default pharma;
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InnerPageHeader from "../../Components/InnerPageHeader";
import { ids, routes } from "../../constants";
import { getLessons } from "../../services/ApiService";
import useStyles from "./styles";

const Lesson = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const props = useLocation().state;
  const [data, setData] = useState([]);
  // const [data, setData] = useState([
  //   {
  //     id: 1,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1653292731848.png',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   },
  //   {
  //     id: 2,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643449215888.jpeg',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   },
  //   {
  //     id: 3,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643448321384.jpeg',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   },
  //   {
  //     id: 4,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643447114253.jpeg',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   },
  //   {
  //     id: 5,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1644636431460.jpeg',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   },
  //   {
  //     id: 6,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643449215888.jpeg',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   },
  //   {
  //     id: 7,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643448321384.jpeg',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   },
  //   {
  //     id: 8,
  //     avatarImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1643447114253.jpeg',
  //     profileImageBig: '',
  //     lessons: [],
  //     links: []
  //   }
  // ]);

  const fetchData = async () => {
    try {
      const response = await getLessons();
      if (response) {
        const data = response.data.data;
        const head = data.filter(item => item.sortOrder == 1);
        const tail = data.filter(item => item.sortOrder !== 1);
        setData([
          ...head,
          ...tail
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  return (
    <>
      <div className={classes.container}>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%', maxWidth: '480px'}}>
          <InnerPageHeader
            hasBackBtn
            goBackClick={() => {
              navigate(routes.home);
            }}
            title={Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypePharma ? 'Products' : 'Elrond Projects'}
          />
        </div>
        <div className={classes.imageContainer}>
          {data && data.length > 0 &&
            data.map((item, index) => (
              <img
                key={item.lessonId}
                src={item.source.avatarBig}
                className={classes.image}
                onClick={() => navigate(routes.lessonDetails, {state: {lessonData: item}})}
              />
            ))
          }
        </div>
      </div>
    </>
  );
};

export default Lesson;

import { useEffect, useState } from "react";
import Game from "./Game";
import { useLocation, useNavigate } from "react-router-dom";
import InnerPageHeader from "../../Components/InnerPageHeader";
import { ids, routes } from "../../constants";
import { addFamer, addToJoin, completeR2Win2, getWinEnggR2New, processRondleSteal } from "../../services/ApiService";
import './index.css';
import Postloader from "../Postloader";

enum GameState {
  Playing,
  Won,
  Lost,
}

export default () => {
  const props = useLocation().state;
  const [gameState, setGameState] = useState(GameState.Playing);
  const [hofWinData, setHofWinData] = useState({
    amount: 10,
    product: null
  });
  // const [hofWinData, setHofWinData] = useState({
  //   amount: 0,
  //   product: {
  //     "id": 20021,
  //     "name": "1 EGLD",
  //     "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648799030951.png"
  //   }
  // })
  const [r2, setR2] = useState({amount: -1});
  const [winComparisonId, setWinComparisonId] = useState();
  const [wins, setWins] = useState(props.joinSummary.length > 0 ? props.joinSummary[0].wins : 0);
  const [word, setWord] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.onpopstate = () => {
      navigate(routes.home);
    };
  }, []);

  const fetchR2 = async (guesses: number, word: string) => {
    try {
      console.log(`props.engagementData=${JSON.stringify(props.engagementData)}`)
      const response = await getWinEnggR2New({engagementId: props.engagementData.newId, score: guesses, word: encodeURIComponent(word)});
      //  console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setR2(response.data);
        setWinComparisonId(response.data.winComparisonId);
      }
    } catch (e) {
      console.log(`error=${e}`);
    }
  };

  useEffect(() => {
    const wonData = r2;
    // console.log(`wonData=${JSON.stringify(wonData)}`)
    setHofWinData({
      amount:  wonData.amount,
      product: wonData.product
    })
  }, [r2])

  const _addFamer = async () => {
    try {
      await addFamer({ engagementId: props.engagementData.newId })
    } catch (e) {
      console.log(e);
    }
  };

  const uploadJoin = async (isWon) => {
    try {
      const joinData = [
        {
          engagement_id: props.engagementData.newId,
          engagement_title: props.engagementData.title,
          record_time: new Date().toISOString().slice(0, -5),
          win_criteria: isWon ? 0 : 1,
          amount: 0,
          tickets: 0,
          difficulty_level: props.userData.difficultyClassId,
          type: 2 //words - 1; topic - 2; numbers - 3; 
        },
      ];
      await addToJoin(joinData);
      // console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  }

  const processWin = async () => {
    try {
      await completeR2Win2({
        winComparisonId: winComparisonId,
        txnType: ids.txnTypeRondleNumberWin
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (winComparisonId) {
      processWin()
    }
  }, [winComparisonId])

  const notifyWon = async (word: string, lives: number) => {
    if (props.fromNotification) {
      setHofWinData({
        amount:  50,
        product: null
      })
      try {
        await processRondleSteal({ stealId: props.stealId, winnerId: props.defenderId })
      } catch (e) {
        console.log(e);
      }
      setTimeout(() => {
        setGameState(GameState.Won);    
      }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
    }
    else {
      await fetchR2(lives, 'Win');
      setTimeout(() => {
        setGameState(GameState.Won);    
        setWins(wins + 1);
        setWord(word);
        uploadJoin(true);
        _addFamer();
      }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
    }
  }

  const notifyLost = (word: string) => {
    setGameState(GameState.Lost);
    setWord(word);
    uploadJoin(false);
  }

  useEffect(() => {
    document.body.classList.add("not-scrollable");
  
    // returned function will be called on component unmount 
    return () => {
      document.body.classList.remove("not-scrollable");
    }
  }, [])

  const slotMachine = (JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 4)[0].slotMachine.filter(k => k.winEngineeringClassId === props.userData.classId)[0]

  return (
    <div className="slotroot" style={{  overscrollBehaviorY: 'none', overflowY: 'hidden',
      backgroundImage: `url(${(JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 4)[0].background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundBlendMode: 'luminosity'}}>
      <div align="center" style={{paddingTop: -10}}>
        {/* <h1 align="center">Roulette Game</h1> */}
        {/* <hr /> */}
        <Game 
          notifyWon={(lives) => notifyWon('A Match', lives)}
          notifyLost={() => notifyLost('No Match')}
          slotStrip={slotMachine.slotStrip}
          slotScores={slotMachine.slotScores}
        />
      </div>
      <Postloader gameState={gameState} hofWinData={hofWinData} guessLeft={0} correctText='YOU GOT:' 
        word={word} 
        avatarBig={props.engagementData.source.avatarBig} postloaderImageBig={props.engagementData.postloaderImageBig}  showHeart={false}
        sourceId={props.engagementData.source.id} engagementId={props.engagementData.newId}
        onRepeatPress={() => {
          localStorage.setItem("autoJoin", "1");
          navigate(-1);
        }}
        lessonData={props.lessonData}
      />
      <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
        <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.home)} hasQuitBtn={false} title='' style={{position: 'absolute'}}/>
      </div>
    </div>
  );
};

// @flow

export const Messages = {
  MESSAGE_SHOW_TITLE_INPUT: 'showTitleInput',
  MESSAGE_SHOW_WEB_IMAGE_SELECTOR: 'showWebImageSelector',
  MESSAGE_SHOW_GALLERY_IMAGE_SELECTOR: 'showGalleryImageSelector',
  MESSAGE_SHOW_FRIENDS_SELECTOR: 'showFriendsSelector',
  MESSAGE_SHOW_FRIENDS_SELECTOR_PROMISE: 'showFriendsSelectorPromise',
  MESSAGE_SHOW_PREVIEW_WITH_DATA: 'showPreviewWithData',
  MESSAGE_INFORM_READY: 'informReady',
  MESSAGE_SET_APP_DATA: 'setAppData',
  MESSAGE_REQUEST_PREVIEW: 'requestPreview',
  MESSAGE_ON_DATA: 'onData',
  MESSAGE_GET_FRIENDS: 'getFriends',
  MESSAGE_PUBLISH: 'publish',
  MESSAGE_PUBLISH_STATUS: 'publishStatus',
  MESSAGE_RESET: 'reset',
  MESSAGE_JOIN: 'join',
  MESSAGE_SET_RESULT: 'setResult',
  MESSAGE_END: 'end',
  MESSAGE_INFORM_LOADED: 'informLoaded',
  MESSAGE_CREATE_USER_BET: 'createUserBet',
  MESSAGE_CLAIM_USER_BET: 'claimUserBet'
}

export const Events = {
  ON_DATA: 'onData',
  ON_RESET: 'reset',
  ON_APP_STATE_CHANGE: 'onAppStateChange'
}

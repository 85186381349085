import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  tutorialContainer: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  tutorialImage: {
    width: 320,
    height: 320,
    objectFit: "cover",
  },
}));
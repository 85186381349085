import React from "react";
import './index.css';
import Slot from './slot_machine.png';
import { isMobile } from 'react-device-detect';
import WinSound from './winning_slot.mp3';

function RepeatButton(props) {
  return (
    <button 
      aria-label='Play again.' 
      id='repeatButton' 
      onClick={props.onClick}>
    </button>
  );
}

function WinningSound() {
  return (
  <audio autoplay="autoplay" className="player" preload="false">
    {/* <source src="https://andyhoffman.codes/random-assets/img/slots/winning_slot.wav" /> */}
    <source src={WinSound} />
  </audio>  
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      winner: null,
      isSpinning: false
    }
    this.finishHandler = this.finishHandler.bind(this)
    this.handleClick = this.handleClick.bind(this);
  }  

  startNewGame() {
    this.setState({ winner: null, isSpinning: false });
    this.emptyArray();
  }

  handleClick() { 
    this.setState({ winner: null, isSpinning: true });
    this.emptyArray();
    this._child1.forceUpdateHandler();
    this._child2.forceUpdateHandler();
    this._child3.forceUpdateHandler();
  }

  static loser = [
    'Not quite', 
    'Hey, you lost!', 
    'Ouch! I felt that',      
    'Don\'t beat yourself up',
    'There goes the college fund',
    'I have a cat. You have a loss',
    'You\'re awesome at losing',
  ];

  static matches = [];

  finishHandler(value) {
    App.matches.push(value);  

    if (App.matches.length === 3) {
      // console.log(`App.matches=${JSON.stringify(App.matches)}`)
      const { winner } = this.state;
      const first = App.matches[0];
      let results = App.matches.every(match => match === first)
      this.setState({ winner: results });
      if (results) {
        setTimeout(() => {
          this.props.notifyWon(first);
        }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
      }
      else {
        setTimeout(() => {
          this.props.notifyLost();
        }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
      }
    }
  }

  emptyArray() {
    App.matches = [];
  }

  render() {
    const { winner, isSpinning } = this.state;
    const getLoser = () => {       
      return App.loser[Math.floor(Math.random()*App.loser.length)]
    }
    let repeatButton = null;
    let winningSound = null;

    // if (winner !== null) {
      repeatButton = <RepeatButton onClick={this.handleClick}/>
    // }
    
    if (winner) {
      winningSound = <WinningSound />
    }

    return (
      <div>
        {winningSound}
        <h2 style={{ color: 'white', position: 'absolute', textAlign: 'center', width: '100%'}}>
          <span>{winner === null ? 'Good luck…' : winner ? '🤑 Pure skill! 🤑' : getLoser()}</span>
        </h2>

        <div className={`spinner-container`} style={{top: isMobile ? '55%' : '50%'}}>
          <Spinner slotScores={this.props.slotScores} onFinish={this.finishHandler} ref={(child) => { this._child1 = child; }} timer="1000" slotStrip={this.props.slotStrip}/>
          <Spinner slotScores={this.props.slotScores} onFinish={this.finishHandler} ref={(child) => { this._child2 = child; }} timer="1800" slotStrip={this.props.slotStrip}/>
          <Spinner slotScores={this.props.slotScores} onFinish={this.finishHandler} ref={(child) => { this._child3 = child; }} timer="2800" slotStrip={this.props.slotStrip}/>
          <div className="gradient-fade"></div>
        </div>
        <img src={Slot} style={{width: '100%', height: '100%', position: 'absolute', top: '90px', left: 0}} />
        {!this.state.isSpinning &&
          <button className="playbutton2" onClick={this.handleClick}>
            SPIN
          </button>      
        }
      </div>
    );
  }
}  
  
class Spinner extends React.Component {  
  constructor(props){
    super(props);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
  };

  forceUpdateHandler(){
    this.reset();
  }; 

  reset() {
    if (this.timer) { 
      clearInterval(this.timer); 
    }  

    this.start = this.setStartPosition();

    this.setState({
      position: this.start,
      timeRemaining: this.props.timer        
    });

    this.timer = setInterval(() => {
      this.tick()
    }, 100);      
  }

  state = {
    position: 0,
    lastPosition: null
  }
  static iconHeight = 188;
  multiplier = Math.floor(Math.random()*(4-1)+1);

  start = this.setStartPosition();
  speed = Spinner.iconHeight * this.multiplier;    

  setStartPosition() {
    // return ((Math.floor((Math.random()*9))) * Spinner.iconHeight)*-1;
    return Spinner.iconHeight;
  }

  moveBackground() {
    this.setState({ 
      position: this.state.position - this.speed,
      timeRemaining: this.state.timeRemaining - 100
    })
  }

  getSymbolFromPosition() {
    let { position } = this.state;
    const totalSymbols = 6;
    const maxPosition = (Spinner.iconHeight * (totalSymbols-1)*-1);
    let moved = (this.props.timer/100) * this.multiplier
    let startPosition = this.start;
    let currentPosition = startPosition;    

    for (let i = 0; i < moved; i++) {              
      currentPosition -= Spinner.iconHeight;

      if (currentPosition < maxPosition) {
        currentPosition = 0;
      }      
    }
    
    // console.log(`maxPosition=${maxPosition}; currentPosition=${currentPosition}`)

    // this.props.onFinish(currentPosition);

    const index = 2 - Math.abs(currentPosition + Spinner.iconHeight) / Spinner.iconHeight / 2;
    // console.log(`currentPosition=${currentPosition}; index=${index}; this.props.slotScores[index]=${this.props.slotScores[index]}`)

    this.props.onFinish(this.props.slotScores[index]);
  }

  tick() {      
    if (this.state.timeRemaining <= 0) {
      clearInterval(this.timer);        
      this.getSymbolFromPosition();    

    } else {
      this.moveBackground();
    }      
  }

  componentDidMount() {
    clearInterval(this.timer);

    this.setState({
      position: this.start,
      timeRemaining: this.props.timer
    });

    // this.timer = setInterval(() => {
    //   this.tick()
    // }, 100);
  }

  render() {
    let { position, current } = this.state;   

    return (            
      <div 
        style={{backgroundPosition: '0px ' + position + 'px', background: this.props.slotStrip ? `#fff url(${this.props.slotStrip}) repeat-y` : "#fff url(https://andyhoffman.codes/random-assets/img/slots/sprite5.png) repeat-y"}}
        className={`icons`}          
      />
    )
  }
}

export default App;
import React, { useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import useStyles from './styles';
import InnerPageHeader from '../../../Components/InnerPageHeader';
import useWindowDimensions from '../../../utils/windowDimention';
import { routes } from '../../../constants';
import moment from 'moment';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const CollectionDetails = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const props = useLocation().state;
  const { height, width } = useWindowDimensions();

  const { description, imageBig, code, createDate } = props.collectionData;

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });

    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  
  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  const item = props.boostDetails;

  return (
    <div className={classes.colcontainer} >
      <div className={classes.colcontainer}>
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title={description} style={{position: 'absolute'}}/>
        </div>
        <div style={{marginTop: 30, alignItems: 'center'}}>
          <div className={classes.displayDetailImage}>
            <img className={classes.receiveImage} src={imageBig} alt='invite' />
          </div>
          <p className={classes.staticLabel}>Code: {code.toUpperCase()}</p>
          <p className={classes.claimed}>{moment(createDate).format("YYYY-MM-DD HH:mm:ss")}</p>
        </div>
        <div className={classes.divider} />
        <p className={classes.staticLabel2}>Gift will be sent to your registered wallet address</p>
        <p className={classes.staticLabel4}>within 72 hours</p>
        <p className={classes.address}>{localStorage.getItem("wefftWalletAddress")}</p> 
        <p className={classes.staticLabel3}>For help and inquiries message us at</p>
        <td onClick={()=> window.open("https://t.me/EffortEconomy", "_blank")} className={classes.link}>t.me/EffortEconomy</td>
      </div>
    </div>
  );
};

export default CollectionDetails;
import { DictionaryType } from './'

const dictionary_en: DictionaryType[] = [
  {
    questionId: 100021,
    category: 'Who is the father of Anatomy?',
    words: [
      'Vesalius'
    ]
  },
{
    questionId: 100022,
    category: 'This is the study of how the human body works?',
    words: [
      'Physiology'
    ]
  },
{
    questionId: 100023,
    category: 'What is the study of the structures of the human body?',
    words: [
      'Anatomy'
    ]
  },
{
    questionId: 100024,
    category: 'What is the largest human cell?',
    words: [
      'Spleen'
    ]
  },
{
    questionId: 100025,
    category: 'What hormone is important in milk production and lactation?',
    words: [
      'Prolactin'
    ]
  },
{
    questionId: 100026,
    category: 'DNA stands for _____ribonucleic acid.',
    words: [
      'Deoxy'
    ]
  },
{
    questionId: 100027,
    category: 'What is the study of plants?',
    words: [
      'Botany'
    ]
  },
{
    questionId: 100028,
    category: 'What is the study of mushrooms called?',
    words: [
      'Mycology'
    ]
  },
{
    questionId: 100029,
    category: 'What is the scientific name of Gumamela?',
    words: [
      'Hibiscus'
    ]
  },
{
    questionId: 100030,
    category: 'Botany is the study of ________?',
    words: [
      'Plants'
    ]
  },
{
    questionId: 100031,
    category: 'What is the plant scientist called?',
    words: [
      'Botanist'
    ]
  },
{
    questionId: 100032,
    category: 'A term commonly used to describe plant life is _______.',
    words: [
      'Flora'
    ]
  },
{
    questionId: 100033,
    category: 'What is the fastest-growing plant?',
    words: [
      'Wolffia'
    ]
  },
{
    questionId: 100034,
    category: 'Who is the modern father of Botany?',
    words: [
      'Linnaeus'
    ]
  },
{
    questionId: 100035,
    category: 'Who is the father of Zoology?',
    words: [
      'Aristotle'
    ]
  },
{
    questionId: 100036,
    category: '_________ is the branch of Science dealing with animal life.',
    words: [
      'Zoology'
    ]
  },
{
    questionId: 100037,
    category: 'What is the scientific name of bird called?',
    words: [
      'Aves'
    ]
  },
{
    questionId: 100038,
    category: 'What animal has 32 brains?',
    words: [
      'Leech'
    ]
  },
{
    questionId: 100039,
    category: 'What animal has no brain?',
    words: [
      'Sponges'
    ]
  },

{
    questionId: 100040,
    category: 'What animal has 8 hearts?',
    words: [
      'Octopus'
    ]
  },
];


export default dictionary_en
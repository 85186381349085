import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '480px',
    padding: "0 20px",
    minHeight: "100vh",
    height: "auto",
    position: "relative",
    background: "#000",
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      maxWidth: '600px'
    },
    // margin: "0 auto",
  },
  gameResultContent: {
    textAlign: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  giftBoxSmallImageBox: {
    border: '2px solid #379ea3',
    borderRadius: '100px',
    width: '70px',
    height: '70px',
    background: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px auto 0'
  },
  // giftBoxSmallImage: {
  //   width: '20px',
  //   height: '34px',
  // },
  jackpotTicketImg: {
    width: '142px',
    height: '140px',
    objectFit: 'contain',
    margin: '70px auto 0'
  },
  coinWinImg: {
    width: '180px',
    height: '245px',
    objectFit: 'contain',
    margin: '70px auto 0'
  },
  gameResultTextContent: {
    marginTop: '30px'    
  },
  gameResultLostTextContent: {
    marginTop: '10px',
  },
  gameResultWonText: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'canaroBook',
  },
  gameResultTicketTextImg: {
    margin: '10px 0 55px',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '27px',
    fontWeight: 500,
    fontFamily: 'canaroBook',
  },
  gameResultTicketLoseTextImg: {
    margin: '10px 0 35px',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '27px',
    fontWeight: 500,
    fontFamily: 'canaroBook',
  },
  gameResultCrossTicket: {
    width: '49px',
    height: '37px',
    marginRight: '10px',
    objectFit: 'contain',
    transform: 'rotate(-25deg)',
  },
  gameResultParagraphTextImg: {
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '90px',
    fontFamily: 'canaroBook',
  },
  gameResultTotalTicketTextImg: {
    position: 'relative',
    paddingLeft: '15px'
  },
  gameResultFlatTicket:{
    width: '60px',
    height: '33px',
    objectFit: 'contain'
  },
  gameResultTotalTicketText:{
    position: 'absolute',
    right: 0,
    bottom: '-5px',
    textShadow: '2px 2px 4px #ffffffba'
  },
  tapWinContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#111111',
    marginTop: 70,
    padding: '15px 20px',
    textDecoration: 'none'
  },
  tapWinContentLeftText: {
    fontSize: '15px',
    fontWeight: '500',
    marginRight: '10px',
    color: '#ffffff',
    fontFamily: 'canaroBook',
  },
  tapWinContentImg: {
    width: '65px',
    height: '80px',
    objectFit: 'contain'
  },
  tapWinContentRightText: {
    fontSize: '15px',
    fontWeight: '500',
    marginLeft: '10px',
    color: '#03abbd',
    fontFamily: 'canaroBook',
  },
  level5Image: {
    width: '100px',
    height: '100px',
  },
  level5ImageContainer: {
    // paddingTop: '30px'
    position: 'absolute',
    bottom: 120,
    left: 0, right: 0, alignItems: 'center'
  },
  loseImg: {
    width: '180px',
    height: '245px',
    // width: '405px',
    // height: '552px',
    objectFit: 'contain',
    margin: '70px auto 0'
  },
  bonus: {
    margin: '10px',
    color: '#00acb8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 400,
    fontFamily: 'canaroBook',
  },
  hostedBy: {
    fontSize: '15px',
    fontWeight: '500',
    color: '#bbb',
    marginTop: '10px',
    fontFamily: 'canaroBook',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  postloaderImg: {
    width: '182px',
    height: '182px',
    objectFit: 'contain',
    margin: '10px auto 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coinImageText: {
    marginTop: '30px'
  },
  coinImageTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px'
  },
  coinYellowImage: {
    width: '35px',
    height: '35px',
    objectFit: 'contain',
    marginRight: '10px'
  },
  coinText: {
    color: '#ffffff',
    fontSize: '42px',
    margin: 0
  },
  coinBlueImage: {
    width: '80px',
    height: '15px',
    objectFit: 'contain'
  },
  availableProtectCoin: {
    textAlign: 'center',
    margin: '50px 0'
  },
  availableProtectCoinText: {
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    margin: 0
  },
  scoreCounterContainer: {
    alignItems: 'center'
  },
  scoreCounterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '50px'
  },
  scoreCounterLeftText: {
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 500,
    marginRight: '10px'
  },
  footer:{
    display:'flex',
    justifyContent:'space-between',
    color:'#fff',
    padding:'0 10px',
    color:"#949494",
  },
  footericn:{
    cursor:'pointer',
    width: 50,
    height: 50,
    padding: 10
  },
  mainfooter: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0
  }
}));

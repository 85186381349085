import { QuestionType } from './'

const question_easy: QuestionType[] = [  
  {
    questionId: 10301,
    setting: "Lion",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Pixel art"
        ]
      },
      {
        correct_answer: "Mane",
        incorrect_answers: [
          "Hair"
        ]
      },
      {
        correct_answer: "Pride",
        incorrect_answers: [
          "Pack"
        ]
      },
      {
        correct_answer: "Cubs",
        incorrect_answers: [
          "Babies"
        ]
      },
      {
        correct_answer: "Club",
        incorrect_answers: [
          "Gang"
        ]
      },
      {
        correct_answer: "LKMEX Farming",
        incorrect_answers: [
          "LKMAX Farming"
        ]
      },
      {
        correct_answer: "Elrond Lions Club",
        incorrect_answers: [
          "Whaley",
          "Super Rare Bears"
        ]
      },
    ]
  },
  {
    questionId: 20301,
    setting: "Whale",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Abstract art"
        ]
      },
      {
        correct_answer: "Tail",
        incorrect_answers: [
          "Fin"
        ]
      },
      {
        correct_answer: "Mammal", 
        incorrect_answers: [
          "Fish" 
        ]
      },
      {
        correct_answer: "RAPT Token", 
        incorrect_answers: [
          "ZORG Token"
        ]
      },
      {
        correct_answer: "Whaley Vale",
        incorrect_answers: [
          "Christian Whale"
        ]
      },
      {
        correct_answer: "4,000 supply",
        incorrect_answers: [
          "6,000 supply"
        ]
      },
      {
        correct_answer: "Whaley",
        incorrect_answers: [
          "Elrond Lions Club",
          "MaiarPunks"
        ]
      },
    ]
  },

{
    questionId: 210301,
    setting: "Effort",
    questions: [
      {
        correct_answer: "Web 3",
        incorrect_answers: [
          "Web 2"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks"
        ]
      },
      {
        correct_answer: "App",
        incorrect_answers: [
          "Protocol"
        ]
      },
      {
        correct_answer: "P2E",
        incorrect_answers: [
          "E2E"
        ]
      },
      {
        correct_answer: "Exert",
        incorrect_answers: [
          "Lax"
        ]
      },
      {
        correct_answer: "Economy",
        incorrect_answers: [
          "Community"
        ]
      },
      {
        correct_answer: "Effort Economy",
        incorrect_answers: [
          "Elrond Network",
          "Mr. Ghost"
        ]
      },
    ]
  },
  {
    questionId: 310301,
    setting: "Elrond",
    questions: [
      {
        correct_answer: "Network",
        incorrect_answers: [
          "System"
        ]
      },
      {
        correct_answer: "EGLD",
        incorrect_answers: [
          "BNB"
        ]
      },
      {
        correct_answer: "Internet Scale Blockchain",
        incorrect_answers: [
          "Wide Scale Blockchain"
        ]
      },
      {
        correct_answer: "Lord of the Rings",
        incorrect_answers: [
          "Narnia"
        ]
      },
      {
        correct_answer: "Beniamin Mincu",
        incorrect_answers: [
          "Whaley Vale"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko"
        ]
      },
      {
        correct_answer: "Elrond Network",
        incorrect_answers: [
          "Effort Economy",
          "Web 3"
        ]
      },
    ]
  },
{
    questionId: 30301,
    setting: "Bear",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Landscape art"
        ]
      },
      {
        correct_answer: "Rare", 
        incorrect_answers: [
          "Uncommon" 
        ]
      },
      {
        correct_answer: "Syringe",
        incorrect_answers: [
          "Pill" 
        ]
      },
      {
        correct_answer: "RARE Token", 
        incorrect_answers: [
          "SRB Token" 
        ]
      },
      {
        correct_answer: "Abomination", 
        incorrect_answers: [
          "Monstrosity" 
        ]
      },
      {
        correct_answer: "10,000 supply", 
        incorrect_answers: [
          "8,000 supply" 
        ]
      },
      {
        correct_answer: "Super Rare Bears",
        incorrect_answers: [
          "Elrond Lions Club",
          "Whaley"
        ]
      },
    ]
  },
{
    questionId: 410301,
    setting: "Crypto",
    questions: [
      {
        correct_answer: "Gas",
        incorrect_answers: [
          "Tax"
        ]
      },
      {
        correct_answer: "Web",
        incorrect_answers: [
          "Internet"
        ]
      },
      {
        correct_answer: "Non Fungible Token",
        incorrect_answers: [
          "Non Funky Token"
        ]
      },
      {
        correct_answer: "Version 3",
        incorrect_answers: [
          "Version 2"
        ]
      },
      {
        correct_answer: "Blockchain",
        incorrect_answers: [
          "Web Ecosystem"
        ]
      },
      {
        correct_answer: "Smart Contract",
        incorrect_answers: [
          "Smart Program"
        ]
      },
      {
        correct_answer: "Web 3",
        incorrect_answers: [
          "Effort Economy",
          "Elrond Network"
        ]
      },
    ]
  },
{
    questionId: 40301,
    setting: "Punks",
    questions: [
      {
        correct_answer: "Pixel art",
        incorrect_answers: [
          "3D art"
        ]
      },
      {
        correct_answer: "The Chaldur",
        incorrect_answers: [
          "Dr. Gaban"
        ]
      },
      {
        correct_answer: "TOKIEN Token", 
        incorrect_answers: [
          "ZORG Token" 
        ]
      },
      {
        correct_answer: "Wizard",
        incorrect_answers: [
          "Witch"
        ]
      },
      {
        correct_answer: "Gen One",
        incorrect_answers: [
          "Gen Z"
        ]
      },
      {
        correct_answer: "Maiar",
        incorrect_answers: [
          "Orc"
        ]
      },
      {
        correct_answer: "MaiarPunks",
        incorrect_answers: [
          "Elva Forest",
          "Mr. Ghost"
        ]
      },
    ]
  },
{
    questionId: 90301,
    setting: "Bros",
    questions: [
      {
        correct_answer: "Creepy",
        incorrect_answers: [
          "Peacful"
        ]
      },
      {
        correct_answer: "Yellow Eyes",
        incorrect_answers: [
          "Blue Eyes"
        ]
      },
      {
        correct_answer: "Igor",
        incorrect_answers: [
          "Ivan"
        ]
      },
      {
        correct_answer: "House",
        incorrect_answers: [
          "Castle"
        ]
      },
      {
        correct_answer: "Bat",
        incorrect_answers: [
          "Bird"
        ]
      },
      {
        correct_answer: "Italy",
        incorrect_answers: [
          "Transylvania"
        ]
      },
      {
        correct_answer: "Maka-Bros",
        incorrect_answers: [
          "Evoluzion",
          "Super Rare Bears"
        ]
      },
    ]
  },
{
    questionId: 80301,
    setting: "Orc",
    questions: [
      {
        correct_answer: "Pixel art",
        incorrect_answers: [
          "Abstract art"
        ]
      },
      {
        correct_answer: "Big teeth",
        incorrect_answers: [
          "Small teeth"
        ]
      },
      {
        correct_answer: "Weapons",
        incorrect_answers: [
          "Flowers"
        ]
      },
      {
        correct_answer: "ZORG Token",
        incorrect_answers: [
          "RARE Token"
        ]
      },
      {
        correct_answer: "Field background",
        incorrect_answers: [
          "Plain background"
        ]
      },
      {
        correct_answer: "Punks",
        incorrect_answers: [
          "Delinquent"
        ]
      },
      {
        correct_answer: "OrcPunks",
        incorrect_answers: [
          "Mr. Ghost",
          "Maka-Bros"
        ]
      },
    ]
  },
{
    questionId: 100301,
    setting: "Eheadz",
    questions: [
      {
        correct_answer: "Dr. Gaban",
        incorrect_answers: [
          "Dr. Elrond"
        ]
      },
      {
        correct_answer: "EME",
        incorrect_answers: [
          "AMA"
        ]
      },
      {
        correct_answer: "Mutation",
        incorrect_answers: [
          "Evolution"
        ]
      },
      {
        correct_answer: "Element",
        incorrect_answers: [
          "Compound"
        ]
      },
      {
        correct_answer: "Chemicals",
        incorrect_answers: [
          "Energy Drink"
        ]
      },
      {
        correct_answer: "Laboratory",
        incorrect_answers: [
          "Junkyard"
        ]
      },
      {
        correct_answer: "Elrond Mutant Eheadz",
        incorrect_answers: [
          "Whaley",
          "Elva Forest"
        ]
      },
    ]
  },
{
    questionId: 70301,
    setting: "Ghost",
    questions: [
      {
        correct_answer: "Gokai Labs",
        incorrect_answers: [
          "Mutant Labs"
        ]
      },
      {
        correct_answer: "Elven app",
        incorrect_answers: [
          "Orc app"
        ]
      },
      {
        correct_answer: "Paranormal",
        incorrect_answers: [
          "Normal"
        ]
      },
      {
        correct_answer: "Dead",
        incorrect_answers: [
          "Alive"
        ]
      },
      {
        correct_answer: "White",
        incorrect_answers: [
          "Black"
        ]
      },
      {
        correct_answer: "Mr.",
        incorrect_answers: [
          "Mrs."
        ]
      },
      {
        correct_answer: "Mr. Ghost",
        incorrect_answers: [
          "Elva Forest",
          "Maka-Bros"
        ]
      },
    ]
  },
{
    questionId: 50301,
    setting: "Forest",
    questions: [
     {
        correct_answer: "Elf",
        incorrect_answers: [
          "Orc"
        ]
      },
      {
        correct_answer: "Fantasy",
        incorrect_answers: [
          "Science Fiction"
        ]
      },
      {
        correct_answer: "Odyssey Collection",
        incorrect_answers: [
          "Genesis Collection"
        ]
      },
      {
        correct_answer: "Online to Offline business",
        incorrect_answers: [
          "Brand to brand business"
        ]
      },
      {
        correct_answer: "Aesthetic Creations",
        incorrect_answers: [
          "Musical Creations"
        ]
      },
      {
        correct_answer: "Elva",
        incorrect_answers: [
          "Elvis"
        ]
      },
      {
        correct_answer: "Elva Forest",
        incorrect_answers: [
          "OrcPunks",
          "Evoluzion"
        ]
      },
    ]
  },
{
    questionId: 60301,
    setting: "Evolve",
    questions: [
      {
        correct_answer: "3D art",
        incorrect_answers: [
          "Pixel art"
        ]
      },
      {
        correct_answer: "Genes Laboratory",
        incorrect_answers: [
          "Gokai Labs"
        ]
      },
      {
        correct_answer: "Primates",
        incorrect_answers: [
          "Felines"
        ]
      },
      {
        correct_answer: "Humans",
        incorrect_answers: [
          "Baboons"
        ]
      },
      {
        correct_answer: "Cyborg",
        incorrect_answers: [
          "Robot"
        ]
      },
      {
        correct_answer: "Evolzionists",
        incorrect_answers: [
          "Extortionists"
        ]
      },
      {
        correct_answer: "Evoluzion",
        incorrect_answers: [
          "Elrond Mutant Headz",
          "Mr. Ghost"
        ]
      },
    ]
  },
{
    questionId: 10302,
    setting: "Elrond Lions Club",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Pixel art"
        ]
      },
      {
        correct_answer: "Mane",
        incorrect_answers: [
          "Hair"
        ]
      },
      {
        correct_answer: "Pride",
        incorrect_answers: [
          "Pack"
        ]
      },
      {
        correct_answer: "Cubs",
        incorrect_answers: [
          "Babies"
        ]
      },
      {
        correct_answer: "1st gen",
        incorrect_answers: [
          "2nd gen"
        ]
      },
      {
        correct_answer: "Breeding",
        incorrect_answers: [
          "Aborting"
        ]
      },
      {
        correct_answer: "Lioness",
        incorrect_answers: [
          "Mountain Lion",
          "Sea Lion"
        ]
      },
    ]
  },
{
    questionId: 20302,
    setting: "Whaley",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Abstract art"
        ]
      },
      {
        correct_answer: "Tail",
        incorrect_answers: [
          "Fin"
        ]
      },
      {
        correct_answer: "Mammal",
        incorrect_answers: [
          "Fish"
        ]
      },
      {
        correct_answer: "RAPT Token",
        incorrect_answers: [
          "ZORG Token"
        ]
      },
      {
        correct_answer: "4,000 supply",
        incorrect_answers: [
          "6,000 supply"
        ]
      },
      {
        correct_answer: "Founder",
        incorrect_answers: [
          "Manager"
        ]
      },
      {
        correct_answer: "Whaley Vale",
        incorrect_answers: [
          "Christian Whale",
          "Beniamin Mincu"
        ]
      },
    ]
  },
{
    questionId: 210302,
    setting: "Effort Economy",
    questions: [
      {
        correct_answer: "Web 3",
        incorrect_answers: [
          "Web 2"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks"
        ]
      },
      {
        correct_answer: "App",
        incorrect_answers: [
          "Protocol"
        ]
      },
      {
        correct_answer: "Play",
        incorrect_answers: [
          "Work"
        ]
      },
      {
        correct_answer: "Exert",
        incorrect_answers: [
          "Lax"
        ]
      },
      {
        correct_answer: "Economy",
        incorrect_answers: [
          "Community"
        ]
      },
      {
        correct_answer: "P2E",
        incorrect_answers: [
          "W2E",
          "G2E"
        ]
      },
    ]
  },
{
    questionId: 310302,
    setting: "Elrond Network",
    questions: [
      {
        correct_answer: "Network",
        incorrect_answers: [
          "System"
        ]
      },
      {
        correct_answer: "EGLD",
        incorrect_answers: [
          "BNB"
        ]
      },
      {
        correct_answer: "Dr. Elrond",
        incorrect_answers: [
          "Alexander43"
        ]
      },
      {
        correct_answer: "Lord of the Rings",
        incorrect_answers: [
          "Narnia"
        ]
      },
      {
        correct_answer: "Beniamin Mincu",
        incorrect_answers: [
          "Whaley Vale"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko"
        ]
      },
      {
        correct_answer: "Internet Scale Blockchain",
        incorrect_answers: [
          "Wide Scale Blockchain",
          "Massive Scale Blockchain"
        ]
      },
    ]
  },
{
    questionId: 30302,
    setting: "Super Rare Bears",
    questions: [
      {
        correct_answer: "Cartoon art",
        incorrect_answers: [
          "Landscape art"
        ]
      },
      {
        correct_answer: "Rare",
        incorrect_answers: [
          "Uncommon"
        ]
      },
      {
        correct_answer: "Syringe",
        incorrect_answers: [
          "Pill"
        ]
      },
      {
        correct_answer: "RARE Token",
        incorrect_answers: [
          "SRB Token"
        ]
      },
      {
        correct_answer: "Abominator",
        incorrect_answers: [
          "Transformer"
        ]
      },
      {
        correct_answer: "10,000 supply",
        incorrect_answers: [
          "8,000 supply"
        ]
      },
      {
        correct_answer: "Abomination",
        incorrect_answers: [
          "Monstrosity",
          "Obscenity"
        ]
      },
    ]
  },
{
    questionId: 410302,
    setting: "Web 3",
    questions: [
      {
        correct_answer: "Gas",
        incorrect_answers: [
          "Tax"
        ]
      },
      {
        correct_answer: "Web",
        incorrect_answers: [
          "Internet"
        ]
      },
      {
        correct_answer: "Non Fungible Token",
        incorrect_answers: [
          "Non Funky Token"
        ]
      },
      {
        correct_answer: "Version 3",
        incorrect_answers: [
          "Version 2"
        ]
      },
      {
        correct_answer: "Blockchain",
        incorrect_answers: [
          "Web Ecosystem"
        ]
      },
      {
        correct_answer: "Smart Contract",
        incorrect_answers: [
          "Smart Program"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks",
          "Forex"
        ]
      },
    ]
  },
{
    questionId: 40302,
    setting: "MaiarPunks",
    questions: [
      {
        correct_answer: "Pixel art",
        incorrect_answers: [
          "3D art"
        ]
      },
      {
        correct_answer: "The Chaldur",
        incorrect_answers: [
          "Dr. Gaban"
        ]
      },
      {
        correct_answer: "TOKIEN Token",
        incorrect_answers: [
          "ZORG Token"
        ]
      },
      {
        correct_answer: "Wizard",
        incorrect_answers: [
          "Witch"
        ]
      },
      {
        correct_answer: "Gen One",
        incorrect_answers: [
          "Gen Z"
        ]
      },
      {
        correct_answer: "Maiar",
        incorrect_answers: [
          "Orc"
        ]
      },
      {
        correct_answer: "Andurin",
        incorrect_answers: [
          "Rohan",
          "Mordor"
        ]
      },
    ]
  },
{
    questionId: 90302,
    setting: "Maka-Bros",
    questions: [
      {
        correct_answer: "Creepy",
        incorrect_answers: [
          "Peaceful"
        ]
      },
      {
        correct_answer: "House",
        incorrect_answers: [
          "Castle"
        ]
      },
      {
        correct_answer: "Hall",
        incorrect_answers: [
          "Corridor"
        ]
      },
      {
        correct_answer: "Human",
        incorrect_answers: [
          "Animal"
        ]
      },
      {
        correct_answer: "Yellow Eyes",
        incorrect_answers: [
          "Blue Eyes"
        ]
      },
      {
        correct_answer: "Female",
        incorrect_answers: [
          "Male"
        ]
      },
      {
        correct_answer: "Wanda",
        incorrect_answers: [
          "Wonka",
          "Wilma"
        ]
      },
    ]
  },
{
    questionId: 80302,
    setting: "OrcPunks",
    questions: [
      {
        correct_answer: "Green skin",
        incorrect_answers: [
          "Blue skin"
        ]
      },
      {
        correct_answer: "Big teeth",
        incorrect_answers: [
          "Small teeth"
        ]
      },
      {
        correct_answer: "Weapons",
        incorrect_answers: [
          "Flowers"
        ]
      },
      {
        correct_answer: "ZORG Token",
        incorrect_answers: [
          "RARE Token"
        ]
      },
      {
        correct_answer: "Field background",
        incorrect_answers: [
          "Plain background"
        ]
      },
      {
        correct_answer: "Punks",
        incorrect_answers: [
          "Delinquent"
        ]
      },
      {
        correct_answer: "Pixel art",
        incorrect_answers: [
          "Abstract art",
          "Landscape art"
        ]
      },
    ]
  },
{
    questionId: 100302,
    setting: "Elrond Mutant Eheadz",
    questions: [
      {
        correct_answer: "Laboratory",
        incorrect_answers: [
          "Junkyard"
        ]
      },
      {
        correct_answer: "Element",
        incorrect_answers: [
          "Compound"
        ]
      },
      {
        correct_answer: "Chemicals",
        incorrect_answers: [
          "Energy Drink"
        ]
      },
      {
        correct_answer: "Mutation",
        incorrect_answers: [
          "Evolution"
        ]
      },
      {
        correct_answer: "EME",
        incorrect_answers: [
          "AMA"
        ]
      },
      {
        correct_answer: "Doctor",
        incorrect_answers: [
          "Scientist"
        ]
      },
      {
        correct_answer: "Dr. Gaban",
        incorrect_answers: [
          "Dr. Elrond",
          "Dr. No"
        ]
      },
    ]
  },
{
    questionId: 70302,
    setting: "Mr. Ghost",
    questions: [
      {
        correct_answer: "Governance token",
        incorrect_answers: [
          "Native token"
        ]
      },
      {
        correct_answer: "Elven app",
        incorrect_answers: [
          "Orc app"
        ]
      },
      {
        correct_answer: "Paranormal",
        incorrect_answers: [
          "Normal"
        ]
      },
      {
        correct_answer: "Dead",
        incorrect_answers: [
          "Alive"
        ]
      },
      {
        correct_answer: "White",
        incorrect_answers: [
          "Black"
        ]
      },
      {
        correct_answer: "Laboratory",
        incorrect_answers: [
          "Junkyard"
        ]
      },
      {
        correct_answer: "Gokai Labs",
        incorrect_answers: [
          "Mutant Labs",
          "Genes Laboratory"
        ]
      },
    ]
  },
{
    questionId: 50302,
    setting: "Elva Forest",
    questions: [
      {
        correct_answer: "Forest",
        incorrect_answers: [
          "Mountains"
        ]
      },
      {
        correct_answer: "Fantasy",
        incorrect_answers: [
          "Science Fiction"
        ]
      },
      {
        correct_answer: "Odyssey Collection",
        incorrect_answers: [
          "Genesis Collection"
        ]
      },
      {
        correct_answer: "Online to Offline business",
        incorrect_answers: [
          "Brand to Brand business"
        ]
      },
      {
        correct_answer: "Aesthetic Creations",
        incorrect_answers: [
          "Musical Creations"
        ]
      },
      {
        correct_answer: "Elva",
        incorrect_answers: [
          "Elvis"
        ]
      },
      {
        correct_answer: "Elf",
        incorrect_answers: [
          "Orc",
          "Dwarf"
        ]
      },
    ]
  },
{
    questionId: 60302,
    setting: "Evoluzion",
    questions: [
      {
        correct_answer: "Evolve",
        incorrect_answers: [
          "Mutate"
        ]
      },
      {
        correct_answer: "Genes Laboratory",
        incorrect_answers: [
          "Gokai Labs"
        ]
      },
      {
        correct_answer: "Primates",
        incorrect_answers: [
          "Felines"
        ]
      },
      {
        correct_answer: "Humans",
        incorrect_answers: [
          "Baboons"
        ]
      },
      {
        correct_answer: "Cyborg",
        incorrect_answers: [
          "Robot"
        ]
      },
      {
        correct_answer: "Evolzionists",
        incorrect_answers: [
          "Extortionists"
        ]
      },
      {
        correct_answer: "3D art",
        incorrect_answers: [
          "Pixel art",
          "Abstract art"
        ]
      },
    ]
  },
{
    questionId: 210303,
    setting: "Effort Economy",
    questions: [
      {
        correct_answer: "Elrond",
        incorrect_answers: [
          "Ethereum"
        ]
      },
      {
        correct_answer: "Effort-to-Earn",
        incorrect_answers: [
          "Play-to-Earn"
        ]
      },
      {
        correct_answer: "Earning",
        incorrect_answers: [
          "Saving"
        ]
      },
      {
        correct_answer: "Many apps",
        incorrect_answers: [
          "One app"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "Uniswap"
        ]
      },
      {
        correct_answer: "Exclusive",
        incorrect_answers: [
          "Inclusive"
        ]
      },
      {
        correct_answer: "$EFFORT",
        incorrect_answers: [
          "$EFT",
          "$EFRT"
        ]
      },
    ]
  },
{
    questionId: 210304,
    setting: "Effort Economy",
    questions: [
      {
        correct_answer: "Entertainment",
        incorrect_answers: [
          "Remittance"
        ]
      },
      {
        correct_answer: "Many apps",
        incorrect_answers: [
          "One app"
        ]
      },
      {
        correct_answer: "Effort-to-Earn",
        incorrect_answers: [
          "Play-to-Earn"
        ]
      },
      {
        correct_answer: "100,000,000",
        incorrect_answers: [
          "1,000,000,000"
        ]
      },
      {
        correct_answer: "Internet Scale Adoption",
        incorrect_answers: [
          "Limited Scale Adoption"
        ]
      },
      {
        correct_answer: "Effort",
        incorrect_answers: [
          "Exert"
        ]
      },
      {
        correct_answer: "$EFFORT",
        incorrect_answers: [
          "$EFT",
          "$EFRT"
        ]
      },
    ]
  },
{
    questionId: 310303,
    setting: "Elrond Network",
    questions: [
      {
        correct_answer: "Romania",
        incorrect_answers: [
          "America"
        ]
      },
      {
        correct_answer: "EGLD",
        incorrect_answers: [
          "ELRD"
        ]
      },
      {
        correct_answer: "Influencer",
        incorrect_answers: [
          "Validator"
        ]
      },
      {
        correct_answer: "Maiar Wallet",
        incorrect_answers: [
          "Metamask"
        ]
      },
      {
        correct_answer: "Proof of Stake",
        incorrect_answers: [
          "Proof of work"
        ]
      },
      {
        correct_answer: "Layer 1",
        incorrect_answers: [
          "Layer 2"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko",
          "Gate.io"
        ]
      },
    ]
  },
{
    questionId: 310304,
    setting: "Elrond Network",
    questions: [
      {
        correct_answer: "Lord of the Rings",
        incorrect_answers: [
          "Narnia"
        ]
      },
      {
        correct_answer: "Internet scale",
        incorrect_answers: [
          "Limited scale"
        ]
      },
      {
        correct_answer: "Network",
        incorrect_answers: [
          "Fund"
        ]
      },
      {
        correct_answer: "Sharding",
        incorrect_answers: [
          "Mapping"
        ]
      },
      {
        correct_answer: "15,000 per second",
        incorrect_answers: [
          "1500 per second"
        ]
      },
      {
        correct_answer: "31,000,000",
        incorrect_answers: [
          "21,000,000"
        ]
      },
      {
        correct_answer: "Maiar Exchange",
        incorrect_answers: [
          "CoinGecko",
          "Gate.io"
        ]
      },
    ]
  },
{
    questionId: 410303,
    setting: "Web 3",
    questions: [
      {
        correct_answer: "Ownership",
        incorrect_answers: [
          "Interactive"
        ]
      },
      {
        correct_answer: "Decentralized",
        incorrect_answers: [
          "Centralized"
        ]
      },
      {
        correct_answer: "Fungible/NFT",
        incorrect_answers: [
          "Fiat currency"
        ]
      },
      {
        correct_answer: "Immutable",
        incorrect_answers: [
          "Revocable"
        ]
      },
      {
        correct_answer: "Global",
        incorrect_answers: [
          "Regional"
        ]
      },
      {
        correct_answer: "Gas",
        incorrect_answers: [
          "Tax"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks",
          "Forex"
        ]
      },
    ]
  },
{
    questionId: 410304,
    setting: "Web 3",
    questions: [
      {
        correct_answer: "Digital tokens",
        incorrect_answers: [
          "Digital vouchers"
        ]
      },
      {
        correct_answer: "Transaction history",
        incorrect_answers: [
          "Viewing history"
        ]
      },
      {
        correct_answer: "Smart contract",
        incorrect_answers: [
          "Submitted field"
        ]
      },
      {
        correct_answer: "Network of validators",
        incorrect_answers: [
          "Network of voters"
        ]
      },
      {
        correct_answer: "Transaction hash",
        incorrect_answers: [
          "Digital receipt"
        ]
      },
      {
        correct_answer: "Covered by network costs",
        incorrect_answers: [
          "Covered by server costs"
        ]
      },
      {
        correct_answer: "Crypto",
        incorrect_answers: [
          "Stocks",
          "Forex"
        ]
      },
    ]
  }
];

export default question_easy;
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  homeContainer: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    backgroundColor: 'green'
  },
  homeContentLandscape: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    transform: 'rotate(270deg)'
  },
  sliderIndicatorContainer: {
    position: 'absolute',
    top: '50%',
    left: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sliderIndicator: {
    border: '2px solid white',
    width: 7,
    height: 7,
    border: '1px solid white',
    borderRadius: '50%',
    marginBottom: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
  },
  textFormat: {
    color: '#ffffff',
    fontSize: '13px',
    margin: 0,
    marginBottom: '0px',
    maxWidth: '300px',
    minWidth: '300px',
    backgroundColor: 'transparent',
    paddingRight: 20,
    textAlign: 'left'
  },
  notifContainer: {
    height: '120px',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  profileImage: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    cursor: 'pointer',
    marginLeft: 20, 
    marginRight: 20
  },
  actionButton: {
    color: "white",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: 1,
    border: "1px solid #28BBB1",
    backgroundColor: "transparent",
    width: 100,
    height: 30,
    textAlign: "center",
    borderRadius: 30,
    paddingTop: 0,
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: 'CanaroMedium',
    marginLeft: 10,
  },
  actionContainer: {
    backgroundColor: "transparent",
    height: 25,
    width: '100%',
    position: 'relative',
    bottom: 0,
    right: 20,
    marginTop: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

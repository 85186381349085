import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    width: "480px",
    padding: "0 20px",
    minHeight: "100vh",
    height: "auto",
    position: "relative",
    background: "#000",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
    margin: "0 auto",
  },
  giftBoxContent: {
    background:
      "linear-gradient(184deg, rgba(73,253,245,1) 0%, rgba(39,165,164,1) 0%, rgba(199,86,159,1) 100%)",
    borderRadius: "20px",
    padding: "3px",
    marginTop: "50px",
    width: "80%",
    left: '10%',
    position: "relative",
  },
  giftBoxSmallImageBox: {
    border: "3px solid #379ea3",
    borderRadius: "100px",
    width: "70px",
    height: "70px",
    background: "#fdfefe",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "-35px",
    transform: "translateX(-50%)",
    objectFit: 'contain'
  },
  giftBoxSmallImage: {
    width: 64,
    height: 64,
    borderRadius: '50%'
  },
  giftBoxImageVideoContent: {
    background: "#000000",
    padding: "35px 15px",
    borderRadius: "18px",
    overflow: "hidden",
    justifyContent: 'center'
  },
  giftBoxImage: {
    width: "100%",
    minHeight: "548px",
    maxHeight: "548px",
    objectFit: "contain",
    '@media (max-width: 480px)': {
      minHeight: "440px",
      maxHeight: "440px"
    },
    '@media (max-width: 400px)': {
      minHeight: "331px",
      maxHeight: "331px"
    }
  },
  customProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "22px",
    overflow: "hidden",
    background: "#e9ecef",
    borderRadius: "8px",
    position: "relative",
    border: "3px solid #c7569f",
    marginTop: "35px",
  },
  customProgressBar: {
    display: "flex",
    height: "100%",
    background: "#c7569f",
    position: "absolute",
    left: 0,
    top: 0,
    transition: "width 3s ease",
  },
  customProgressText: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#0000000",
    position: "relative",
  },
}));

import "./App.css";
import React, { useRef } from "react";
import { useEffect, useState, useMemo } from 'react';
import { Questionnaire } from './components';

import Timer from "./components/Timer";
import Change from "./components/Change";
import useStyles from './style';

import Start from "./components/Start";
import Timesup from "./components/Timesup";
import DoubleTime from "./components/DoubleTime";
import { useLocation, useNavigate } from "react-router-dom";
import InnerPageHeader from "../../Components/InnerPageHeader";
import questions_easy from './questions/easy';
import pharma from './questions/pharma';
import { addFamer, addToJoin, completeR2Win2, getWinEnggR2New } from "../../services/ApiService";
import { ids, routes } from "../../constants";
import { isIOS, isMobile } from "react-device-detect";
import Coin from '../../assets/images/header-images/Coin.png';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Postloader from "../Postloader";
import MaxJoinTopic from '../../assets/images/common-images/max_joins_topic.png';

const apiUrl = "https://opentdb.com/api.php?amount=100";

const myName = "Tomer Vardi";
let currentYear = new Date().getFullYear();

enum GameState {
  Playing,
  Won,
  Lost,
}

function App() {
  const classes = useStyles();
  const props = useLocation().state;

  //tracking if the user registered or not => if not -> showing welcome screen
  const [userName, setUserName] = useState(null);


  //tracking question number, current array and currIndex of question 
  //from fatched questions's array
  const [questions, setQuestions] = useState([]);
  const [currIndex, setCurrIndex] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(1);


  //tracking current earn and game status => when gameOver = true -> game is end,
  // when time is out => the player need to press btn to continue
  const [earn, setEarn] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [timeOut, setTimeOut] = useState(false);
  const [timer, setTimer] = useState(30);
  const [done, setDone] = useState(false);

  const [gameState, setGameState] = useState(GameState.Playing);
  const [hofWinData, setHofWinData] = useState({
    amount: 10,
    product: null
  });
  // const [hofWinData, setHofWinData] = useState({
  //   amount: 0,
  //   product: {
  //     "id": 20021,
  //     "name": "1 EGLD",
  //     "imageBig": "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1648799030951.png"
  //   }
  // })
  const [r2, setR2] = useState({amount: -1});
  const [winComparisonId, setWinComparisonId] = useState();
  const [wins, setWins] = useState(props.joinSummary.length > 0 ? props.joinSummary[0].wins : 0);
  const [showMaxJoin, setShowMaxJoin] = useState(false);

  const navigate = useNavigate();

  // Timer and Life_Lines
  const [doubleTimeUsed, setDoubleTimeUsed] = useState(false);
  const [changeUsed, setChangeUsed] = useState(false);

  // const [fiftyFifty, setFiftyFifty] = useState(false);

  //Questions's earn values
  const moneyPyramid = useMemo(
    () =>
      [
        { id: 1, amount: 100 },
        { id: 2, amount: 200 },
        { id: 3, amount: 300 },
        { id: 4, amount: 500 },
        { id: 5, amount: 1000 },
        { id: 6, amount: 2000 },
        { id: 7, amount: 4000 },
        { id: 8, amount: 8000 },
        { id: 9, amount: 16000 },
        { id: 10, amount: 32000 },

      ].reverse(),
    []
  );

  const [value, setValue] = useState(Math.random())

  //Fetching the questions's API, Creating current question array and mix it
  useEffect(() => {
    // fetch(apiUrl)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     //mapping fetched data and creating current question&answers array
    //     const questions = data.results.map((question) =>
    //     ({
    //       ...question,
    //       answers: [
    //         question.correct_answer,
    //         ...question.incorrect_answers,
    //       ].sort(() => Math.random() - 0.5),
    //     }))
    //     setQuestions(questions);
    //   });
    let set = questions_easy;
    if (Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypePharma) {
      set = pharma;
    }

    const joinRequirement = (JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 4)[0];
    const set1 = (props.userData.classId === ids.classA || props.userData.classId === ids.classB) ? joinRequirement.set1H : joinRequirement.set1E
    const set2 = (props.userData.classId === ids.classA || props.userData.classId === ids.classB) ? joinRequirement.set2H : joinRequirement.set2E
    const set3 = (props.userData.classId === ids.classA || props.userData.classId === ids.classB) ? joinRequirement.set3H : joinRequirement.set3E

    const questions = set.map((question) => ({
      ...question,
      answers: [
        question.correct_answer,
        ...question.incorrect_answers,
      ].sort(() => Math.random() - 0.5)
    }))

    // console.log(`wins=${wins}; set1=${JSON.stringify(set1)}; set2=${JSON.stringify(set2)}; set3=${JSON.stringify(set3)};`)
    if (typeof wins !== 'undefined' && typeof set1 !== 'undefined'
      && typeof set2 !== 'undefined' && typeof set3 !== 'undefined') 
    {
      if (wins > 0) {
        if (wins >= 8) {
          const data = questions.filter((i) => set3.some((a) => a === i.questionId))
          if (data.length > 0) {
            setQuestions([data.sort(() => Math.random() - 0.5)[0]]);
          }
          else {
            setQuestions([questions.sort(() => Math.random() - 0.5)[0]]);
          }
        }
        else if (wins >= 4) {
          const data = questions.filter((i) => set2.some((a) => a === i.questionId))
          if (data.length > 0) {
            setQuestions([data.sort(() => Math.random() - 0.5)[0]]);
          }
          else {
            setQuestions([questions.sort(() => Math.random() - 0.5)[0]]);
          }
        }
        else {
          const data = questions.filter((i) => set1.some((a) => a === i.questionId))
          if (data.length > 0) {
            setQuestions([data.sort(() => Math.random() - 0.5)[0]]);
          }
          else {
            setQuestions([questions.sort(() => Math.random() - 0.5)[0]]);
          }
        }
      }
      else {
        const data = questions.filter((i) => set1.some((a) => a === i.questionId))
        if (data.length > 0) {
          setQuestions([data.sort(() => Math.random() - 0.5)[0]]);
        }
        else {
          setQuestions([questions.sort(() => Math.random() - 0.5)[0]]);
        }
      }
    }
    else {
      setQuestions([questions.sort(() => Math.random() - 0.5)[0]]);
    }    
    // console.log(`engagementId=${props.engagementData.newId}`)
  }, [value]);

  useEffect(() => {
    if (timeOut) {
      // console.log(`timeout useeffect`)
      setGameState(GameState.Lost)
      localStorage.setItem("timeout", "1")
      uploadJoin(false);
    }
  }, [timeOut])

  //handling answer: showing the correct answer & update the earn
  const handleAnswer = async (answer) => {
    // console.log(`gameState=${gameState}; timeout=${timeOut}; done=${done}; ls=${localStorage.getItem("timeout")}`)
    //check for the answer
    if (answer === questions[currIndex].correct_answer && localStorage.getItem("timeout") === "0") {
      //updating earn
      //need to decide how to end the game and update the earn calc
      // setEarn(moneyPyramid[10 - questionNumber].amount + earn);
      await fetchR2(1, questions[currIndex].correct_answer);
      setWins(wins + 1);
      setGameState(GameState.Won);    
      uploadJoin(true);
    }
    else {
      setGameState(GameState.Lost);    
      uploadJoin(false);
    }
    // handleNextQuestion(true);

  };

  //lunching next question and stop showing corrrect answer
  const handleNextQuestion = (changeQuestion) => {
    if (questionNumber === 1) {
      setGameOver(true);
    }
    if (changeQuestion) {
      setQuestionNumber(questionNumber + 1);
    }
    //show another question
    setCurrIndex(currIndex + 1);
  }

useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  const fetchR2 = async (guesses: number, word: string) => {
    try {
      const response = await getWinEnggR2New({engagementId: props.engagementData.newId, score: guesses, word: encodeURIComponent(word)});
      //  console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setR2(response.data);
        setWinComparisonId(response.data.winComparisonId);
      }
    } catch (e) {
      console.log(`error=${e}`);
    }
  };

  useEffect(() => {
    const wonData = r2;
    // console.log(`wonData=${JSON.stringify(wonData)}`)
    setHofWinData({
      amount:  wonData.amount,
      product: wonData.product
    })
  }, [r2])

  const _addFamer = async () => {
    try {
      await addFamer({ engagementId: props.engagementData.newId })
    } catch (e) {
      console.log(e);
    }
  };

  const uploadJoin = async (isWon) => {
    try {
      const joinData = [
        {
          engagement_id: props.engagementData.newId,
          engagement_title: props.engagementData.title,
          record_time: new Date().toISOString().slice(0, -5),
          win_criteria: isWon ? 0 : 1,
          amount: 0,
          tickets: 0,
          difficulty_level: props.userData.difficultyClassId,
          type: 2 //words - 1; topic - 2; numbers - 3; 
        },
      ];
      await addToJoin(joinData);
      // console.log("uploadjoin.response", response);
    } catch (e) {
      console.log(e);
    }
  }

  const processWin = async () => {
    // console.log(`r2=${JSON.stringify(r2)}, guesses=${guesses}`)
    // const wonData = r2[guesses]; 
    
    // try {
    //   await completeR2Win({
    //     engagementId: props.engagementData.newId,
    //     win: guesses + 1, //+1 cause 0-based array
    //     sequence,
    //     word: word,
    //     txnType: ids.txnTypeRondleTopicWin
    // });
    // } catch (e) {
    //   console.log(e);
    // }
    // console.log(`processWin=${winComparisonId}`)
    try {
      await completeR2Win2({
        winComparisonId: winComparisonId,
        txnType: ids.txnTypeRondleTopicWin
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (winComparisonId) {
      processWin()
    }
  }, [winComparisonId])

  const onRepeatPress = () => {
    if (wins >= ids.MaxWinsTopic) {
      setShowMaxJoin(true);
    }
    else {
      setGameState(GameState.Playing);
      setValue(Math.random());
      setDone(false);
      setTimeOut(false);
      localStorage.setItem("timeout", "0")
    }
  }

  const gameRef = useRef();

  useEffect(() => {
    document.body.classList.add("not-scrollable");
  
    // returned function will be called on component unmount 
    return () => {
      document.body.classList.remove("not-scrollable");
    }
  }, [])

  //rendering screens and 
  return ( //!userName ?
    // (

    //   <div className="startScreen">
    //     <header>
    //       <h1>Welcome to <span className="big">QUIZ TIME</span><br />an online quiz app</h1>
    //     </header>


    //     <Start setUsername={setUserName} />
    //     <footer>
    //       <p className="copyRight">Ⓒ Copyright, {myName} {currentYear}. </p></footer>
    //   </div>
    // ) : (
      //checking if data already fetched => if not -> showing loading message
      questions.length > 0 ? (

        // Main app div
        <div className="app vh-100" >

          <>

            {/* Main (Left) container: Top & Bottom containers, and if game is over => Over container  */}
            <div className="main" style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${(JSON.parse(props.engagementData.joinRequirement) || []).filter(j => j.type == 4)[0].background})`, objectFit: 'contain'}}>
              {/* {gameOver ? (

                <h1>Game over! <br /> <span className="big">{userName}</span> earn: ★ {earn} in total !</h1>

              ) : timeOut ? (


                //sadely i have an unfixed problem:
                //when player's time is up he needs to click the btn to continue the game
                //right now when he does, the state of the useState const - timeOut
                //change only by the 2nd click, what lead to another click and skiping a question
                //i read that it's because of some problem in react changing state 
                //and because Because setState() is an asynchronous function but wouldn't found a solution
                //so at this moment the player needs to click 2 times and he loses another question.

                <Timesup
                  userName={userName}
                  setTimeOut={setTimeOut}
                  timeOut={timeOut}
                  setGameOver={setGameOver}
                  questionNumber={questionNumber}
                  handleNextQuestion={handleNextQuestion}
                />
              ) : ( */}
              {(
                <>

                  {/* Top container: Question#, Timer, LifeLines & QuitGame button  */}
                  <div className="top">

                    <div className="timer" style={{top: isMobile ? '40px' : '60px'}}>

                      {/* <Timer
                        setTimeOut={setTimeOut}
                        questionNumber={questionNumber}
                        timer={timer}
                        setTimer={setTimer}
                        changeUsed={changeUsed}
                      /> */}

                      <CountdownCircleTimer
                        key={value}
                        isPlaying={!done}
                        duration={20}
                        trailColor='#ff894b'
                        colors='#007135'
                        size={80}
                        strokeWidth={10}
                        onComplete={() => setTimeOut(true)}
                      >
                        {({ remainingTime }) => remainingTime}
                      </CountdownCircleTimer>

                    </div>

                    {/* <div className="change">

                      <Change
                        changeUsed={changeUsed}
                        setChangeUsed={setChangeUsed}
                        handleNextQuestion={handleNextQuestion}
                      />

                    </div>

                    <div className="double">

                      <DoubleTime
                        doubleTimeUsed={doubleTimeUsed}
                        setDoubleTimeUsed={setDoubleTimeUsed}
                        timer={timer}
                        setTimer={setTimer}
                      />

                    </div> */}

                  </div>

                  {/* Bottom container: Questions & Answers container */}
                  <div className="bottom" >

                    <Questionnaire
                      data={questions[currIndex]}
                      handleAnswer={(answer) => {
                        if (gameState === GameState.Playing) {
                          // console.log(`here`)
                          handleAnswer(answer)
                        }
                      }}
                      // setTimeOut={setTimeout}
                      done={() => setDone(true)}
                      ref={gameRef}
                    />

                  </div>

                </>
              )}

            </div>

            {/* Pyramid (Right container): game progress & question's value */}
            {/* <div className="pyramid col-3">

              <div className="moneyList vh-100">
                {moneyPyramid.map((m, idx) => (
                  <div
                    className={
                      questionNumber === m.id
                        ? "moneyListItem active row"
                        : "moneyListItem row"
                    }
                    key={idx}
                  >

                   

                    <div className="moneyListItemNumber col-3 d-flex align-items-center">
                      {m.id}
                    </div>

                    <div className="moneyListItemAmount col-9 d-flex align-items-center">
                      ★ {m.amount}
                    </div>

                  </div>



                ))}

              </div>

            </div> */}

          </>
          {!showMaxJoin && <Postloader gameState={gameState} hofWinData={hofWinData} guessLeft={0} correctText='CORRECT ANSWER:' 
            word={questions[currIndex].correct_answer.toUpperCase()} 
            avatarBig={props.engagementData.source.avatarBig} postloaderImageBig={props.engagementData.postloaderImageBig} 
            sourceId={props.engagementData.source.id} engagementId={props.engagementData.newId}
            showHeart={false} onRepeatPress={onRepeatPress} lessonData={props.lessonData} />
          }
          {/* {gameState === GameState.Won && (
            <div style={styles.prizeContainer}>
              <img src={props.engagementData.source.avatarBig} className={classes.logo} />
              <span className={classes.correctAnswer}>CORRECT ANSWER:</span>
              <span className={classes.answer}>{questions[currIndex].correct_answer.toUpperCase()}</span>
              <span className={classes.scratchWinMuch}>You won</span>
              {hofWinData.amount > 0 && (
                <div>
                  <div className={classes.coinImageText}>
                    <div className={classes.coinImageTextRow}>
                      <img
                        src={Coin}
                        className={classes.coinYellowImage}
                      />
                      <h2 className={classes.coinText}>
                        {hofWinData.amount}
                      </h2>
                    </div>
                  </div>
                  <div className={classes.availableProtectCoin}>
                  </div>
                  <div className={classes.scoreCounterRow}>
                  </div>
                </div>
              )}
              {hofWinData.product && (
                <div>
                  <img
                    src={hofWinData.product.imageBig}
                    alt="bengga coin img"
                    className={classes.scratchWinProductImage}
                  />
                </div>
              )}
              
              <div style={styles.buttonContainer}>
                <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
                  BACK
                </button>
              </div>
              <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
                <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
              </div>
            </div>
          )}
          {(gameState === GameState.Lost || (timeOut && gameState === GameState.Playing) ) && (
            <div style={styles.prizeContainer}>
              <img src={props.engagementData.source.avatarBig} className={classes.logo} />
              <span className={classes.correctAnswer}>CORRECT ANSWER:</span>
              <span className={classes.answer}>{questions[currIndex].correct_answer.toUpperCase()}</span>
              <span className={classes.scratchWinMuch}>Try again</span>
              <div>
                <img
                  src={props.engagementData.postloaderImageBig}
                  alt="bengga coin img"
                  className={classes.lostImage}
                />
              </div>

              <div style={styles.buttonContainer}>
                <button style={styles.buttonBuyContainer} onClick={() => navigate(-1)}>
                  BACK
                </button>
              </div>
              <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
                <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
              </div>
            </div>
          )} */}
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%', maxWidth: '480px'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title='' style={{position: 'absolute'}}/>
          </div>
          <div className={`bg_modal_background ${showMaxJoin && 'modal_bg_show_no_delay'}`} style={{backgroundColor: 'black'}}></div>
          <div className={`bg_modal ${showMaxJoin && 'modal_show'}`}
            onClick={() => navigate(routes.home)}
          >
            <img src={MaxJoinTopic} className={classes.tutorialImage} />
          </div>
        </div>

      ) : (
        <h2 className='big'>Loading...</h2>
      )//questions.length > 0 ?

    );//return

}//function App

export default App;

import React, { Component, Fragment } from 'react'
import Alphabet from '../../components/Alphabet'
import Category from '../../../../Components/Clue'
import TipsLeft from '../../components/TipsLeft'
import { LetterType } from '../../components/Letter'
import Word from '../../components/Word'
import dictionary_pharma from '../../dictionaries/pharma'
import dictionary_en from '../../dictionaries/dictionary-en'
import dictionary_fi from '../../dictionaries/dictionary-fi'
import dictionary_es from '../../dictionaries/dictionary-es'
import './App.css'
import { Keyboard } from "../../../../Keyboard";
import { Clue } from "../../../../clue";
import correct from "../../../MultipleChoice/asset/sounds/Correct Answer Sound Effect.mp3";
import wrong from "../../../MultipleChoice/asset/sounds/Wrong Answer sound effect.mp3";
import { ids } from '../../../../constants'

interface State {
  alphabet: string
  letters: LetterType[]
  category: any
  guessedLetters: Set<string>
  guessedTheWord: boolean
  guessesLeft: number
  language: string
  word: LetterType[],
  selectedPrize: number,
  showPrize: boolean,
  answers: string[],
  selectedAnswer: string,
  className: string,
  questionSet: any,
  currentIndex: number,
  correctAnswer: string,
  outcome: string,
  outcomeColor: string,
  value: string,
  freeLetters: string,
}

class App extends Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = this.initialState()
    this.changeLanguage = this.changeLanguage.bind(this)
    this.selectLetter = this.selectLetter.bind(this)
    this.startNewGame = this.startNewGame.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addKeyPressListener();
    this.letterInfo = new Map<string, Clue>();
    this.clues = [];
  }

  initialState(language: string = 'gb'): State {
    const alphabet: string = this.getAlphabet(language)

    const letters: LetterType[] = Array.from(alphabet).map(letter => ({
      letter,
      disabled: false
    }))

    const dictionaries = this.getDictionaries()
    const dictionary: Object | undefined = dictionaries.get(language)
    const category = this.randomCategory(dictionary)

    const word: LetterType[] = Array.from(this.randomWord(category)).map(
      letter => ({
        letter,
        disabled: false
      })
    )

    const guessedLetters: Set<string> = new Set()
    const guessesLeft: number = 5
    const guessedTheWord: boolean = false
    const selectedPrize: number = -1
    const showPrize: boolean = false
    const selectedAnswer: string = ''
    const className: string = 'ph2answer'
    // const questionsSet = category.questions.map((question) => ({
    //   ...question,
    //   answers: [
    //     question.correct_answer,
    //     ...question.incorrect_answers,
    //   ].sort(() => Math.random() - 0.5)
    // }))
    const questionsSet = [];
    const currentIndex = 0
    const correctAnswer = "";
    const answers: string[] = []
    const outcome: string = "Choose one"
    const outcomeColor: string = 'white'
    const value: string = ""
    const freeLetters: string = ""

    this.props.setWord(word.map(item => item.letter).join(''));
    this.props.setQuestionId(category.questionId);

    return {
      alphabet,
      letters,
      language,
      category,
      word,
      guessedLetters,
      guessesLeft,
      guessedTheWord,
      selectedPrize,
      showPrize,
      answers,
      selectedAnswer,
      className,
      questionsSet,
      currentIndex,
      correctAnswer,
      outcome,
      outcomeColor,
      value,
      freeLetters,
    }
  }

  getAlphabet(language: string): string {
    const defaultAlphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

    return language === 'gb'
      ? defaultAlphabet
      : language === 'fi'
      ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ'
      : language === 'es'
      ? 'AÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ'
      : defaultAlphabet
  }

  getDictionaries(): Map<string, Object> {
    const dictionaries: Map<string, Object> = new Map()
    dictionaries.set('fi', dictionary_fi)
    dictionaries.set('gb', dictionary_en)
    dictionaries.set('es', dictionary_es)
    if (Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypePharma) {
      dictionaries.set('gb', dictionary_pharma)
    }

    return dictionaries
  }

  randomCategory(dictionary: any) {
    const categories = []

    for (const entry of dictionary.entries()) {
      // eslint-disable-next-line
      let [index, object] = entry
      categories.push(object)
    }

    if (this.props.fromNotification && this.props.questionId) {
      const data = categories.filter((i) => i.questionId === this.props.questionId)
      if (data.length > 0) {
        return data[0]
      }
    }
    else if (typeof this.props.wins !== 'undefined' && typeof this.props.set1 !== 'undefined'
      && typeof this.props.set2 !== 'undefined' && typeof this.props.set3 !== 'undefined') 
    {
      console.log(`this.props.wins=${this.props.wins}`)
      if (this.props.wins > 0) {
        if (this.props.wins >= 8) {
          const data = categories.filter((i) => this.props.set3.some((a) => a === i.questionId))
          if (data.length > 0) {
            return data[Math.floor(Math.random() * data.length)]
          }
        }
        else if (this.props.wins >= 4) {
          const data = categories.filter((i) => this.props.set2.some((a) => a === i.questionId))
          if (data.length > 0) {
            return data[Math.floor(Math.random() * data.length)]
          }
        }
      }
      const data = categories.filter((i) => this.props.set1.some((a) => a === i.questionId))
      if (data.length > 0) {
        return data[Math.floor(Math.random() * data.length)]
      }
    }
    return categories[Math.floor(Math.random() * categories.length)]
  }

  randomWord(category: { words: string[] }): string {
    return category.words[
      Math.floor(Math.random() * category.words.length)
    ].toUpperCase()
  }

  changeLanguage(language: string): void {
    this.setState(this.initialState(language))
  }

  selectLetter(selectedLetter: string): void {
    this.updateGameStatus(this.state, selectedLetter)
  }

  updateGameStatus(state: State, selectedLetter: string): void {
    if (state.guessesLeft > 0 && !state.guessedTheWord) {
      const nextState: State = { ...state }
      const alreadyGuessedLetter: boolean = state.guessedLetters.has(
        selectedLetter
      )

      if (!alreadyGuessedLetter) {
        nextState.guessedLetters.add(selectedLetter)
        const letter: LetterType | undefined = nextState.letters.find(
          letter => letter.letter === selectedLetter
        )

        if (letter) {
          letter.disabled = true
        }
      }

      const guessedTheWord: boolean = nextState.word
        .filter(letter => !['-', ' '].includes(letter.letter))
        .every(letter => nextState.guessedLetters.has(letter.letter))

      const lettersFoundInWord: boolean =
        nextState.word.filter(letter => letter.letter === selectedLetter)
          .length > 0

      if (guessedTheWord) {
        nextState.guessedTheWord = true;
      } else if (!alreadyGuessedLetter && !lettersFoundInWord) {
        // nextState.guessesLeft--
      }

      nextState.word.forEach(letter =>
        letter.letter === selectedLetter ? (letter.disabled = true) : ''
      )

      this.setState(nextState, () => {
        // console.log(`nextState=${JSON.stringify(nextState)}`)
        if (guessedTheWord) {
          if (this.timer) {
            clearInterval(this.timer);
          }
          this.props.notifyWon(6 - nextState.guessesLeft, state.word.map(item => item.letter).join(''))
        }
        else if (nextState.guessesLeft == 0) {
          setTimeout(() => {
            this.props.notifyLost(nextState.word.map(item => item.letter).join(''));
          }, Number(process.env.REACT_APP_POSTLOADER_PAUSE))
        }
      })
    }
  }

  startNewGame(): void {
    this.setState(this.initialState(this.state.language))
  }

  addKeyPressListener(): void {
    document.addEventListener('keydown', event => {
      const letter: string = event.key.toUpperCase()

      if (this.state.alphabet.includes(letter)) {
        // this.selectLetter(letter)
      }
    })
  }

  prizeClick(i: number): void {
    // console.log('here')
    this.setState({showPrize: true, selectedPrize: i});
  }

  delay(duration, callback) {
    setTimeout(() => {
        callback();
    }, duration);
  };

  handleClick(answer: string): void {
    this.setState({selectedAnswer: answer, className: answer === this.state.correctAnswer ? "answer correct" : "answer wrong"}, () => {
      // this.delay(500, () => {
        // this.setState({className: answer === this.state.correctAnswer ? "answer correct" : "answer wrong"})
      // });
      if (this.state.currentIndex <= this.state.questionsSet.length - 1) {
        const category = {...this.state.category};
        category.category = `${this.state.category.category} ${this.state.correctAnswer}${this.state.currentIndex < this.state.questionsSet.length - 1 ? ',' : ''}`;
        this.clues.push({
          clue: this.state.correctAnswer,
          isCorrect: answer === this.state.correctAnswer
        })
        this.setState({
          category,
          outcome: answer === this.state.correctAnswer ? "You're right" : 'Not quite',
          outcomeColor: answer === this.state.correctAnswer ? '#3fff3f' : 'red',
          guessesLeft: answer == this.state.correctAnswer ? (this.state.guessesLeft + 1) : this.state.guessesLeft,
        }, () => {
          this.delay(100, () => {
            if (this.state.currentIndex == this.state.questionsSet.length - 1) {
              // done();
            }
            this.delay(3000, () => {
              let qs, correctAnswer, answers;
              if (this.state.currentIndex < this.state.questionsSet.length - 1) {
                qs = this.state.questionsSet[this.state.currentIndex + 1];
                correctAnswer = qs.correct_answer;
                answers = qs.answers
              }
              this.setState({
                selectedAnswer: "",
                outcome: "Choose one",
                outcomeColor: 'white',
                currentIndex: this.state.currentIndex + 1,
                correctAnswer,
                answers
              })
            });
          })
        })
      }
    })
  }
    
  componentDidMount() {
    // this.nameInput.focus();
    this.timer = setInterval(() => {
      console.log(`guessLeft=${this.state.guessesLeft}; this.state.freeLetter=${this.state.freeLetters}`)
      if (this.state.guessesLeft > 2) {
        this.selectLetter(this.state.freeLetters.split('')[5-this.state.guessesLeft]);
      }
      if (this.state.guessesLeft > 1) {
        this.setState({guessesLeft: this.state.guessesLeft - 1})
      }
      else {
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    }, 5000)
    //get unique letters, shuffle
    this.setState({freeLetters: this.makeUnique(this.state.category.words[0]).split('').sort(function(){return 0.5-Math.random()}).join('')})
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.nameInput.blur();
  }

  handleChange(event) {
    this.setState({value: event.target.value});
    event.preventDefault();
  }

  timeOut() {
    this.nameInput.blur();
  }

  handleSubmit(event) {
    if (this.ciEquals(this.state.value, this.state.category.words[0])) {
      new Audio(correct).play();
      this.state.freeLetters.split('').map((letter) => {
        this.selectLetter(letter);
      })
    }
    else {
      new Audio(wrong).play();
      this.setState({value: ""});
    }
    if (event) {
      event.preventDefault();
    }
  }

  ciEquals(a, b) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
  }

  makeUnique(str: string) {
    return String.prototype.concat(...new Set(str.toUpperCase().replace(/ /g, '')))
  }

  onKey(key: string) {
    if (/^[a-z]$/i.test(key)) {
      this.setState({value: this.state.value + (key.toUpperCase())})
    } else if (key === "Space") {
      this.setState({value: this.state.value + " "});
    } else if (key === "Backspace") {
      this.setState({value: this.state.value.slice(0, -1)});
    } else if (key === "Enter") {
      this.handleSubmit();
    }
  }

  render() {
    const languages: string[] = ['fi', 'gb', 'es']
    
    return (
      <div className="ph3-main-bg" style={{backgroundImage: `url(${this.props.background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundBlendMode: 'luminosity'}}>
        {/* <img src={this.props.background} style={{resize: 'stretch', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}></img> */}
        <Fragment>
          {/* <p className="sr-only">
            Welcome to play word guess game. Try to guess the word from the given
            category. Use keyboard to guess the letters.
          </p> */}
          <Category setting={''} category={this.clues} />
          {/* <Category category={this.state.category} tipsLeft={this.state.guessesLeft}/> */}
          <Word
            word={this.state.word}
            guessedTheWord={this.state.guessedTheWord}
          />
          <form className="ph3InputContainer" onSubmit={this.handleSubmit}>
            <input className="ph3Input" type="text" value={this.state.value} onChange={this.handleChange} readOnly={true}
              ref={(input) => { this.nameInput = input; }} 
            />
          </form>
          <Keyboard
            layout={this.props.keyboardLayout}
            letterInfo={this.letterInfo}
            onKey={this.onKey.bind(this)}
          />
          {/* <Alphabet letters={this.state.letters} onClick={this.selectLetter} /> */}
            {/* <div>
              <p className="ph2outcome" style={{color: this.state.outcomeColor}}>{this.state.outcome}</p>
              <div className='ph2answers row'>

                  {this.state.answers.map((answer, idx) => {
                      return (
                          <button
                              key={idx}
                              dangerouslySetInnerHTML={{ __html: answer }}
                              className={this.state.selectedAnswer === answer ? this.state.className : "ph2answer"}
                              onClick={() => !this.state.selectedAnswer && this.handleClick(answer)}
                          />
                      );
                  })}
              </div>
            </div> */}
          
          <div className="footer">
            <TipsLeft tipsLeft={this.state.guessesLeft-1} prizes={this.props.prizes} prizeClick={(i) => this.prizeClick(i)} />
            {/* <GameResult
              guessedTheWord={this.state.guessedTheWord}
              guessesLeft={this.state.guessesLeft}
            /> */}
            {/* <LanguageSwitcher
              languages={languages}
              onClick={this.changeLanguage}
            /> */}
            {/* <NewWord onClick={this.startNewGame} /> */}
          </div>
        </Fragment>
        {this.state.showPrize && (
          <div
            className="tutorialContainer"
            onClick={() => this.setState({showPrize: false})}
          >
            <img src={this.props.prizes[this.state.selectedPrize].imageBig} className="tutorialImage" />
            <p className="close">X</p>
          </div>
        )}
      </div>
    )
  }
}

export default App

import Api from "./Api";

const API_LOGIN = '/api/v1';
const API_FUN_TYPE = '/api/packages';
const API_ENGAGEMENT = '/api/v1/engagement';
const API_ENGAGEMENT_SKED = '/api/v1/scheduler';
const API_WIN_ENGINEERING = '/api/v1/winEngg';
const API_GAME_STATS = '/api/v1/gameStats/engagement';
const API_LEADERBOARD = '/api/v1/leaderboard';
const API_WIN_PROCESSING = '/api/v1/win/engagement';
const API_ATTACKABLE = '/api/v1/loot-shield';
const API_BATTLE = '/api/v1/users';
const API_TOURNEY_WINNERS = '/api/v1/tourney';
const API_IAF = '/api/v1/referral';
const API_STORE = '/api/v1/store';
const API_USER_DETAILS = '/api/v1/profile';
const API_MEDIA = '/api/v1/media';
const API_USER_COLLECTIONS = '/api/v1/user-coin-ticket';
const API_NOTIFICATIONS = '/api/v1';
const API_TXN_HISTORY = '/api/v1/transactionHistory';
const API_MITAPP = '/api/v1';
const API_PARAMETERS = '/api/v1';
const API_CHARM = '/api/v1/charm';

const MAX_LIMIT = 50;


//steal
export const getRondleAttackableUsers = (params = {}) => {
  return Api.get(`${API_ATTACKABLE}/rondle/users`, params)
}

export const addRondleSteal = (params = {}) => {
  return Api.post(`${API_ATTACKABLE}/rondle/steal`, params)
}

export const getRondleStealDetails = (params = {}) => {
  return Api.get(`${API_ATTACKABLE}/rondle/steal/details`, params)
}

export const processRondleSteal = (params = {}) => {
  return Api.put(`${API_ATTACKABLE}/rondle/steal`, params)
}

//lesson
export const getLessons = (params = {}) => {
  return Api.get(`${API_ENGAGEMENT}/rondle/lesson`, params)
}

//parameters
export const checkUserVersion = (params = {}) => {
  return Api.post(`${API_PARAMETERS}/parameters/user/version`, params)
}

//engagementSked
export const getFeatured = (params = {}) => {
  return Api.get(`${API_ENGAGEMENT_SKED}/schedules`, params)
}

// brand - store
export const getBrandLeaderboard = (params = {}) => {
  return Api.get(`${API_STORE}/leaderboard`, params);
}

export const buyBrandStoreItem = (params = {}) => {
  return Api.post(`${API_STORE}/buyBrands`, params);
}

export const getSalesPromo = (params = {}) => {
  return Api.get(`${API_STORE}/salesPromo`, params);
}

export const getStoreItemsBought = (params = {}) => {
  return Api.get(`${API_STORE}/bought`, params);
}

// user collections
export const coinUse = (params = {}) => {
  return Api.post(`${API_USER_COLLECTIONS}/coin`, params);
}

export const getUserBalance = (params = {}) => {
  return Api.get(`${API_USER_COLLECTIONS}/balance`, params);
}

// notifications
export const getNotificationCount = (params = {}) => {
  return Api.get(`${API_NOTIFICATIONS}/notifications/count`, params);
}

export const getNotifications = (params = {}) => {
  return Api.get(`${API_NOTIFICATIONS}/notifications`, {...params, start: 0, limit: MAX_LIMIT});
}

export const getNotificationsConfetti = (params = {}) => {
  return Api.get(`${API_NOTIFICATIONS}/notifications/confetti`, params);
}

export const getBroadcasts = (params = {}) => {
  return Api.get(`${API_NOTIFICATIONS}/notifications/newsfeed/broadcast`, params)
}

// boost and gift
export const getBoosts = (params = {isBoost: true, start: 0, limit: MAX_LIMIT}) => {
  return Api.get(`${API_MITAPP}/mitapp/user`, params);
}

export const acceptMitApp = (params = {}) => {
  return Api.post(`${API_MITAPP}/mitapp/user/accept`, params);
}

// win engg
export const getWinEnggF2 = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/hof`, params);
}

export const getFamers = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/famers`, params);
}

export const getWinEnggConsolidated = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/consolidated`, params);
}

export const addFamer = (params = {}) => {
  return Api.post(`${API_WIN_ENGINEERING}/famers`, params);
}

export const completeWin = (params = {}) => {
  return Api.post(`${API_WIN_ENGINEERING}/win`, params);
}

export const getWinEnggR2 = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/rondle/hof`, params);
}

export const getWinEnggR2New = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/rondle/hof2`, params);
}

export const getWinEnggR2Sequence = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/rondle/sequence`, params);
}

export const completeR2Win = (params = {}) => {
  return Api.post(`${API_WIN_ENGINEERING}/rondle/win`, params);
}

export const completeR2Win2 = (params = {}) => {
  return Api.post(`${API_WIN_ENGINEERING}/rondle/win2`, params);
}

// brand win engg
export const addBrandFamer = (params = {}) => {
  return Api.post(`${API_WIN_ENGINEERING}/brand/famers`, params);
}

export const getBrandWinEnggB2F = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/brand/hof`, params);
}

export const completeBrandWin = (params = {}) => {
  return Api.post(`${API_WIN_ENGINEERING}/brand/win`, params);
}

export const getBrandWinEnggConsolidated = (params = {}) => {
  return Api.get(`${API_WIN_ENGINEERING}/brand/consolidated`, params);
}

//leaderboard
export const getLeaderboard = (params = {}) => {
  return Api.get(`${API_LEADERBOARD}`, params)
}

export const getBestScore = (params = {}) => {
  return Api.get(`${API_LEADERBOARD}/userData`, params)
}

export const postScore = (params = {}) => {
  return Api.post(`${API_LEADERBOARD}/postscore`, params);
};

// win processing
export const addToJoin = (params = {}) => {
  return Api.post(`${API_WIN_PROCESSING}/joins`, params);
}

export const getJoinSummary = (params = {}) => {
  return Api.get(`${API_WIN_PROCESSING}/rondleJoinSummary`, params);
}

// game stats
export const getOneTimePay = (params = {}) => {
  return Api.get(`${API_GAME_STATS}/oneTimePay`, params);
};

export const payOneTimeFee = (params = {}) => {
  return Api.post(`${API_GAME_STATS}/oneTimePay`, params);
};

export const checkAndPayOneTimeFee = (params = {}) => {
  return Api.post(`${API_GAME_STATS}/oneTimeCheckAndPay`, params);
};

// login
export const login = (params = {}) => {
  return Api.post(`${API_LOGIN}/auth/login`, params);
};

// user profile
export const getUserProfile = (params = {}) => {
  return Api.get(`${API_USER_DETAILS}/details`, params);
}

export const updateAvatar = (params = {}) => {
  return Api.post(`${API_USER_DETAILS}/avatar`, params);
}

export const updateClass = (params = {}) => {
  return Api.post(`${API_USER_DETAILS}/class`, params);
}

export const checkWalletAddress = (params = {}) => {
  return Api.adminGet(`${API_USER_DETAILS}/checkWalletAddress`, params);
}

export const checkName = (params = {}) => {
  return Api.adminGet(`${API_USER_DETAILS}/checkName`, params);
}

export const register = (params = {}) => {
  return Api.adminPost(`${API_USER_DETAILS}/registerRondle`, params);
}

// transaction history
export const getCoinHistory = (params = {start: 0, limit: MAX_LIMIT}) => {
  return Api.get(`${API_TXN_HISTORY}/coin`, params)
}

// tourney winners
export const getTourneyWinners = (params = {}) => {
  return Api.get(`${API_TOURNEY_WINNERS}/winners`, params);
}

//media
export const uploadMedia = (params = {}) => {
  const header = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  return Api.post("/api/v1/media", params, header);
};

//others
export const getEngagement = (params = {}) => {
  return Api.get(`/api/v1/leaderboard`, params);
};
import { QuestionType } from './'

const pharma: QuestionType[] = [
  {
    questionId: 100074,
     question: "What is the effective food supplement taken 3 tablespoons per day?",
     correct_answer: "Rondlex Folic",
     incorrect_answers: [
       "Rondlex Forte ",
       "Rondlex F4 ",
       "Rondlex F16"
     ]
   },
 {
    questionId: 100075,
     question: "One of its target specialty is OBGYN.",
     correct_answer: "Rondlex Folic",
     incorrect_answers: [
       "Rondlex F4",
       "Rondlex Forte",
       "Rondlex F16"
     ]
   },
 {
    questionId: 100076,
     question: "Which product contains 400mcg folic acid content?",
     correct_answer: "Rondlex Folic",
     incorrect_answers: [
       "Rondlex F4",
       "Rondlex Forte",
       "Rondlex F16"
     ]
   },
 {
    questionId: 100077,
     question: "What is the daily dosage recommendation of Rondlex Folic?",
     correct_answer: "3 tablespoons",
     incorrect_answers: [
       "2 capsules",
       "3 Dispersible Stick",
       "3 tablets"
     ]
   },
 {
    questionId: 100078,
     question: "One of the MD Specialty target of Rondlex Folic is:",
     correct_answer: "General Medicine",
     incorrect_answers: [
       "Hematology",
       "Internal Medicine",
       "Nephrology"
     ]
   },
 {
    questionId: 100079,
     question: "What is the effective food supplement taken 2 capsules per day?",
     correct_answer: "Rondlex Forte",
     incorrect_answers: [
       "Rondlex Folic",
       "Rondlex F4",
       "Rondlex F16"
     ]
   },
 {
    questionId: 100080,
     question: "One of its target specialty is Nephrology.",
     correct_answer: "Rondlex Forte",
     incorrect_answers: [
       "Rondlex Folic",
       "Rondlex F4",
       "Rondlex F16"
     ]
   },
 {
    questionId: 100081,
     question: "Which product contains 45mg Ascorbic acid content?",
     correct_answer: "Rondlex Forte",
     incorrect_answers: [
       "Rondlex Folic",
       "Rondlex F4",
       "Rondlex F16"
     ]
   },
 {
    questionId: 100082,
     question: "What is the daily dosage recommendation of Rondlex Forte?",
     correct_answer: "2 capsules",
     incorrect_answers: [
       "1 capsule",
       "3 capsules",
       "2 tablets"
     ]
   },
 {
    questionId: 100083,
     question: "One of the MD Specialty Target of Rondlex Forte is:",
     correct_answer: "Nephrology",
     incorrect_answers: [
       "OBGYN",
       "General Medicine",
      "None of the above"
 
     ]
   },
];

export default pharma;
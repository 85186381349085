
import React, { useEffect } from 'react';
import useStyles from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useWindowDimensions from '../../../../utils/windowDimention';
import { useNavigate } from 'react-router-dom';
// import Trophy from "../../../../assets/images/logo/tourney_logo.png";
import Footer from "../Footer";
import InnerPageHeader from '../../../../Components/InnerPageHeader';
import { ids } from '../../../../constants';
import SoundBestScore from '../../../../assets/sounds/best_score.mp3';

import { postScore } from '../../../../services/ApiService';

import moment from 'moment';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const GameResult = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const { engagementData, score, highScore, highScoreDate } = props;

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const newHighScore = score >= highScore;

  useEffect(() => {
    uploadScore();
  })

  const uploadScore = async () => {
    try {
      const scoreData = [
        {
            "engagementId": engagementData.newId, 
            "score": score, 
            "recordTime": new Date().toISOString().slice(0,-5)
        }
      ]
      
      await postScore(scoreData)
    } catch (e) {
      console.log(e);
    }
  }

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };



  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };
  return (
    <div>
      <div className={classes.homeContainer} style={{ height: height }}>

        <div style={{ paddingRight: 20 }}>
          <InnerPageHeader
            hasBackBtn
            goBackClick={() => navigate(-1)}
            title={`B: ${engagementData.title}`}
          />
        </div>
        {newHighScore && <iframe src={SoundBestScore}
            frameborder='0'
            allow='autoplay; encrypted-media'
            style={{width: 0, height: 0}}
            title='video'
        />}
        <div >
          <div >
            <div className={classes.Mian}>
              {engagementData.funTypeFamilyId === ids.FunTypeFamilyFamer && <h3 className={classes.hostedBy}>Hosted by:</h3>}
              <div className={classes.logo} >
                <img src={engagementData.source.avatarBig} className={classes.img} />
              </div>
              <div className={classes.score} >
                <p className={classes.you}>{newHighScore ? 'New Best Score:' : 'Your Score:'}</p>
                <h1 className={classes.point}>{score}</h1>
              </div>
              {!newHighScore && 
                <div className={classes.score} >
                  <p style={{ margin: "0", color: "#949494" }}>Your best score: {highScore}</p>
                  <p className={classes.timing}>as of {moment(highScoreDate).format("YYYY-MM-DD HH:mm:ss")}</p>
                </div>
              }
              <div className={classes.rank} >
                <h2 className={classes.gameResultWonText}>Try to win</h2>
              </div>
              <div>
                <img src={engagementData.postloaderImageBig} className={classes.postloaderImg} />
              </div>
              <p className={classes.bonus}>Rank 1 prize</p>
            </div>
          </div>
        </div>
      </div>
      <Footer
        onRepeat={props.onRepeat}
        showTips={() =>
          navigate("/show", { state: { title: engagementData.title, image: engagementData.tipsImageBig } })
        }
        style={{bottom: 60}}
      />
    </div>
  )
}

export default GameResult;
